export default function logError(err) {
  if (process.env.NODE_ENV === 'development') {
    console.error(err); // eslint-disable-line no-console
  }
}

export class Logger {
  constructor(className) {
    this.className = className;
  }

  debug(message, ...rest) {
    if (process.env.NODE_ENV !== 'production') {
      console.log(`[${this.className}] ${message}`, ...rest);
    }
  }
}
