import React, { useState, useEffect, useCallback } from "react";
import { API } from "aws-amplify";
import PropTypes from "prop-types";
import Fab from "@material-ui/core/Fab";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { I18n } from "react-redux-i18n";

import cn from "classnames";

import includes from "lodash/includes";

import TnC from "../../../../components/TnC/TnC";
import Copyright from "../../../../components/Copyright";
import { trackQuestionnaire } from "../../../../utils/analytics";

const styles = () => ({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 12
  },
  loader: { marginBottom: "50px" },
  startBtn: {
    width: "362px",
    height: "92px",
    borderRadius: "46px",
    color: "#ffffff",
    marginBottom: "50px",
    fontSize: "20px",
    fontWeight: 900
    // backgroundColor: "#fd6983"
  },
  anonymousBtn: {
    width: "350px",
    height: "60px",
    outline: "none"
    // color: "#fd6983"
  },
  tnc: {
    color: "inherit",
    fontWeight: "bold"
  },
  hide: { display: "none" }
});

const Footer = ({ classes, user, survey, doPrepareQuestionnaire }) => {
  const [acceptedTnC, setAcceptedTnC] = useState(false);
  const [hasResponse, setHasResponse] = useState(false);
  const [loader, setLoader] = useState(true);
  const [isSalesforceSurvey, setIsSalesforceSurvey] = useState(false);
  const [arePreconditionsMet, setPreconditionsMet] = useState(false);

  const handleClick = useCallback(() => {
    doPrepareQuestionnaire();
    trackQuestionnaire(hasResponse);
  }, [hasResponse]);

  useEffect(() => {
    const fetchResponses = async () => {
      if (isEmpty(user.userId)) {
        setHasResponse(false);
        setLoader(false);
      } else {
        setLoader(true);
        try {
          const responses = await API.get(
            "surveys",
            `/users/${user.userId}/surveys/${survey._id}/responses`,
            {
              headers: { Authorization: `Bearer ${user.jwtToken}` }
            }
          );
          setHasResponse(responses.length > 0);
          setAcceptedTnC(responses.length > 0);
        } catch (error) {
          setHasResponse(false);
        } finally {
          setLoader(false);
        }
      }
    };

    fetchResponses();
  }, [user]);

  useEffect(() => {
    if (includes(survey.name, "salesforce")) {
      setAcceptedTnC(true);
      setIsSalesforceSurvey(true);
    }

    if (survey.type === "private" && isEmpty(user.userId)) {
      setPreconditionsMet(false);
    } else {
      setPreconditionsMet(true);
    }
  }, [survey, user]);

  return (
    <div className={classes.container}>
      {loader && <CircularProgress className={classes.loader} />}
      {!loader && (
        <>
          {!hasResponse && (
            <FormGroup className={cn({ [classes.hide]: isSalesforceSurvey })}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptedTnC}
                    onChange={e => setAcceptedTnC(e.target.checked)}
                    value="checked"
                    color="secondary"
                  />
                }
                label={
                  <TnC
                    pretext={I18n.t("agreeToSurveyTnC")}
                    url={process.env.REACT_APP_SURVEY_TNC_URL}
                  />
                }
              />
            </FormGroup>
          )}
          <Fab
            variant="extended"
            size="large"
            color="primary"
            disabled={!acceptedTnC || !arePreconditionsMet}
            className={classes.startBtn}
            onClick={handleClick}
          >
            {hasResponse ? I18n.t("continue") : I18n.t("startNow")}
          </Fab>
        </>
      )}

      <Copyright />
    </div>
  );
};
Footer.propTypes = {
  user: PropTypes.shape().isRequired,
  survey: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  doPrepareQuestionnaire: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  survey: state.survey,
  user: state.user
});

export default connect(mapStateToProps)(withStyles(styles)(Footer));
