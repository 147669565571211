import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Scrollbar from "react-scrollbars-custom";
import isEmpty from "lodash/isEmpty";

const styles = theme => ({
  container: {
    width: "100%",
    top: 200,
    height: "calc(100vh - 150px) !important",
    [theme.breakpoints.down("sm")]: {
      top: 0,
      // height: "calc(100vh - 100px) !important",
      // paddingBottom: 82,
      boxSizing: "border-box",
      position: "relative"
    },
    "@media (max-width: 320px)": {
      // height: "calc(100vh - 90px) !important"
    },
    "& .ScrollbarWrapper": {
      zIndex: 8,
      [theme.breakpoints.down("sm")]: {
        top: "60px !important"
      }
    },
    "& .ScrollbarContent": {
      paddingRight: "23px !important",
      marginRight: "-23px !important",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "50px"
      },
      "@media (max-width: 320px)": {
        paddingTop: "30px"
      }
    },
    "& .ScrollbarTrack-Y": {
      top: "23px!important",
      zIndex: 12,
      [theme.breakpoints.down("sm")]: {
        top: "0 !important",
        zIndex: 9
      }
    }
  },
  body: {
    position: "absolute",
    top: 50,
    width: "100%",
    display: "flex",
    paddingBottom: 250,
    paddingRight: "inherit",
    paddingTop: "inherit",
    justifyContent: "center",
    fontFamily: "'Roboto', sans-serif",
    [theme.breakpoints.down("sm")]: {
      top: 0,
      flexDirection: "column",
      boxSizing: "border-box",
      paddingBottom: 0
    },
    "@media (max-width: 320px)": {
      paddingBottom: 0
    }
  }
});

const QuestionBody = ({ classes, cursor, error, offset, children, mobile }) => {
  const scroll = useRef(null);

  /**
   * On entering a question scroll to top
   */
  useEffect(() => {
    scroll.current.scrollToTop();
  }, [cursor]);

  /**
   * On error scroll to top so the user can see the error
   */
  useEffect(() => {
    if (!isEmpty(error)) {
      scroll.current.scrollToTop();
    }
  }, [error]);

  /**
   * Scroll to a specific offset.
   * Useful for scrolling on demand from question types.
   * Used by searchable list on mobile
   */
  useEffect(() => {
    scroll.current.scrollTo(0, offset);
  }, [offset]);

  const rest = mobile
    ? {
        minimalThumbSize: 70,
        maximalThumbSize: 300
        // mobileNative: true
      }
    : {
        minimalThumbSize: 50,
        maximalThumbSize: 500
      };

  return (
    <Scrollbar className={classes.container} ref={scroll} {...rest}>
      <div className={classes.body}>{children}</div>
    </Scrollbar>
  );
};

QuestionBody.propTypes = {
  classes: PropTypes.shape().isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element]))
  ]).isRequired,
  cursor: PropTypes.number.isRequired,
  mobile: PropTypes.bool,
  offset: PropTypes.number,
  error: PropTypes.string
};

QuestionBody.defaultProps = {
  error: "",
  offset: 0,
  mobile: false
};

const mapStateToProps = state => ({
  cursor: state.cursor.index
});

export default connect(mapStateToProps)(withStyles(styles)(QuestionBody));
