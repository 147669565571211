import React from "react";
import PropTypes from "prop-types";
import amber from "@material-ui/core/colors/amber";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/Info";

import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";

const variantIcon = {
  warning: WarningIcon,
  info: InfoIcon
};

const styles = theme => ({
  warning: {
    backgroundColor: amber[700]
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  icon: {
    fontSize: 20
  },
  message: {
    display: "flex"
  },
  text: {
    paddingLeft: theme.spacing.unit
  }
});

const AppSnackBar = ({ open, message, classes, variant, onClose }) => {
  const Icon = variantIcon[variant];

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <SnackbarContent
        className={classes[variant]}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={classes.icon} />
            <span className={classes.text}>{message}</span>
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={onClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
};

AppSnackBar.propTypes = {
  classes: PropTypes.shape().isRequired,
  open: PropTypes.bool,
  message: PropTypes.string,
  variant: PropTypes.oneOf(["warning", "info"]),
  onClose: PropTypes.func
};

AppSnackBar.defaultProps = {
  open: false,
  message: "",
  variant: "warning",
  onClose: () => true
};

export default withStyles(styles)(AppSnackBar);
