import React from "react";
import { navigate } from "@reach/router";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const style = () => ({
  appBar: {
    zIndex: 1900
  },
  profile: {
    color: "#fefefe"
  },
  container: {
    paddingTop: "60px",
    height: "100%"
  }
});

const AdminLayout = ({ classes, children }) => (
  <>
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <Button className={classes.profile} onClick={() => navigate("/admin")}>
          Surveys
        </Button>
        <div style={{ flexGrow: 1 }} />
        <Button className={classes.profile}>Profile</Button>
      </Toolbar>
    </AppBar>
    <main className={classes.container}>{children}</main>
  </>
);

export default withStyles(style)(AdminLayout);
