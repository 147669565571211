export function extractURLParams(location = window.location) {
  const params = location.pathname.split('/').filter((e) => e);

  return {
    survey: params[0],
    question: params[1]
  };
}

/**
 * Get url query strings
 * @param  {Object} query: window.location
 * @return {Object}      : a key-value object where keys are qs names and values are qs values
 * e.g. { lang: 'ES', somethingElse: 'somethingElse' }
 */
export function extractQueryParams(location = window.location) {
  const query = location.search;

  if (!query) return {};

  return (/^[?#]/.test(query) ? query.slice(1) : query)
    .split('&')
    .reduce((params, param) => {
      const [key, value] = param.split('=');
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
      return params;
    }, {});
}
