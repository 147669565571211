import React from "react";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    color: "#2b2d42",
    fontWeight: "bold",
    paddingLeft: 350,
    paddingRight: 400,
    marginBottom: 8,
    "@media only screen and (max-width: 1280px) and (orientation: landscape)": {
      paddingLeft: 100,
      paddingRight: 100
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 100,
      paddingRight: 100,
      fontSize: "1.25rem",
      wordBreak: "break-all"
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 13
    }
  }
});

const Header = ({ classes, children }) => (
  <div className={classes.root}>{children}</div>
);

export default withStyles(styles)(Header);
