import React, { useEffect, useState } from "react";

import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";

import {
  AuthState,
  SignUp,
  SignIn,
  RequireNewPassword,
  ConfirmSignUp,
  ForgotPassword,
  ResetPreviousPassword,
  SuccessMessage,
  AuthReducer,
  AuthActions
} from "@slashdata/authenticator";
import {
  trackNewSurveyUser,
  trackNewsletterSubscription
} from "../../utils/analytics";
import { handleNewsletterSubscriptions } from "../../state/actions/user";

const { SET_STATUS, SIGN_UP_STATUS, SET_CURRENT_NEWSLETTERS } = AuthReducer;
const { useAuthStateValue } = AuthState;

const Authenticator = ({
  newsletters,
  defaultStatus,
  user,
  doUpdateState,
  doHandleNewsletterSubscriptions
}) => {
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useAuthStateValue();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const fetchCurrentStatus = async () => {
      const status = await AuthActions.getCurrentStatus(defaultStatus);
      dispatch({
        type: SET_STATUS,
        payload: status
      });
      dispatch({
        type: SET_CURRENT_NEWSLETTERS,
        payload: newsletters
      });
      setLoader(false);
    };

    fetchCurrentStatus();
  }, [defaultStatus, dispatch, newsletters, user]);

  if (loader) return null;

  const labels = I18n.t("authenticator");

  const onSubscribeNewsletters = async newslettersState => {
    trackNewsletterSubscription(newslettersState);
    doHandleNewsletterSubscriptions(newslettersState);
  };

  return (
    <>
      <SignUp labels={labels} tncUrl={process.env.REACT_APP_SURVEY_TNC_URL} />
      <SignIn labels={labels} onSignIn={doUpdateState} />
      <RequireNewPassword labels={labels} />
      <ConfirmSignUp
        labels={labels}
        onConfirmSignUp={trackNewSurveyUser}
        onSubscribeNewsletters={onSubscribeNewsletters}
      />
      <ForgotPassword labels={labels} />
      <ResetPreviousPassword labels={labels} />
      <SuccessMessage labels={labels} />
    </>
  );
};

const mapStateToProps = state => ({
  defaultStatus: state.user.authenticatorView || SIGN_UP_STATUS
});

const mapDispatchToProps = dispatch => ({
  doHandleNewsletterSubscriptions: params =>
    dispatch(handleNewsletterSubscriptions(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(Authenticator);
