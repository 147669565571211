import React, { useEffect, useState, useCallback } from "react";

import filter from "lodash/filter";
import join from "lodash/join";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";

import { handleGACookies } from "../../utils/analytics";
import AppBar from "../Questionnaire/components/AppBar/AppBar";
import Copyright from "../../components/Copyright";

const styles = theme => ({
  root: {
    overflowY: "scroll",
    height: "100%"
  },
  header: {
    fontFamily: "Paytone One",
    fontSize: 30,
    lineHeight: 1.37,
    color: theme.palette.questionnaire.section,
    marginTop: 6,
    textAlign: "left"
  },
  container: {
    paddingLeft: 24,
    paddingRight: 24,
    marginTop: 90
  },
  title: {
    fontFamily: "Paytone one",
    fontSize: "24px",
    color: theme.palette.questionnaire.section,
    textAlign: "left"
  },
  text: {
    color: theme.palette.questionnaire.textPrimary,
    fontFamily: "Roboto"
  },
  allowed: {
    marginLeft: 4,
    color: theme.palette.questionnaire.section
  },
  content: {
    fontFamily: "Roboto",
    paddingBottom: 150
  },
  copyrightContainer: { position: "relative" }
});

const CONSENT_TYPES = ["marketing", "necessary", "preferences", "statistics"];

const getConsentReportHTML = (myDoc, stateUpdater) => {
  if (myDoc.readyState === "complete") {
    setTimeout(() => {
      const reportDivs = myDoc.body.getElementsByTagName("div");
      const reportDiv = reportDivs && reportDivs.length && reportDivs[0];
      const consentReport = reportDiv && reportDiv.innerHTML;
      stateUpdater({ loader: false, consentReport });
    }, 1000);
  } else {
    // This will be continiously called until the iFrame is ready
    setTimeout(() => {
      getConsentReportHTML(myDoc, stateUpdater);
    });
  }
};

const prepareConsentReport = stateUpdater => {
  const cookiebotTextFrame = document.createElement("iframe");
  cookiebotTextFrame.setAttribute("width", "100%");
  cookiebotTextFrame.id = "cookiebotTextFrame";

  const zone = document.createElement("div");
  document.body.appendChild(zone);
  zone.style.display = "none";
  zone.innerHTML = "";
  zone.appendChild(cookiebotTextFrame);

  const url = `https://consent.cookiebot.com/${process.env.REACT_APP_COOKIEBOT_ID}/cd.js`;

  const cookiebotTextFrameHTML = `
    <html>
      <head>
      </head>
      <body>
        <script type="text/javascript" src='${url}'></script>
      </body>
    </html>
  `;

  let cookiebotTextFrameDoc = cookiebotTextFrame.document;
  if (cookiebotTextFrame.contentDocument) {
    cookiebotTextFrameDoc = cookiebotTextFrame.contentDocument;
  } else if (cookiebotTextFrame.contentWindow) {
    cookiebotTextFrameDoc = cookiebotTextFrame.contentWindow.document;
  }

  cookiebotTextFrameDoc.open();
  cookiebotTextFrameDoc.writeln(cookiebotTextFrameHTML);
  cookiebotTextFrameDoc.close();

  getConsentReportHTML(cookiebotTextFrameDoc, stateUpdater);
};

const CookiePolicy = ({ classes }) => {
  const [state, setState] = useState({ loader: true, consentReport: "" });
  const [allowedCookies, setAllowedCookies] = useState({});

  useEffect(() => {
    prepareConsentReport(setState);

    const updateConsentStatus = () => {
      const values = filter(CONSENT_TYPES, type =>
        get(window, ["Cookiebot", "consent", type], false)
      );
      setAllowedCookies(values);
      handleGACookies();
    };

    if (typeof window !== "undefined") {
      updateConsentStatus();
      window.addEventListener("CookiebotOnAccept", updateConsentStatus);
      window.addEventListener("CookiebotOnDecline", updateConsentStatus);
    }

    return function cleanup() {
      window.removeEventListener("CookiebotOnAccept", updateConsentStatus);
      window.removeEventListener("CookiebotOnDecline", updateConsentStatus);
    };
  }, []);

  const showCookiebot = useCallback(() => {
    if (typeof window !== "undefined" && typeof Cookiebot !== "undefined") {
      window.Cookiebot.show();
    }
  }, []);

  const withdrawCookiebot = useCallback(() => {
    if (typeof window !== "undefined" && typeof Cookiebot !== "undefined") {
      window.Cookiebot.hide();
      window.Cookiebot.withdraw();
    }
  }, []);

  const { loader, consentReport } = state;

  if (loader) {
    return <div>Loading</div>;
  }

  return (
    <div className={classes.root}>
      <AppBar hideLogo hideAutoSave userProps={{ hideSignIn: true }} />
      <div className={classes.container}>
        <h2 className={classes.header}>Cookie Policy</h2>
        <h4 className={classes.title}>Your current consent status</h4>
        <div className={classes.text}>
          {isEmpty(allowedCookies) ? (
            "All cookies are forbidden"
          ) : (
            <>
              You allowed current types of cookies:
              <span className={classes.allowed}>
                {join(allowedCookies, ", ")}
              </span>
            </>
          )}
          <div>
            <Button onClick={showCookiebot}>Change your consent</Button>
            <Button onClick={withdrawCookiebot}>Withdraw your consent</Button>
          </div>
        </div>
        <h4 className={classes.title}>What this policy covers</h4>
        <div
          className={classes.content}
          dangerouslySetInnerHTML={{ __html: consentReport }}
        />
      </div>
      <div className={classes.copyrightContainer}>
        <Copyright />
      </div>
    </div>
  );
};

export default withStyles(styles)(CookiePolicy);
