import React, { Component } from "react";
import PropTypes from "prop-types";
import assign from "lodash/assign";
import filter from "lodash/filter";
import find from "lodash/find";
import flow from "lodash/flow";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
// import isUndefined from 'lodash/isUndefined';
import keyBy from "lodash/keyBy";
// import map from 'lodash/map';
import mapValues from "lodash/mapValues";
import noop from "lodash/noop";
import omitBy from "lodash/omitBy";
import partialRight from "lodash/partialRight";
import size from "lodash/size";

import Checkbox from "../Checkbox";
import Input from "../Input";
import ListLayout from "../ListLayout";
import styles from "./CheckboxList.module.css";

const countOptionsSelected = flow([
  partialRight(filter, obj => obj.value !== null),
  size
]);

class CheckboxList extends Component {
  constructor(props) {
    super(props);

    const formatValue = flow([
      partialRight(keyBy, "name"),
      partialRight(mapValues, (option, key) =>
        get(props.value, key, {
          value: null,
          writeIn: option.writeIn ? "" : undefined,
          source: option.source
        })
      )
    ]);

    this.state = {
      value: formatValue(props.options),
      disabled: size(filter(props.value, "value")) === props.maxOptionsAllowed
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleWriteIn = this.handleWriteIn.bind(this);
    this.handleWriteInClick = this.handleWriteInClick.bind(this);
  }

  componentWillMount() {
    // Pass state and answer to parent (Question) component right before being appended to DOM
    this.props.onInit(
      this.props.name,
      this.props.type,
      {
        //      options: map(this.props.options, (v) => omitBy(v, isUndefined))
        options: this.props.options,
        custom: this.props.custom
      },
      this.props.value,
      this.props.duration
    );
  }

  handleChange(e, name, checked) {
    const state = this.state;
    const option = find(this.props.options, { name });

    if (checked) {
      // handle exclusive options
      if (option.exclusive) {
        state.value = mapValues(state.value, obj =>
          assign({}, obj, { value: null })
        );
      } else {
        // nullify other exclusive options
        state.value = mapValues(state.value, (obj, key) => {
          if (find(this.props.options, { name: key, exclusive: true })) {
            return assign({}, obj, { value: null });
          }

          return obj;
        });
      }

      state.value[option.name].value = option.value;
      state.value[option.name].source = option.source;
    } else {
      // unchecked
      state.value[option.name].value = null;
      state.value[option.name].source = null;

      if (option.writeIn) {
        state.value[option.name].writeIn = "";
      }
    }

    state.disabled =
      countOptionsSelected(state.value) === this.props.maxOptionsAllowed;

    this.setState(state);

    this.props.onChange(e, omitBy(state.value, isEmpty));
  }

  handleWriteIn(e) {
    const state = this.state;

    state.value[e.target.name].writeIn = e.target.value;
    this.setState(state);

    this.props.onChange(e, state.value);
  }

  // If user clicks on writeIn the we force this item to be checked
  handleWriteInClick(e) {
    this.handleChange(e, e.target.name, true);
  }

  renderItem(option) {
    const currentOption = this.state.value[option.name];
    return (
      <label className={styles.item} key={option.name}>
        <Checkbox
          name={option.name}
          value={option.value}
          checked={currentOption.value === option.value}
          disabled={this.state.disabled && currentOption.value === null}
          onChange={e => this.handleChange(e, e.target.name, e.target.checked)}
        />
        <span className={styles.text}>
          {option.writeIn ? (
            <Input
              name={option.name}
              placeholder={option.text}
              disabled={this.state.disabled && currentOption.value === null}
              value={this.state.value[option.name].writeIn}
              onChange={this.handleWriteIn}
              onClick={this.handleWriteInClick}
            />
          ) : (
            option.text// || option.value
          )}
        </span>
      </label>
    );
  }

  render() {
    return (
      <ListLayout columns={1}>
        {this.props.options.map(o => this.renderItem(o))}
      </ListLayout>
    );
  }
}

CheckboxList.propTypes = {
  // The name of the question
  name: PropTypes.string.isRequired,
  // The type of the question (CHECKBOX_LIST)
  type: PropTypes.string.isRequired,
  // The options of the question
  options: PropTypes.arrayOf(
    PropTypes.shape({
      // The name of the option
      name: PropTypes.string.isRequired,
      // The value of the option
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      // The text of the option
      text: PropTypes.string.isRequired,
      // Whether the user can add the content of the question
      writeIn: PropTypes.boolean,
      // NOTE: option.fixed not used internally
      fixed: PropTypes.boolean,
      // Whether the option is exclusive
      // When exclusive selected, multi selection is not possible
      exclusive: PropTypes.boolean,
      // TODO: Provide description about source property
      source: PropTypes.string,
      // NOTE: option.path not used internally
      path: PropTypes.string
    })
  ).isRequired,
  // The selected value of the user
  value: PropTypes.shape({
    // The actual value
    value: PropTypes.string
  }),
  // The duration the user spent on this question
  duration: PropTypes.number.isRequired,
  // How many options are able to be selected
  maxOptionsAllowed: PropTypes.number,
  // TODO: Provide description about custom property
  custom: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
      text: PropTypes.string
    })
  ),
  // Callback to be invoked on mount
  onInit: PropTypes.func,
  // Callback to be invoked on change
  onChange: PropTypes.func
};

CheckboxList.defaultProps = {
  value: {},
  maxOptionsAllowed: null,
  custom: null,
  onInit: noop,
  onChange: noop
};

export default CheckboxList;
