import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { connect } from "react-redux";

import get from "lodash/get";
import includes from "lodash/includes";

import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    fontSize: "12px",
    color: theme.palette.questionnaire.textPrimary,
    position: "absolute",
    bottom: "5px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "100%"
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    color: theme.palette.questionnaire.textPrimary,
    marginLeft: 4
  },
  salesforce: {
    bottom: 3
  }
});

const Copyright = ({ classes, name }) => {
  const isSalesforceSurvey = includes(name, "salesforce") || includes(name, "mulesoft");
  return (
    <Typography
      className={cn(classes.root, { [classes.salesforce]: isSalesforceSurvey })}
      align="center"
    >
      {isSalesforceSurvey && (
        <p style={{ margin: 0 }}>
          Salesforce values your privacy
          <a
            href={process.env.REACT_APP_SALESFORCE_PRIVACY_POLICY_URL}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            | Privacy Statement
          </a>
        </p>
      )}
      <b>SlashData © Copyright {new Date().getFullYear()}</b> | All rights reserved |
      <a
        href={process.env.REACT_APP_PRIVACY_POLICY_URL}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        Privacy Policy |
      </a>
      <a
        href="/cookie-policy"
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        Cookie Policy
      </a>
    </Typography>
  );
};

Copyright.propTypes = {
  classes: PropTypes.shape().isRequired
};

const mapStateToProps = state => ({
  name: get(state, ["survey", "name"])
});

export default connect(mapStateToProps)(withStyles(styles)(Copyright));
