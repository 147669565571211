import React from "react";
import PropTypes from "prop-types";
import Radio from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/core/styles";
import noop from "lodash/noop";

const styles = theme => ({
  root: {
    color: theme.palette.questionnaire.selection,
    "&$checked": {
      color: theme.palette.questionnaire.checked
    }
  }
});

const RadioField = ({
  classes,
  name,
  checked,
  onChange,
  onClick,
  value,
  disabled
}) => (
  <Radio
    name={name}
    checked={checked}
    onChange={onChange}
    onClick={onClick}
    value={value}
    disabled={disabled}
    classes={{ root: classes.root }}
  />
);

RadioField.propTypes = {
  classes: PropTypes.shape().isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func
};

RadioField.defaultProps = {
  name: null,
  value: null,
  checked: false,
  disabled: false,
  onChange: noop,
  onClick: noop
};

// class Radio extends Component {
//   static get propTypes() {
//     return {
//       name: PropTypes.string,
//       value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//       checked: PropTypes.bool,
//       disabled: PropTypes.bool,
//       onChange: PropTypes.func,
//       onClick: PropTypes.func
//     };
//   }

//   static get defaultProps() {
//     return {
//       name: null,
//       value: null,
//       checked: false,
//       disabled: false,
//       onChange: noop,
//       onClick: noop
//     };
//   }

//   render() {
//     return (
//       <label>
//         <input
//           className={styles.input}
//           type="radio"
//           name={this.props.name}
//           value={this.props.value}
//           checked={this.props.checked}
//           disabled={this.props.disabled}
//           onChange={this.props.onChange}
//           onClick={this.props.onClick}
//         />
//         <span className={styles.check} />
//       </label>
//     );
//   }
// }

export default withStyles(styles)(RadioField);
