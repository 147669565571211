import shuffle from 'lodash/shuffle';

/**
 * Randomizes the elements of the supplied collection, for which the predicate returns truthy, and returns a new array.
 * @param {Array<Object>} collection
 * @param {Function} [predicate]
 * @returns {Array<Object>}
 */
export default function (collection, predicate = () => true) {
  const results = shuffle(
    collection.filter(predicate)
  );

  collection.forEach((e, i) => {
    if (!predicate(e)) {
      results.splice(i, 0, e);
    }
  });

  return results;
}
