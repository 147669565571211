import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  row: {
    padding: "20px 0",
    // marginRight: "-20px",
    borderTop: "2px solid rgba(var(--text-color), 0.1)"
  }
});

const Row = ({ classes, children }) => (
  <div className={classes.row}>{children}</div>
);

export default withStyles(styles)(Row);
