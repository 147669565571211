import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { connect } from "react-redux";
import { navigate } from "@reach/router";
import Drawer from "@material-ui/core/Drawer";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import MailIcon from "@material-ui/icons/Mail";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core/styles";

import Screen from "./components/Screen";
import { getJwtToken } from "../../state/selectors/user";
import ImportSpecForm from "./components/ImportSpecForm";
import SurveyForm from "./components/SurveyForm";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  button: {
    marginLeft: theme.spacing.unit
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    paddingLeft: drawerWidth,
    flexGrow: 1,
    padding: theme.spacing.unit * 3
  }
});

const EditView = ({ classes, slug, jwtToken }) => {
  // eslint-disable-next-line no-unused-vars
  const [loader, setLoader] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [errorMessage, setErrorMessage] = useState("");
  const [showImportSpec, setShowImportSpec] = useState(false);
  const [survey, setSurvey] = useState({
    _id: "",
    name: "",
    startDate: "",
    endDate: "",
    theme: "",
    status: "",
    type: ""
  });

  useEffect(() => {
    if (slug !== undefined) {
      getSurvey(slug);
    }
  }, [slug]);

  const getSurvey = async surveySlug => {
    setLoader(true);
    try {
      const {
        _id,
        name,
        startDate,
        endDate,
        status,
        theme,
        type
      } = await API.get("surveys", `/surveys/name/${surveySlug}`);
      setLoader(false);
      setSurvey({
        _id,
        name,
        startDate,
        endDate,
        theme,
        status,
        type
      });
    } catch (error) {
      setLoader(false);
      setErrorMessage(error.message);
    }
  };

  const publishSurvey = async () => {
    setLoader(true);
    try {
      await API.put("surveys", `/surveys/${survey._id}`);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      setErrorMessage(error.message);
    }
  };

  return (
    <Screen>
      <Drawer
        variant="permanent"
        anchor="left"
        open
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.toolbar} />
        <Divider />
        <List>
          {[{ name: "Overview", url: `/name/${survey.name}` }].map(
            ({ name, url }, index) => (
              <ListItem button key={name} onClick={() => navigate(url)}>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={name} />
              </ListItem>
            )
          )}
        </List>
      </Drawer>
      <Grid container spacing={16} justify="center" className={classes.content}>
        <Grid item xs={12}>
          <Toolbar>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={publishSurvey}
              disabled
            >
              Publish
            </Button>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => navigate(`/name/${survey.name}`)}
            >
              Preview
            </Button>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => setShowImportSpec(true)}
              disabled
            >
              Import Spec
            </Button>
          </Toolbar>
        </Grid>
        <Grid item>
          <SurveyForm survey={survey} jwtToken={jwtToken} />
        </Grid>
      </Grid>
      <ImportSpecForm
        open={showImportSpec}
        onClose={() => setShowImportSpec(false)}
      />
    </Screen>
  );
};

const mapStateToProps = state => ({
  jwtToken: getJwtToken(state)
});

export default connect(mapStateToProps)(withStyles(styles)(EditView));
