import URL from 'url-parse';
import pathParse from 'path-parse';

/**
 * Returns a function to be used as image URL transformer
 * @param {*} hostname
 */
const imageTransformer = (hostname = '', defaultExtension = '.png') => (
  ...props
) => {
  // Parse the image src url
  const parsedUrl = pathParse(props[0]);
  // Construct the asset name
  const asset = `${parsedUrl.name}${parsedUrl.ext || defaultExtension}`;

  const srcUrl = new URL();
  srcUrl.set('pathname', asset);
  srcUrl.set('protocol', 'https');
  srcUrl.set('port', '');
  srcUrl.set('hostname', hostname);

  return srcUrl.href;
};

export default imageTransformer;
