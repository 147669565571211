import "../../styles/main.css";

const styles = theme => ({
  body: {
    height: "100%",
    width: "100%",
    padding: "80px",
    [theme.breakpoints.between("xs", "sm")]: {
      padding: "0"
    },
    fontFamily: "'Roboto', sans-serif",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    display: "flex",
    justifyContent: "center",
    minHeight: "100vh",
    boxSizing: "border-box"
  },
  // mainContainer: {
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   width: "100%"
  // },
  // main: {
  //   margin: "76px 0 62px 0",
  //   padding: "1em 2em 2em 2em",
  //   overflow: "auto",
  //   minHeight: "20em",
  //   minWidth: "50%",
  //   zIndex: theme.zIndex.question
  // },
  content: {
    padding: "120px"
  },
  nextBtn: {
    position: "fixed",
    right: "40px",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: theme.zIndex.navigation,
    "@media only screen and (max-width: 1280px) and (orientation: landscape)": {
      display: "none"
    }
  },
  prevBtn: {
    position: "fixed",
    left: "40px",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: theme.zIndex.navigation,
    "@media only screen and (max-width: 1280px) and (orientation: landscape)": {
      display: "none"
    }
  },
  container: {
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 67, // AppBar height
      paddingBottom: 82 // Footer height
    },
    "@media (max-width: 320px)": {
      paddingTop: 52, // AppBar height
      paddingBottom: 65 // Footer height
    }
  }
});

export default styles;
