import React, { useEffect } from "react";

import { connect } from "react-redux";

import get from "lodash/get";
import indexOf from "lodash/indexOf";

import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

import Copyright from "../../../../components/Copyright";
import Markdown from "../shared/md";
import { getPageContent } from "../shared/selectors";
import { OUTRO_PAGE } from "../types";
import { gaPageView } from "../../../../utils/analytics";

const styles = theme => ({
  container: {
    padding: "200px 80px 80px 80px",
    [theme.breakpoints.down("xs")]: {
      padding: "80px 24px 24px 24px"
    }
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  image: {
    [theme.breakpoints.up("xl")]: {
      justifyContent: "center"
    }
  },
  content: {
    maxWidth: 450
  }
});

const panelists = ["branded"/*, "dataspring", "symmetric", "borderless"*/];

const getUrl = (member_id, ext_uuid, questionId) => {
  if (member_id === "branded") {
    const url =
      questionId === "MRK_QLF1" || questionId === "MRK_QLF2"
        ? `https://r.mintvine.com/success/?uid=${ext_uuid}`
        : `https://r.mintvine.com/nq/?uid=${ext_uuid}`;

    return url;
  }
/*
  if (member_id === "dataspring") {
    const url =
      questionId === "MRK_REF1" || questionId === "MRK_REF2"
        ? `https://d8aspring.post-survey.com/ans/back/?key=84D8Nn2h&status=comp`
        : `https://d8aspring.post-survey.com/ans/back/?key=84D8Nn2h&status=scrout`;

    return url;
  }

  if (member_id === "symmetric") {
    const url =
      questionId === "MRK_REF1" || questionId === "MRK_REF2"
        ? `https://www.daisurvey.com/surveyV3/RenderSurvey.aspx?SurveyID=gsuguhKlGRzTRU5yq%2fMMxA%3d%3d&PayCode=1&ID=${ext_uuid}`
        : `https://www.daisurvey.com/surveyV3/RenderSurvey.aspx?SurveyID=gsuguhKlGRzTRU5yq%2fMMxA%3d%3d&PayCode=2&ID=${ext_uuid}`;

    return url;
  }

  if (member_id === "borderless") {
    const url =
      questionId === "MRK_REF1" || questionId === "MRK_REF2"
        ? `https://surveys.thepanelstation.com/crdct.aspx?route=${ext_uuid}A28D49B168410E78`
        : `https://surveys.thepanelstation.com/crdct.aspx?route=${ext_uuid}2E32549167C36C23`;

    return url;
  }
*/
  return "";
};

const OutroPage = ({ classes, content, member_id, ext_uuid, questionId }) => {
  useEffect(() => {
    gaPageView();
  }, []);

  const isPanelist = indexOf(panelists, member_id) !== -1;

  if (isPanelist) {
    const url = getUrl(member_id, ext_uuid, questionId);

    const panelistContent = `##### Thank you for your response. \n\n\n Click below to go to your panel \n\n\n\n[Click Me](${url} "button")`;
    return (
      <Grid container spacing={16} className={classes.container}>
        <Grid item xs={12} className={classes.contentWrapper}>
          <Markdown content={panelistContent} className={classes.content} />
        </Grid>
        <Grid item xs={12}>
          <Copyright />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={16} className={classes.container}>
      <Grid item xs={12} className={classes.contentWrapper}>
        <Markdown content={content} className={classes.content} />
      </Grid>
      <Grid item xs={12}>
        <Copyright />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => ({
  content: getPageContent(state, OUTRO_PAGE),
  member_id: get(state, ["meta", "member_id"], ""),
  ext_uuid: get(state, ["meta", "ext_uuid"], ""),
  questionId: get(
    state,
    ["cursor", "path", get(state, ["cursor", "index"])],
    ""
  )
});

export default connect(mapStateToProps)(withStyles(styles)(OutroPage));
