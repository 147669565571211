import get from "lodash/get";

import { IMPORT_QUESTIONNAIRE, RESET } from "../types";

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case IMPORT_QUESTIONNAIRE: {
      return {
        ...state,
        member_id: get(action, ["payload", "_meta", "member_id"], ""),
        ext_uuid: get(action, ["payload", "_meta", "ext_uuid"], "")
      };
    }
    case RESET:
      return {
        ...state,
        ...initialState
      };

    default:
      return state;
  }
};

export default reducer;
