import React, { Component } from "react";
import PropTypes from "prop-types";
import { I18n } from "react-redux-i18n";

import noop from "lodash/noop";
import get from "lodash/get";
import find from "lodash/find";
import isUndefined from "lodash/isUndefined";
import styles from "./SearchableList.module.css";
import navArrowGray from "../../images/nav-arrow-gray-manatee.svg";
import navArrowWhite from "../../images/nav-arrow.svg";
import combineClassNames from "../../utils/combineClassNames";
import suggest from "../../utils/suggest";

class SearchableList extends Component {
  static get propTypes() {
    return {
      inputType: PropTypes.string,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      placeholder: PropTypes.string,
      autoFocus: PropTypes.bool,
      required: PropTypes.bool,
      limit: PropTypes.number, // max number of displayed suggestions
      itemMinHeightPx: PropTypes.number, // min height (in px) for suggestion dropdown items
      itemOffsetPx: PropTypes.number, // vertical distance (in px) between dropdown items
      onInit: PropTypes.func,
      onChange: PropTypes.func,
      onShowList: PropTypes.func,
      validate: PropTypes.func, // custom validation function,
      suggest: PropTypes.func,
      list: PropTypes.array.isRequired,
      value: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) // selected value
      }),
      duration: PropTypes.number.isRequired,
      custom: PropTypes.objectOf(
        PropTypes.shape({
          name: PropTypes.string,
          text: PropTypes.string
        })
      )
    };
  }

  static get defaultProps() {
    return {
      inputType: "text",
      placeholder: "application.start_typing",
      autoFocus: true,
      required: true, // do not allow empty submission
      limit: 3, // limit suggestions to 3 by default
      itemMinHeightPx: 45,
      itemOffsetPx: 6,
      onInit: noop,
      onChange: noop,
      onShowList: noop,
      suggest, // default suggest function
      value: null,
      custom: null,
      validate: (options = { suggestions: [], query: "" }) =>
        options.suggestions
          .map(s => s.toLowerCase())
          .indexOf(options.query.trim().toLowerCase()) > -1
    };
  }

  constructor(props) {
    super(props);

    const value = get(
      find(
        this.props.list,
        item => item.value === get(this.props.value, "value")
      ),
      "text"
    );

    this.state = {
      query: value || "",
      active: -1, // selected suggestion index. Initialize as -1 since nothing is pre-selected
      suggestions: [], // suggested options
      valid: true,
      hideDropdown: true
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    // Pass state and answer to parent (Question) component right before being appended to DOM
    this.props.onInit(
      this.props.name,
      this.props.type,
      {
        options: this.props.list,
        custom: this.props.custom
      },
      this.props.value,
      this.props.duration
    );
  }

  isInputValid(query) {
    return this.props.validate({
      suggestions: this.state.suggestions,
      query
    });
  }

  /**
   * If the active index is already -1, do nothing.
   * If the start of suggestions has been reached reset state.query and state.active.
   * Else, decrease the active index by one, set state.query to the selected
   * suggestion, and set state.valid to be true.
   */
  handleArrowUp() {
    if (this.state.active < 0) return;

    let active = -1;
    let query = "";

    if (this.state.active > 0) {
      active = this.state.active - 1;
      query = this.state.suggestions[active];
    }

    this.setState({ active, query, valid: true });
  }

  /**
   * If the end of the suggestion list has been reached, do nothing,
   * else increase the active index by one and update state.
   */
  handleArrowDown() {
    if (this.state.active === this.state.suggestions.length - 1) return;

    const active = this.state.active + 1;
    const query = this.state.suggestions[active];

    this.setState({ active, query, valid: true });
  }

  handleKeyDown(e) {
    switch (e.keyCode) {
      case 27: // esc
        this.textInput.blur();
        break;
      case 40: // arrow down
        this.handleArrowDown();
        break;
      case 38: // arrow up
        this.handleArrowUp();
        break;
      case 13: // enter
        this.handleSubmit(e);
        break;
      default:
        // reset active suggestion index
        this.setState({ active: -1 });
    }
  }

  handleChange(e) {
    this.setState({
      query: e.target.value,
      suggestions: this.props.suggest({
        query: e.target.value,
        limit: this.props.limit,
        list: this.props.list
      }),
      // UX: clear errors if possible, but only set invalid state on submit
      valid: this.state.valid ? true : this.isInputValid(e.target.value),
      hideDropdown: false
    });

    this.props.onShowList(100);
  }

  /**
   * If the event is a blur and was triggered by a click on the dropdown,
   * ignore the event and skip validation.
   * If the event was a click on the menu, update state, and trigger the onChange
   * parent callback passing an updated state object, and hide the dropdown.
   * If the event was triggered by any other action (e.g. keydown - enter),
   * or the blur relatedTarget hide the dropdown and trigger the parent callback.
   * Else simply set state to invalid.
   */
  handleSubmit(e, query, active) {
    if (isUndefined(e)) return;

    if (e.type === "blur" && e.relatedTarget !== null) {
      e.preventDefault();
      return;
    }

    if (e.type === "click" && !isUndefined(query) && !isUndefined(active)) {
      this.setState({ query, active, valid: true, hideDropdown: true });

      this.props.onChange(e, {
        value: get(find(this.props.list, item => item.text === query), "value")
      });
      return;
    }

    if (this.isInputValid(e.target.value)) {
      this.setState({ hideDropdown: true });
      this.props.onChange(e, {
        // set value as the country ISO code
        value: get(
          find(this.props.list, item => item.text === this.state.query),
          "value"
        )
      });
      return;
    }

    this.setState({ valid: false });
  }

  renderSuggestions() {
    const itemHeightSum = this.props.itemMinHeightPx * this.props.limit;
    const itemOffsetSum = this.props.itemOffsetPx * this.props.limit;
    const borderHeightSum = this.props.limit * 2; // borders are 1px thick

    // When an invalid query is submitted, the suggester should return all available suggestions.
    // In order to communicate to the user that the dropdown is scrollable, the maxHeight attr of the dropdown
    // should be set in such a manner as to ensure that the last item in the list is always half-visible,
    // no matter the limit, itemOffsetPx and itemMinHeightPx are set to.
    const dropdownMaxHeight = `${itemHeightSum +
      itemOffsetSum +
      borderHeightSum +
      this.props.itemMinHeightPx / 2}px`;

    return (
      <ul
        className={combineClassNames({
          [styles.dropdown]: true,
          [styles.hidden]: this.state.hideDropdown
        })}
        style={{ maxHeight: dropdownMaxHeight }}
      >
        {this.state.suggestions.map((suggestion, index) => (
          <li
            key={suggestion}
            className={styles.dropdownItem}
            style={{
              minHeight: this.props.itemMinHeightPx,
              marginTop: this.props.itemOffsetPx
            }}
          >
            <a
              tabIndex={index + 1}
              className={combineClassNames({
                [styles.dropdownAnchor]: true,
                [styles.highlight]: index === this.state.active
              })}
              onClick={e => {
                this.handleSubmit(e, suggestion, index);
              }}
            >
              {suggestion}
              <img
                src={this.state.active === index ? navArrowWhite : navArrowGray}
                alt={I18n.t("application.arrow")}
                className={styles.optionArrow}
              />
            </a>
          </li>
        ))}
      </ul>
    );
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <input
            type={this.props.inputType}
            name={this.props.name}
            value={this.state.query}
            placeholder={I18n.t(this.props.placeholder)}
            autoFocus={this.props.autoFocus}
            required={this.props.required}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            onBlur={this.handleSubmit}
            className={combineClassNames({
              [styles.input]: true,
              [styles.invalid]: !this.state.valid
            })}
            style={{ minHeight: this.props.itemMinHeightPx }}
            ref={input => {
              this.textInput = input;
            }}
          />
          {this.renderSuggestions()}
        </div>
      </div>
    );
  }
}

export default SearchableList;
