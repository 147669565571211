import concat from "lodash/concat";
import differenceWith from "lodash/differenceWith";
import find from "lodash/find";
import isEqual from "lodash/isEqual";

import { ADD_USER_ERROR, REMOVE_USER_ERROR, RESET } from "../types";

export default function(state = [], action) {
  const userError = { category: action.category, question: action.question };

  switch (action.type) {
    case ADD_USER_ERROR:
      if (find(state, userError)) {
        return state; // error already inserted - avoid duplicates
      }

      return concat(state, [userError]);

    case REMOVE_USER_ERROR:
      return differenceWith(state, [userError], isEqual);

    case RESET:
      return [];

    default:
      return state;
  }
}
