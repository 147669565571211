import { useEffect } from "react";

function useOnBeforeUnload(callback, slug, enable) {
  useEffect(() => {
    function onUnload(event) {
      if (enable) {
        callback();
        event.returnValue = "There is pending work. Sure you want to leave?";
        return event.returnValue;
      }

      return null;
    }

    window.addEventListener("beforeunload", onUnload);

    return function cleanUp() {
      return window.removeEventListener("beforeunload", onUnload);
    };
  }, [slug, enable]);
}

export default useOnBeforeUnload;
