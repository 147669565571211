import React, { Component } from "react";
import PropTypes from "prop-types";

import jsonLogic from "json-logic-js";
import moment from "moment";
import { connect } from "react-redux";

import assign from "lodash/assign";
import cloneDeep from "lodash/cloneDeep";
import filter from "lodash/filter";
import find from "lodash/find";
import forEach from "lodash/forEach";
import get from "lodash/get";
import has from "lodash/has";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import map from "lodash/map";
import merge from "lodash/merge";
import noop from "lodash/noop";
import set from 'lodash/set';
import split from 'lodash/split';

import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";

import assignAnswer from "../../state/actions/assignAnswer";
import assignContext from "../../state/actions/assignContext";
import removeUserError from "../../state/actions/removeUserError";
import { isContextValid } from "../../utils/context";
import {
  extendTextField,
  loadGroups,
  extendGroups,
  loadOptions,
  extendOptions
} from "../../utils/piping";
import { isQuestionRequiredAndNotAnswered } from "../../utils/question";
import randomize from "../../utils/randomize";
import getQuestionError from "../../state/selectors/userErrors";
import QuestionTitle from "./components/QuestionTitle";
import QuestionBody from "./components/QuestionBody";
import QuestionExtraText from "./components/QuestionExtraText";

import ControlWrapper from "../ControlWrapper";
import Input from "../Input";
import TextArea from "../TextArea";
import SearchableList from "../SearchableList";
import CheckboxList from "../CheckboxList";
import CheckboxGrid from "../CheckboxGrid";
import RadioList from "../RadioList";
import RadioGrid from "../RadioGrid";
import RatingList from "../RatingList";
import RatingGrid from "../RatingGrid";
// import Ranking from '../Ranking';
import ReadOnly from "../ReadOnly";

import { QUESTION_REQUIRED } from "../../state/userErrors";

const styles = () => ({
  root: {
    position: "relative",
    overflow: "hidden",
    height: "100%"
  }
});

/**
 * A question switch component which renders a question type specific component.
 */
class Question extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.startTime = moment();

    this.state = {
      scrollOffset: 0
    };
  }

  handleScroll(offset) {
    this.setState({
      scrollOffset: offset
    });
  }

  handleChange(e, value) {
    const { onChange, name, duration: delta } = this.props;
    // Keep track of duration of each change
    const duration = delta + moment().diff(this.startTime);
    this.startTime = moment();

    // Pass the minimum fields needed
    const question = {
      required: this.props.required,
      type: this.props.type,
      context: this.props.context
    };

    // Re-create context from question properties
    const context = {
      groups: this.props.groups,
      options: this.props.options,
      custom: this.props.custom
    };

    onChange({ name, value, question, context, duration });
  }

  renderUIControl() {
    switch (this.props.type) {
      case "CHECKBOX_LIST":
        return (
          <CheckboxList
            key={this.props.name}
            name={this.props.name}
            type={this.props.type}
            options={this.props.options}
            optionsRandomized={this.props.optionsRandomized}
            maxOptionsAllowed={this.props.maxOptionsAllowed}
            value={this.props.value}
            duration={this.props.duration}
            custom={this.props.custom}
            onInit={this.props.onInit}
            onChange={this.handleChange}
          />
        );

      case "CHECKBOX_GRID":
        return (
          <CheckboxGrid
            key={this.props.name}
            name={this.props.name}
            type={this.props.type}
            groups={this.props.groups}
            options={this.props.options}
            groupsRandomized={this.props.groupsRandomized}
            maxGroupsAllowed={this.props.maxGroupsAllowed}
            value={this.props.value}
            duration={this.props.duration}
            custom={this.props.custom}
            onInit={this.props.onInit}
            onChange={this.handleChange}
          />
        );

      case "RADIO_LIST":
        return (
          <RadioList
            key={this.props.name}
            name={this.props.name}
            type={this.props.type}
            options={this.props.options}
            optionsRandomized={this.props.optionsRandomized}
            value={this.props.value}
            duration={this.props.duration}
            custom={this.props.custom}
            onInit={this.props.onInit}
            onChange={this.handleChange}
          />
        );

      case "RADIO_GRID":
        return (
          <RadioGrid
            key={this.props.name}
            name={this.props.name}
            type={this.props.type}
            groups={this.props.groups}
            options={this.props.options}
            groupsRandomized={this.props.groupsRandomized}
            maxGroupsAllowed={this.props.maxGroupsAllowed}
            value={this.props.value}
            duration={this.props.duration}
            custom={this.props.custom}
            onInit={this.props.onInit}
            onChange={this.handleChange}
          />
        );

      case "RATING_LIST":
        return (
          <RatingList
            key={this.props.name}
            name={this.props.name}
            type={this.props.type}
            options={this.props.options}
            value={this.props.value}
            duration={this.props.duration}
            custom={this.props.custom}
            onInit={this.props.onInit}
            onChange={this.handleChange}
          />
        );

      case "RATING_GRID":
        return (
          <RatingGrid
            key={this.props.name}
            name={this.props.name}
            type={this.props.type}
            groups={this.props.groups}
            options={this.props.options}
            value={this.props.value}
            duration={this.props.duration}
            custom={this.props.custom}
            onInit={this.props.onInit}
            onChange={this.handleChange}
          />
        );

      case "SEARCHABLE_LIST":
        return (
          <SearchableList
            inputType="text"
            key={this.props.name}
            name={this.props.name}
            type={this.props.type}
            list={this.props.options}
            value={this.props.value}
            duration={this.props.duration}
            custom={this.props.custom}
            onInit={this.props.onInit}
            onChange={this.handleChange}
            onShowList={this.handleScroll}
          />
        );
      /*
      case 'RANKING':
        return (
          <Ranking
            key={this.props.name}
            options={this.props.options}
            maxOptionsAllowed={this.props.maxOptionsAllowed}
            value={this.props.value}
            onChange={this.handleChange}
          />
        );
      */
      case "TEXT":
        return (
          <ControlWrapper>
            <Input
              inputType="text"
              key={this.props.name}
              name={this.props.name}
              type={this.props.type}
              value={this.props.value.value}
              defaultValue={this.props.defaultValue}
              duration={this.props.duration}
              custom={this.props.custom}
              onInit={this.props.onInit}
              onChange={this.handleChange}
            />
          </ControlWrapper>
        );

      case "TEXTAREA":
        return (
          <TextArea
            key={this.props.name}
            name={this.props.name}
            type={this.props.type}
            maxLength={256}
            value={this.props.value.value}
            duration={this.props.duration}
            custom={this.props.custom}
            onInit={this.props.onInit}
            onChange={this.handleChange}
          />
        );

      case "READONLY":
        return (
          <ReadOnly
            key={this.props.name}
            name={this.props.name}
            type={this.props.type}
            duration={this.props.duration}
            custom={this.props.custom}
            content={this.props.content}
            onInit={this.props.onInit}
          />
        );

      default:
        throw new Error(`Unknown question type: ${this.props.type}`);
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Grid item xs={12} className={classes.root}>
          {/* <Question imagesHost={this.props.imagesHost} /> */}
          <Hidden smDown>
            <QuestionTitle
              title={this.props.title}
              description={this.props.description}
              required={Boolean(this.props.required)}
              error={this.props.error}
            />
            <QuestionBody>
              <Grid container>
                {!isEqual(this.props.type, "READONLY") &&
                  !isEmpty(this.props.content) && (
                    <Grid item xs={12}>
                      <QuestionExtraText content={this.props.content} />
                    </Grid>
                  )}
                <Grid item xs={12}>
                  {this.renderUIControl()}
                </Grid>
              </Grid>
            </QuestionBody>
          </Hidden>
          <Hidden mdUp>
            <QuestionBody
              error={this.props.error}
              offset={this.state.scrollOffset}
              mobile
            >
              <QuestionTitle
                title={this.props.title}
                description={this.props.description}
                required={Boolean(this.props.required)}
                error={this.props.error}
                float
              />
              <Grid container>
                {!isEqual(this.props.type, "READONLY") &&
                  !isEmpty(this.props.content) && (
                    <Grid item xs={12}>
                      <QuestionExtraText content={this.props.content} />
                    </Grid>
                  )}
                <Grid item xs={12}>
                  {this.renderUIControl()}
                </Grid>
              </Grid>
            </QuestionBody>
          </Hidden>
        </Grid>
      </>
    );
  }
}

Question.propTypes = {
  // The name of the question
  name: PropTypes.string.isRequired, // The supported question types
  type: PropTypes.oneOf([
    "CHECKBOX_GRID",
    "CHECKBOX_LIST",
    "RADIO_GRID",
    "RADIO_LIST",
    "TEXT",
    "TEXTAREA",
    "SEARCHABLE_LIST",
    "RATING_LIST",
    "RATING_GRID",
    // "RANKING",
    "READONLY"
  ]).isRequired,
  // The user answer
  value: PropTypes.shape({
    value: PropTypes.string
  }),
  classes: PropTypes.shape().isRequired,
  // The default answer for the question
  defaultValue: PropTypes.oneOfType([PropTypes.any]),
  // The duration user spent at this question
  duration: PropTypes.number,
  // The title of the question as described in json
  title: PropTypes.string.isRequired,
  // The description of the question as described in json
  description: PropTypes.string,
  // The API error
  error: PropTypes.string,
  // The content of the question, a string with markdown
  content: PropTypes.string,
  // The options of the question
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.any,
      text: PropTypes.string,
      unique: PropTypes.bool,
      fixed: PropTypes.bool,
      writeIn: PropTypes.bool,
      exclusive: PropTypes.bool,
      source: PropTypes.string
    })
  ),
  // Whether should shuffle the questions
  optionsRandomized: PropTypes.bool,
  // The count of allowed options
  maxOptionsAllowed: PropTypes.number,
  // The question groups
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      text: PropTypes.string,
      fixed: PropTypes.bool,
      writeIn: PropTypes.bool,
      source: PropTypes.string
    })
  ),
  // Whether should shuffle the groups
  groupsRandomized: PropTypes.bool,
  // The count of allowed groups
  maxGroupsAllowed: PropTypes.number,
  // Whether the question is required
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  /**
   * A snapshot of the question at the time the user answered the questions
   */
  context: PropTypes.arrayOf(
    PropTypes.shape({
      input: PropTypes.string,
      output: PropTypes.string,
      rules: PropTypes.object
    })
  ),
  /**
   * The values to be used in further questions
   */
  custom: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
      text: PropTypes.string
    })
  ),
  // Callback to be invoked on mount
  onInit: PropTypes.func,
  // Callback to be invoked on change
  onChange: PropTypes.func
};

Question.defaultProps = {
  value: {},
  defaultValue: null,
  duration: 0,
  description: null,
  error: null,
  content: "",
  options: [],
  optionsRandomized: false,
  maxOptionsAllowed: null,
  groups: [],
  groupsRandomized: false,
  maxGroupsAllowed: null,
  required: false,
  context: null,
  custom: {},
  onInit: noop,
  onChange: noop
};

export { Question as Component };

function mapStateToProps(state) {
  const { cursor } = state;

  // console.log(state.payload.context);

  // make a clone of the question object for further manipulation
  const question = cloneDeep(
    find(state.questions, { name: cursor.path[cursor.index] })
  );
  // get initial state of the component from context
  const initialState = get(state, ["payload", "context", question.name]);
  // get answer from payload (if any)
  const value = get(state, ["payload", "answer", question.name]);
  // get default answer (if any)
  const defaultValue = jsonLogic.apply(get(question, "defaultAnswer"), state);
  // get any current error for this question
  const error = getQuestionError(state.userErrors, question.name);

  // Interpolate any existing place holders based on the current context
  question.title = extendTextField(question.title, state.payload.context);
  question.content = extendTextField(question.content, state.payload.context);

  // If we have a valid state and answer then load these into the object
  if (
    !isEmpty(initialState) &&
    isContextValid(
      state.questions,
      question,
      state.payload.answer,
      state.payload.context
    )
  ) {
    // if (has(question, 'groups')
    question.groups = loadGroups(initialState.groups);
    // if has(question, 'options')
    question.options = loadOptions(initialState.options);
    question.custom = initialState.custom; // TODO: add proper load method
    // Include answer in the initial state
    return assign({}, question, {
      value: value.answer,
      duration: value.duration,
      defaultValue,
      error
    });
  }

  if(get(value, 'type') === 'TEXTAREA') {
    question.value = value.answer
  }

  // Otherwise perform initial setup:

  // Apply display logic (if any) to group and options
  if (has(question, "groups")) {
    question.groups = filter(question.groups, v =>
      jsonLogic.apply(v.displayLogic || true, { answer: state.payload.answer, context: state.payload.context })
    );
  }

  if (has(question, "options")) {
    question.options = filter(question.options, v =>
      jsonLogic.apply(v.displayLogic || true, { answer: state.payload.answer, context: state.payload.context })
    );
  }

  // Randomise groups and options if needed
  if (has(question, "groups") && question.groupsRandomized) {
    question.groups = randomize(question.groups, e => !e.fixed);
  }

  if (has(question, "options") && question.optionsRandomized) {
    question.options = randomize(question.options, e => !e.fixed);
  }

  // Pipe groups and options if needed
  if (has(question, "groups") && find(question.groups, g => has(g, "import"))) {
    question.groups = extendGroups(
      state.questions,
      question,
      state.payload.answer
    );
  }

  if (
    has(question, "options") &&
    find(question.options, o => has(o, "import"))
  ) {
    question.options = extendOptions(
      state.questions,
      question,
      state.payload.answer,
      state.payload.context
    );
  }

  return assign({}, question, { defaultValue, error });
}

function mapDispatchToProps(dispatch) {
  const onInit = (name, type, state, answer, duration) => {
    dispatch(assignContext(name, state));
    dispatch(assignAnswer(name, type, answer, duration));
  };

  const onChange = ({ name, value, question, context, duration }) => {
    dispatch(assignAnswer(name, question.type, value, duration));

    // Upon any change, generate custom vars (if any)
    if (question.context) {
      const custom = {};

      forEach(question.context, ctx => {
        // Convert value object into an array of objects of the form { name, value }
        const mapped = map(value, (value, name) => ({ name, value })); // eslint-disable-line no-shadow
        // Remove any values from the excluded list
        const answer = filter(mapped, v => !includes(ctx.exclude, v.name));
        // NOTE: we pass the data context with a fixed key called "answer" (also defined as-is in the spec)
        const output = jsonLogic.apply(ctx.rules, { answer });

        /* **************************** */
        // FIXME: this is hackish. In the future use newer version of json-logic supporting native filter, map, reduce
        // Also note that this was currently tested for checkbox lists, radio lists and radio grids only. Other types need testing.
        // In a future these variables should just contain the option name instead of the full object

        // Search by name or value depending on question type
        let option = null;

        if (question.type === "RADIO_LIST") {
          // Output could be null if all options are unselected so handle that specific case
          option = output
            ? find(context[ctx.input], { value: output.value })
            : null;
        } else if (
          question.type === "CHECKBOX_LIST" ||
          question.type === "RATING_LIST" ||
          question.type === "RADIO_GRID"
        ) {
          // Output could be null if all options are unselected so handle that specific case
          option = output
            ? find(context[ctx.input], { name: output.name })
            : null;
        } else if (question.type === 'CHECKBOX_GRID') {
          // Output could be null if all options are unselected so handle that specific case
          option = output
            ? find(context[ctx.input], { name: output.name })
            : null;
        } else if (question.type === 'SEARCHABLE_LIST') {
          option = { name: output };
        } else {
          throw new Error(`Unsupported question type: ${question.type}`);
        }
        /* **************************** */

        // Generate context object for this question
        const obj = { [ctx.output]: option };

        merge(custom, obj);
      });

      // console.log(custom);

      // See if context vars are set to be overridden
      const params = (new URL(document.location)).searchParams;
      const queryVar = params.getAll('context');

      // URL format for query parameters: context=questionId.CTX_VAR:value
      // If multiple context vars are defined: context=questionId.CTX_VAR1:value1&context=questionId.CTX_VAR2:value2
      const dest = {};
      for (const q of queryVar) {
        const values = split(q, ':');
        merge(dest, set({}, values[0], values[1]));
      }

      // If URL defines ctx var, override it
      if (has(dest, name)) {
        // console.log('Overriding ctx vars');
        const localCtx = get(dest, name, {});
        for (const k in localCtx) {
          merge(custom, set({}, [k, 'name'], localCtx[k]));
        }
        // console.log(custom);
      }

      // Pass context containing both fixed and custom properties
      dispatch(assignContext(name, assign({}, context, { custom })));
    }

    if (!isQuestionRequiredAndNotAnswered(question, value, context)) {
      dispatch(removeUserError(QUESTION_REQUIRED, name));
    }
  };

  return { onInit, onChange };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Question));
