/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import chunk from "lodash/chunk";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  grid: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px"
    }
  },
  row: {
    display: "flex",
    paddingLeft: "350px",
    paddingRight: "400px",
    "@media only screen and (max-width: 1280px) and (orientation: landscape)": {
      paddingLeft: 100,
      paddingRight: 100
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "100px",
      paddingRight: "100px"
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      flexDirection: "column"
    },
    "&:nth-child(odd)": {
      backgroundColor: theme.palette.questionnaire.stripe
    }
  },
  item: {
    cursor: "pointer",
    overflow: "hidden",
    width: "100% !important"
  }
});

const ListLayout = ({ classes, children, columns, width }) => {
  const rows = chunk(children, columns);
  return (
    <div className={classes.grid}>
      {rows.map((items, i) => (
        <div key={`row-${i}`} className={classes.row}>
          {items.map((item, j) => (
            <MediaQuery key={`media-${j}`} maxWidth={480}>
              {matches =>
                matches ? (
                  <div key={`item-${j}`} className={classes.item}>
                    {item}
                  </div>
                ) : (
                  <div
                    key={`item-${j}`}
                    className={classes.item}
                    style={{ width }}
                  >
                    {item}
                  </div>
                )
              }
            </MediaQuery>
          ))}
        </div>
      ))}
    </div>
  );
};

ListLayout.propTypes = {
  columns: PropTypes.number,
  width: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element]))
  ]),
  classes: PropTypes.shape().isRequired
};

ListLayout.defaultProps = {
  columns: 1,
  width: "auto",
  children: null
};

export default withStyles(styles)(ListLayout);
