import assign from "lodash/assign";
import get from "lodash/get";
import {
  ASSIGN_ANSWER,
  ASSIGN_CONTEXT,
  IMPORT_QUESTIONNAIRE,
  RESET
} from "../types";

const initialState = {
  graph: [],
  answer: {},
  context: {},
  current: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case IMPORT_QUESTIONNAIRE:
      return assign({}, state, {
        graph: get(action, "graph"),
        answer: get(action, "payload.answer", {}),
        context: get(action, "payload.context", {}),
        current: get(action, "payload.current", null)
      });

    case ASSIGN_ANSWER:
      return {
        ...state,
        current: action.key,
        answer: {
          ...state.answer,
          [action.key]: {
            type: action.questionType,
            answer: action.value,
            duration: action.duration
          }
        }
      };

    case ASSIGN_CONTEXT:
      return {
        ...state,
        current: action.key,
        context: {
          ...state.context,
          [action.key]: action.value
        }
      };

    case RESET:
      return {
        ...state,
        ...initialState
      };

    default:
      return state;
  }
}
