import React from "react";
import PropTypes from "prop-types";

import get from "lodash/get";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core/styles";

import Languages from "./components/Languages";
import User from "./components/User";
import AutoSave from "./components/AutoSave";
import Keyboard from "./components/Keyboard";

const styles = theme => ({
  appBar: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      height: "67px"
    },
    "@media (max-width: 320px)": {
      height: "52px"
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "50px",
      paddingRight: "50px"
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "105px",
      paddingRight: "250px"
    },
    backgroundColor: "#ffffff",
    boxShadow: "0px 6px 21px 0 rgba(154, 170, 176, 0.17)"
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0,
    display: "flex",
    alignItems: "center",
    height: "100%",
    "@media (max-width: 320px)": {
      height: "52px"
    }
  },
  logo: {
    "max-width": "75%",
    "max-height": "80%"
  }
});

const QuestionnaireAppBar = ({
  classes,
  hideLogo,
  hideAutoSave,
  userProps,
  Images
}) => {
  const Logo = get(Images, "Logo", "");

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        {!hideLogo && (
          <img src={Logo} className={classes.logo} alt="app-bar-logo" />
        )}
        <div style={{ flexGrow: 1 }} />
        {!hideAutoSave && <Keyboard />}
        <AutoSave hide={hideAutoSave} Images={Images} />
        <User {...userProps} />
        <Languages />
      </Toolbar>
    </AppBar>
  );
};

QuestionnaireAppBar.propTypes = {
  classes: PropTypes.shape().isRequired,
  hideAutoSave: PropTypes.bool.isRequired,
  // Props for the User component
  userProps: PropTypes.shape(),
  // Images to extract logo
  Images: PropTypes.shape().isRequired
};

QuestionnaireAppBar.defaultProps = {
  userProps: {}
};

export default withStyles(styles)(QuestionnaireAppBar);
