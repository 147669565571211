import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  item: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    justifyContent: "center",
    minHeight: "4em",
    padding: "4px",
    textAlign: "center",
    border: "2px solid transparent",
    wordWrap: "break-word"
    // wordBreak: "break-word"
  }
});

const GridItem = ({ classes, children }) => (
  <div className={classes.item}>{children}</div>
);

export default withStyles(styles)(GridItem);
