import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core/styles";
import { navigate } from "@reach/router";
import { connect } from "react-redux";
import map from "lodash/map";

import Snackbar from "../../components/Snackbar";
import { getJwtToken } from "../../state/selectors/user";
import Screen from "./components/Screen";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    height: "75vh",
    overflowY: "auto",
  },
  table: {
    minWidth: 1020,
    [theme.breakpoints.down("lg")]: {
      minWidth: 800
    }
  },
  tableWrapper: {
    overflowX: "auto"
  },
  interactiveCell: {
    cursor: "pointer"
  }
});

const Surveys = ({ classes, jwtToken }) => {
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [surveys, setSurveys] = useState([]);

  useEffect(() => {
    fetchSurveys();
  }, []);

  const fetchSurveys = async () => {
    setLoader(true);
    try {
      const data = await API.get("surveys", "/surveys", {
        headers: { Authorization: `Bearer ${jwtToken}` }
      });
      setLoader(false);
      setSurveys(data);
    } catch (error) {
      setLoader(false);
      setErrorMessage(error.message);
    }
  };

  return (
    <Screen>
      <Toolbar>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/admin/surveys/new")}
        >
          Create Survey
        </Button>
      </Toolbar>
      {loader && <div>Fetching surveys</div>}
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {map(
                  ["name", "start date", "end date", "actions"],
                  (label, index) => (
                    <TableCell key={index}>{label}</TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {map(surveys, ({ _id, name, startDate, endDate }) => (
                <TableRow hover key={_id}>
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => navigate(`/name/${name}`)}
                    className={classes.interactiveCell}
                  >
                    {name}
                  </TableCell>
                  <TableCell>{startDate || "Category"}</TableCell>
                  <TableCell>{endDate || "Access"}</TableCell>
                  <TableCell
                    onClick={() => navigate(`/admin/surveys/${name}`)}
                    className={classes.interactiveCell}
                  >
                    Edit
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Paper>
      <Snackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
        open={Boolean(errorMessage)}
      />
    </Screen>
  );
};

const mapStateToProps = state => ({
  jwtToken: getJwtToken(state)
});

export default connect(mapStateToProps)(withStyles(styles)(Surveys));
