import React, { Component } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import noop from "lodash/noop";
import omitBy from "lodash/omitBy";
import partial from "lodash/partial";
import some from 'lodash/some';
import Radio from "../Radio";
import ListLayout from "../ListLayout";
import Input from "../Input";
import styles from "./RadioList.module.css";

/**
 * A component to handle RADIO_LIST type of question.
 */
class RadioList extends Component {
  constructor(props) {
    super(props);

    const { value, writeIn, source } = props.value;

    this.state = { value, writeIn, source };
    this.handleChange = this.handleChange.bind(this);
    this.handleWriteIn = this.handleWriteIn.bind(this);
    this.handleWriteInClick = this.handleWriteInClick.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  componentWillMount() {
    // Pass state and answer to parent (Question) component right before being appended to DOM
    this.props.onInit(
      this.props.name,
      this.props.type,
      {
        //      options: map(this.props.options, (v) => omitBy({ name: v.name, source: v.source, path: v.path }, isUndefined))
        options: this.props.options,
        custom: this.props.custom
      },
      this.props.value,
      this.props.duration
    );
  }

  handleChange(option, e) {
    const obj = {};
    const hasWriteIn = some(this.props.options, (o) => o.writeIn);

    if (this.state.value !== option.value) {
      // previously unchecked
      obj.value = option.value;
      obj.source = option.source;
    } else {
      // previously checked -> uncheck
      obj.value = null;
      obj.source = null;
    }

    // If any option has a writeIn flag but isn't currently selected, nullify its text content
    if (hasWriteIn && !option.writeIn) {
      obj.writeIn = "";
    }

    this.setState(obj);
    this.props.onChange(e, omitBy(obj, isEmpty));
  }

  handleWriteIn(option, e) {
    if (this.state.value !== option.value) {
      return; // exit - do nothing
    }

    const writeIn = e.target.value;
    this.setState({ writeIn });

    this.props.onChange(
      e,
      omitBy(
        {
          writeIn,
          value: this.state.value,
          source: this.state.source
        },
        isEmpty
      )
    );
  }

  // If user clicks on writeIn the we force this item to be checked
  handleWriteInClick(e) {
    const option = find(this.props.options, { name: e.target.name });

    if (this.state.value === option.value) {
      // previously checked
      return; // exit - do nothing
    }

    this.handleChange(option, e);
  }

  renderItem(option) {
    return (
      <label key={option.name} className={styles.item}>
        <Radio
          name={option.name}
          value={option.value}
          checked={this.state.value === option.value}
          onClick={partial(this.handleChange, option)}
        />
        <span className={styles.text}>
          {option.writeIn ? (
            <Input
              name={option.name}
              placeholder={option.text}
              /*
              value={
                this.state.value === option.value ? this.state.writeIn : ""
              }*/
              value={this.state.writeIn}
              /*disabled={this.state.value !== option.value}*/
              onChange={partial(this.handleWriteIn, option)}
              onClick={this.handleWriteInClick}
            />
          ) : (
            option.text || option.value
          )}
        </span>
      </label>
    );
  }

  render() {
    return (
      <ListLayout columns={1}>
        {this.props.options.map(o => this.renderItem(o))}
      </ListLayout>
    );
  }
}

RadioList.propTypes = {
  // The name of the question
  name: PropTypes.string.isRequired,
  // The type of the question (RADIO_LIST)
  type: PropTypes.string.isRequired,
  // The options of the question
  options: PropTypes.arrayOf(
    PropTypes.shape({
      // The name of the option
      name: PropTypes.string.isRequired,
      // The value of the option
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
      ]).isRequired,
      // The text of the option to be displayed
      text: PropTypes.string.isRequired,
      // NOTE: The option.fixed is not used internally
      fixed: PropTypes.boolean,
      // NOTE: The option.source is not used internally
      source: PropTypes.string,
      // NOTE: The option.path is not used internally
      path: PropTypes.string
    })
  ).isRequired,
  // The value the user has answered
  value: PropTypes.shape({
    // selected value
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool
    ]),
    writeIn: PropTypes.string,
    source: PropTypes.string
  }),
  // The duration user spent at this question
  duration: PropTypes.number.isRequired,
  custom: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
      text: PropTypes.string
    })
  ),
  onInit: PropTypes.func,
  onChange: PropTypes.func
};

RadioList.defaultProps = {
  value: {},
  custom: null,
  onInit: noop,
  onChange: noop
};

export default RadioList;
