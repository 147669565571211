import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { connect } from "react-redux";

import {
  SURVEY_STATUS,
  STATUS_OK,
  STATUS_ERROR
} from "../../../../state/status";
import Copyright from "../../../../components/Copyright";
import setAppStatus from "../../../../state/actions/setAppStatus";
import Snackbar from "../../../../components/Snackbar";

const styles = theme => ({
  root: {
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "16px",
      paddingRight: "16px"
    }
  },
  progress: {
    width: "404px",
    height: "6px",
    marginTop: "71px"
  }
});

const LoadingPage = ({ classes, surveyError, doClearSurveyError, Images }) => {
  const { LoadingAsset } = Images;

  return (
    <>
      <div className={classes.root}>
        <img
          src={LoadingAsset}
          height={190}
          alt="loading-illustration"
        />
        <LinearProgress className={classes.progress} />
      </div>
      <Snackbar
        message={surveyError}
        onClose={doClearSurveyError}
        open={Boolean(surveyError)}
      />
      <Copyright />
    </>
  );
};

LoadingPage.propTypes = {
  classes: PropTypes.shape().isRequired
};

const mapStateToProps = state => ({
  surveyError:
    state.status[SURVEY_STATUS] &&
    state.status[SURVEY_STATUS].value === STATUS_ERROR &&
    state.status[SURVEY_STATUS].text
      ? state.status[SURVEY_STATUS].text
      : ""
});

const mapDispatchToProps = dispatch => ({
  doClearSurveyError: () => dispatch(setAppStatus(SURVEY_STATUS, STATUS_OK))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LoadingPage));
