import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import moment from "moment";
import find from "lodash/find";

import execAuthenticatedFlow from "./flow/execAuthenticatedFlow";
import execAnonymousFlow from "./flow/execAnonymousFlow";

const useUserFlow = (
  initSurvey,
  metadata,
  versionId,
  locale,
  questionId,
  branch,
  doImportQuestionnaire,
  doImportUser
) => {
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showLandingPage, setShowLandingPage] = useState(false);
  const [showClosingPage, setShowClosingPage] = useState(false);
  const [prepareQuestionnaire, setPrepareQuestionnaire] = useState(false);
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [survey, setSurvey] = useState(initSurvey);

  useEffect(() => {
    if (!isEmpty(survey) && !isEmpty(survey._id)) {
      if (moment(survey.endDate).isBefore()) {
        setShowClosingPage(true);
      } else if (find(survey.pages, { name: "INTRO" }) !== undefined) {
        setShowLandingPage(true);
      } else {
        setPrepareQuestionnaire(true);
      }
    }

    // const versionModes = reduce(
    //   survey.versions,
    //   (acc, { auth }) => (auth === true ? acc + 1 : acc),
    //   0
    // );
    // setIsPrivate(versionModes === survey.versions.length);
    // setIsAnonymousOnly(versionModes === 0);
    if (survey.type === "private") {
      setIsPrivate(true);
    } else if (survey.type === "public") {
      setIsPublic(true);
    }
  }, [survey]);

  useEffect(() => {
    const handleFlow = async () => {
      let payload = {};
      let responseId = null;
      let version = {};

      if (prepareQuestionnaire) {
        setLoader(true);
        try {
          if (isPrivate) {
            const data = await execAuthenticatedFlow(
              survey,
              metadata,
              versionId,
              locale,
              branch
            );
            payload = get(data, "payload");
            responseId = get(data, "responseId");
            version = get(data, "version");
            doImportUser();
          }

          if (isPublic) {
            const data = await execAnonymousFlow(
              survey,
              metadata,
              versionId,
              locale,
              branch
            );
            payload = get(data, "payload");
            responseId = get(data, "responseId");
            version = get(data, "version");
            setIsAnonymous(true);
          }

          if (!isPublic && !isPrivate) {
            try {
              const data = await execAuthenticatedFlow(
                survey,
                metadata,
                versionId,
                locale,
                branch
              );
              payload = get(data, "payload");
              responseId = get(data, "responseId");
              version = get(data, "version");
              doImportUser();
            } catch (error) {
              const data = await execAnonymousFlow(
                survey,
                metadata,
                versionId,
                locale,
                branch
              );
              payload = get(data, "payload");
              responseId = get(data, "responseId");
              version = get(data, "version");
              setIsAnonymous(true);
            }
          }

          // Fetch the survey doc
          const surveyId = get(survey, "_id", "");
          // Fetch a localized version of the questionnaire
          const questionnaire = await API.get(
            "surveys",
            `/surveys/${surveyId}/questions/locale/${locale}`
          );

          doImportQuestionnaire({
            blocks: questionnaire.blocks,
            questions: questionnaire.questions,
            version,
            slug: surveyId,
            question: survey.status === "draft" ? questionId : undefined,
            responseId,
            payload
          });
          setPrepareQuestionnaire(false);
          setShowQuestionnaire(true);
        } catch (error) {
          setErrorMessage(error.message || error);
        } finally {
          setLoader(false);
        }
      }
    };

    handleFlow();
  }, [prepareQuestionnaire]);

  const doPrepareQuestionnaire = () => {
    setShowLandingPage(false);
    setPrepareQuestionnaire(true);
  };

  const doExecFlow = surveyDoc => setSurvey(surveyDoc);

  return {
    showLandingPage,
    showClosingPage,
    showQuestionnaire,
    flowLoader: loader,
    flowError: errorMessage,
    isAnonymous,
    isPublic,
    doClearFlowError: () => setErrorMessage(null),
    doExecFlow,
    doPrepareQuestionnaire
  };
};

export default useUserFlow;
