export const de = {
  primary: {
    main: "#fd6983",
    dark: "#6a27e8"
  },
  secondary: {
    main: "#378aff"
  },
  error: {
    main: "#e40000"
  },
  custom: {
    main: "#b0c0da"
  },
  questionnaire: {
    section: "#fd6983",
    textPrimary: "#2b2a28",
    textSecondary: "#b0c0da",
    textRequired: "#e40000",
    stripe: "#f1f3f8",
    selection: "#b0c0da",
    checked: "#378aff"
  },
  auth: {
    error: "#e40000",
    main: "#b0c0da",
    title: {
      font: "'Paytone One', sans- serif"
    },
    cta: "#6a27e8",
    readonly: "#b0c0da",
    tnc: "#2b2a28"
  }
};

export const arvr = {
  primary: {
    main: "#8700a1",
    dark: "#CC278D"
  },
  secondary: {
    main: "#378aff"
  },
  error: {
    main: "#e40000"
  },
  custom: {
    main: "#b0c0da"
  },
  questionnaire: {
    section: "#8700a1",
    textPrimary: "#2b2a28",
    textSecondary: "#b0c0da",
    textRequired: "#e40000",
    stripe: "rgba(181, 183, 188, 0.14)",
    selection: "#b0c0da",
    checked: "#378aff"
  },
  auth: {
    error: "#e40000",
    main: "#b0c0da",
    title: {
      font: "'Paytone One', sans- serif"
    },
    cta: "#8700a1",
    readonly: "#b0c0da",
    tnc: "#2b2a28"
  }
};

export const mulesoft = {
  primary: {
    main: "#00a2df",
    dark: "#00044c"
  },
  secondary: {
    main: "#4a5568"
  },
  error: {
    main: "#e40000"
  },
  custom: {
    main: "#b0c0da"
  },
  questionnaire: {
    section: "#b0c0da",
    textPrimary: "#000000",
    textSecondary: "#4a5568",
    textRequired: "#e40000",
    stripe: "rgba(243, 251, 255, 1.0)",
    selection: "#00a2df",
    checked: "#00a2df"
  },
  auth: {
    error: "#e40000",
    main: "#b0c0da",
    title: {
      font: "'Paytone One', sans- serif"
    },
    cta: "#8700a1",
    readonly: "#b0c0da",
    tnc: "#2b2a28"
  }
};

export const salesforce = {
  primary: {
    main: "#205c9f",
    dark: "#b0c0da"
  },
  secondary: {
    main: "#4a5568"
  },
  error: {
    main: "#e40000"
  },
  custom: {
    main: "#b0c0da"
  },
  questionnaire: {
    section: "#b0c0da",
    textPrimary: "#4a5568",
    textSecondary: "#4a5568",
    textRequired: "#e40000",
    stripe: "rgba(237, 242, 247, 1.0)",
    selection: "#205c9f",
    checked: "#205c9f"
  },
  auth: {
    error: "#e40000",
    main: "#b0c0da",
    title: {
      font: "'Paytone One', sans- serif"
    },
    cta: "#8700a1",
    readonly: "#b0c0da",
    tnc: "#2b2a28"
  }
};

export const dpl = {
  primary: {
    main: "#f7a900",
    dark: "#550c70"
  },
  secondary: {
    main: "#378aff"
  },
  error: {
    main: "#e40000"
  },
  custom: {
    main: "#b0c0da"
  },
  questionnaire: {
    section: "#fd6983",
    textPrimary: "#2b2a28",
    textSecondary: "#b0c0da",
    textRequired: "#e40000",
    stripe: "#f1f3f8",
    selection: "#b0c0da",
    checked: "#378aff"
  },
  auth: {
    error: "#e40000",
    main: "#b0c0da",
    title: {
      font: "'Paytone One', sans- serif"
    },
    cta: "#6a27e8",
    readonly: "#b0c0da",
    tnc: "#2b2a28"
  }
};

// const palettes = {
//   de,
//   arvr
// };

// const palette = palettes[process.env.REACT_APP_THEME];

export default de;
