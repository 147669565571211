import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { I18n } from "react-redux-i18n";

import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  root: { fontSize: "14px", fontWeight: 300, color: "#2b2a28 !important" },
  tnc: {
    fontSize: "14px",
    marginLeft: "4px",
    color: "inherit",
    fontWeight: "normal"
  }
});

const TnC = ({ classes, pretext, url, className }) => (
  <Typography color="textSecondary" className={cn(classes.root, className)}>
    {pretext}
    <a
      href={url}
      className={classes.tnc}
      target="_blank"
      rel="noopener noreferrer"
    >
      {I18n.t("terms-and-conditions")}
    </a>
  </Typography>
);

TnC.propTypes = {
  classes: PropTypes.shape().isRequired,
  pretext: PropTypes.string,
  url: PropTypes.string,
  className: PropTypes.string
};

TnC.defaultProps = {
  pretext: "",
  url: "",
  className: ""
};

export default withStyles(styles)(TnC);
