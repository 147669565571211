import find from "lodash/find";
import { SET_CURSOR_NEXT } from "../types";
import { isQuestionRequiredAndNotAnswered } from "../../utils/question";
import { QUESTION_NOT_FOUND, QUESTION_REQUIRED } from "../userErrors";
import addUserError from "./addUserError";

function setCursorNext(blocks, questions, payload) {
  return {
    blocks,
    questions,
    payload,
    type: SET_CURSOR_NEXT
  };
}

export default function(blocks, questions, payload, current) {
  const question = find(questions, { name: current });

  if (!question) {
    return dispatch => {
      dispatch(addUserError(QUESTION_NOT_FOUND, current));
    };
  }

  const value = payload.answer[question.name];
  const context = payload.context[question.name];

  if (isQuestionRequiredAndNotAnswered(question, value.answer, context)) {
    return dispatch => {
      dispatch(addUserError(QUESTION_REQUIRED, current));
    };
  }

  return dispatch => {
    dispatch(setCursorNext(blocks, questions, payload));
  };
}
