import { IMPORT_QUESTIONNAIRE, RESET } from "../types";

export default function(state = [], action) {
  switch (action.type) {
    case IMPORT_QUESTIONNAIRE:
      return action.blocks;

    case RESET:
      return [];

    default:
      return state;
  }
}
