export const IMPORT_SURVEY = "IMPORT_SURVEY";
export const IMPORT_QUESTIONNAIRE = "IMPORT_QUESTIONNAIRE";
export const SET_RESPONSE_ID = "SET_RESPONSE_ID";
export const ASSIGN_ANSWER = "ASSIGN_ANSWER";
export const ASSIGN_CONTEXT = "ASSIGN_CONTEXT";
export const SET_CURSOR_NEXT = "SET_CURSOR_NEXT";
export const SET_CURSOR_PREVIOUS = "SET_CURSOR_PREVIOUS";
export const SET_CURSOR = "SET_CURSOR";
export const MARK_DIRTY_QUESTION = "MARK_DIRTY_QUESTION";
export const UNMARK_DIRTY_QUESTION_BATCH = "UNMARK_DIRTY_QUESTION_BATCH";
export const RESET_APP_STATUS = "RESET_APP_STATUS";
export const SET_APP_STATUS = "SET_APP_STATUS";
export const ADD_USER_ERROR = "ADD_USER_ERROR";
export const REMOVE_USER_ERROR = "REMOVE_USER_ERROR";
export const RESET = "RESET";
export const SIGN_OUT = "SIGN_OUT";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const CHANGE_AUTHENTICATOR_VIEW = "CHANGE_AUTHENTICATOR_VIEW";
