import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";

import { isUpdatingResponse } from "../../../../../../state/selectors/status";
import pushResponse from "../../../../../../state/actions/pushResponse";

const styles = theme => ({
  root: {
    width: 20,
    height: 20,
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      height: 23,
      width: 23
    },
    "@media (max-width: 320px)": {
      height: 18,
      width: 18
    }
  },
  icon: {
    width: 20,
    height: 20,
    [theme.breakpoints.down("sm")]: {
      height: 23,
      width: 23
    },
    "@media (max-width: 320px)": {
      height: 18,
      width: 18
    }
  },
  progress: {
    marginRight: "4px"
  }
});

const AutoSave = ({ classes, hide, isSaving, Images, doPushResponse }) => {
  if (hide) return null;

  if (isSaving) {
    return (
      <CircularProgress className={classes.progress} size={20} thickness={4} />
    );
  }

  const { SaveIcon } = Images;

  return (
    <IconButton
      color="primary"
      onClick={doPushResponse}
      className={classes.root}
    >
      <img src={SaveIcon} className={classes.icon} alt="save icon" />
    </IconButton>
  );
};

AutoSave.propTypes = {
  classes: PropTypes.shape().isRequired,
  isSaving: PropTypes.bool.isRequired,
  doPushResponse: PropTypes.func.isRequired,
  hide: PropTypes.bool.isRequired,
  // Images to extract save icon
  Images: PropTypes.shape().isRequired
};

const mapStateToProps = state => ({
  isSaving: isUpdatingResponse(state)
});

const mapDispatchToProps = dispatch => ({
  doPushResponse: () => dispatch(pushResponse())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AutoSave));
