import jsonLogic from 'json-logic-js';
import difference from 'lodash/difference';
import every from 'lodash/every';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import xorBy from 'lodash/xorBy';

import { extendGroup, extendOption } from './piping';

/**
 * Determine if the question self (ie. non-piped) groups stored in the context are valid.
 *
 * @param  {Array.<Object>} questions: the questions spec (state.questions)
 * @param  {Object} question    : the question to check the context
 * @param  {Object} answer      : the answer object containing current responses
 * @param  {Object} context     : the context object
 * @return {Bool}               :
 */
function areContextSelfGroupsValid(questions, question, answer, context) {
  const ctx = context[question.name];

  // Remove piped items
  const contextSelfGroups = filter(ctx.groups, (item) => isUndefined(item.source));
  const questionSelfGroups = filter(question.groups, (item) => isUndefined(item.import));

  // Apply logic over the current answers set
  const filteredGroups = filter(questionSelfGroups, (v) =>
    jsonLogic.apply(v.displayLogic || true, { answer, context })
  );

  // Items in context will be valid if the same set is found in the original question
  return xorBy(contextSelfGroups, filteredGroups, 'name').length === 0;
}

/**
 * Determine if the question groups stored in the context are valid.
 *
 * @param  {Array.<Object>} questions: the questions spec (state.questions)
 * @param  {Object} question    : the question to check the context
 * @param  {Object} answer      : the answer object containing current responses
 * @param  {Object} context     : the context object
 * @return {Bool}               :
 */
function areContextPipedGroupsValid(questions, question, answer, context) {
  const ctx = context[question.name];
  // Group items by source, eliminating those without source attribute
  const groupedBySource = groupBy(
    pickBy(ctx.groups, 'source'),
    'source'
  );

  return every(groupedBySource, (v, k) => {
    // Get a list of current options names
    const currentItems = map(v, (i) => i.name);
    // Get the import entry for this source
    const group = find(question.groups, (item) => get(item, 'import.from.name') === k);
    // Generate list of allowed items
    const allowedItems = map(
      extendGroup(questions, group, answer),
      (i) => i.name
    );
    // Get max items limit
    const limit = group.import.limit ? group.import.limit : allowedItems.length;
    // If there were fewer items selected than the limit and new ones have been added then invalidate
    const noNewValidItemsForGaps = !(currentItems.length < limit && currentItems.length < allowedItems.length);
    // Otherwise items should always be contained (due to use of limit in piping) in the list of allowed answers
    return noNewValidItemsForGaps && difference(currentItems, allowedItems).length === 0;
  });
}

/**
 * Determine if the question options stored in the context are valid.
 *
 * @param  {Array.<Object>} questions: the questions spec (state.questions)
 * @param  {Object} question    : the question to check the context
 * @param  {Object} answer      : the answer object containing current responses
 * @param  {Object} context     : the context object
 * @return {Bool}               :
 */
function areContextPipedOptionsValid(questions, question, answer, context) {
  const ctx = context[question.name];
  // Group items by source, eliminating those without source attribute
  const groupedBySource = groupBy(
    pickBy(ctx.options, 'source'),
    'source'
  );

  return every(groupedBySource, (v, k) => {
    // Get a list of current options names
    const currentItems = map(v, (i) => i.name);
    // Get the import entry for this source
    const option = find(question.options, (item) => item.import.from.name === k);
    // Generate list of allowed items
    const allowedItems = map(
      extendOption(questions, option, answer, context),
      (i) => i.name
    );
    // Get max items limit
    const limit = option.import.limit ? option.import.limit : allowedItems.length;
    // If there were fewer items selected than the limit and new ones have been added then invalidate
    const noNewValidItemsForGaps = !(currentItems.length < limit && currentItems.length < allowedItems.length);
    // Otherwise items should always be contained (due to use of limit in piping) in the list of allowed answers
    return noNewValidItemsForGaps && difference(currentItems, allowedItems).length === 0;
  });
}

/**
 * Determine if the question self (ie. non-piped) options stored in the context are valid.
 *
 * @param  {Array.<Object>} questions: the questions spec (state.questions)
 * @param  {Object} question    : the question to check the context
 * @param  {Object} answer      : the answer object containing current responses
 * @param  {Object} context     : the context object
 * @return {Bool}               :
 */
function areContextSelfOptionsValid(questions, question, answer, context) {
  const ctx = context[question.name];

  // Remove piped items
  const contextSelfOptions = filter(ctx.options, (item) => isUndefined(item.source));
  const questionSelfOptions = filter(question.options, (item) => isUndefined(item.import));

  // Apply logic over the current answers set
  const filteredOptions = filter(questionSelfOptions, (v) =>
    jsonLogic.apply(v.displayLogic || true, { answer, context })
  );

  // Items in context will be valid if the same set is found in the original question
  return xorBy(contextSelfOptions, filteredOptions, 'name').length === 0;
}

/**
 * Determine if the all objects stored in the context are valid.
 *
 * @param  {Array.<Object>} questions: the questions spec (state.questions)
 * @param  {Object} question    : the question to check the context
 * @param  {Object} answer      : the answer object containing current responses
 * @param  {Object} context     : the context object
 * @return {Bool}               :
 */
export function isContextValid(questions, question, answer, context) {
  // console.log(areContextSelfGroupsValid(questions, question, answer, context));
  // console.log(areContextPipedGroupsValid(questions, question, answer, context));
  // console.log(areContextSelfOptionsValid(questions, question, answer, context));
  // console.log(areContextPipedOptionsValid(questions, question, answer, context));

  return (
    areContextSelfGroupsValid(questions, question, answer, context) &&
    areContextPipedGroupsValid(questions, question, answer, context) &&
    areContextSelfOptionsValid(questions, question, answer, context) &&
    areContextPipedOptionsValid(questions, question, answer, context)
  );
}
