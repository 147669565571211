import React from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import Input from "../Input/Input";
import combineClassNames from "../../utils/combineClassNames";
import styles from "./TextArea.module.css";

class TextArea extends Input {
  static get propTypes() {
    return {
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      valid: PropTypes.bool,
      placeholder: PropTypes.string,
      autoFocus: PropTypes.bool,
      minLength: PropTypes.number,
      maxLength: PropTypes.number,
      required: PropTypes.bool,
      onInit: PropTypes.func,
      onChange: PropTypes.func,
      value: PropTypes.string,
      duration: PropTypes.number.isRequired,
      custom: PropTypes.objectOf(
        PropTypes.shape({
          name: PropTypes.string,
          text: PropTypes.string
        })
      )
    };
  }

  static get defaultProps() {
    return {
      valid: true,
      placeholder: null,
      autoFocus: true,
      minLength: null,
      maxLength: null,
      required: false,
      rows: 7,
      onInit: noop,
      onChange: noop,
      value: "",
      custom: null
    };
  }

  componentWillMount() {
    // Pass state and answer to parent (Question) component right before being appended to DOM
    this.props.onInit(
      this.props.name,
      this.props.type,
      {
        custom: this.props.custom
      },
      { value: this.props.value },
      this.props.duration
    );
  }

  handleKeyPress(e) {
    if (e.charCode === 13 && e.ctrlKey) {
      // ctrl + Enter
      this.handleSubmit(e);
    }
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.textAreaCounterWrapper}>
          <textarea
            name={this.props.name}
            type={this.props.type}
            value={this.props.value}
            rows={this.props.rows}
            cols={this.props.cols}
            autoFocus={this.props.autoFocus}
            placeholder={this.props.placeholder}
            onKeyPress={this.handleKeyPress}
            onChange={this.handleChange}
            onBlur={this.handleSubmit}
            className={combineClassNames({
              [styles.textarea]: true,
              [styles.invalid]: !this.props.valid
            })}
          />
          {this.props.maxLength ? (
            <div
              className={combineClassNames({
                [styles.charCounter]: true,
                [styles.invalid]: this.props.valid === false // TODO: apply invalid style only when maxLength constrain fails validation
              })}
            >
              {this.props.value.length} / {this.props.maxLength}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default TextArea;
