import React from "react";

import { withStyles } from "@material-ui/core/styles";

import GridRow from "./GridRow";

const styles = () => ({
  header: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: "#ffffff"
  }
});

const GridHeader = ({ classes, children }) => (
  <GridRow className={classes.header}>{children}</GridRow>
);

export default withStyles(styles)(GridHeader);
