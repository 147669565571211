import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import ReactMarkdown from "react-markdown";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import isEmpty from "lodash/isEmpty";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  appBar: {
    top: "100px",
    minHeight: "100px",
    backgroundColor: "#ffffff",
    boxShadow: "none",
    borderBottom: "1px solid #dce3ee",
    zIndex: theme.zIndex.title,
    [theme.breakpoints.down("sm")]: {
      top: "75px",
      paddingLeft: "20px",
      paddingRight: "20px"
    },
    "@media (max-width: 320px)": {
      top: "65px",
      height: "auto"
    }
  },
  toolbar: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    paddingLeft: "350px",
    paddingRight: "400px",
    "@media only screen and (max-width: 1280px) and (orientation: landscape)": {
      paddingLeft: 100,
      paddingRight: 100
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "100px",
      paddingRight: "100px"
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "12px",
      paddingRight: "12px"
    }
  },
  title: {
    fontSize: "20px",
    display: "flex",
    alignItems: "end",
    fontWeight: "bold",
    color: theme.palette.questionnaire.textPrimary,
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      paddingTop: "12px",
      lineHeight: "normal"
    },
    "@media (max-width: 320px)": {
      fontSize: 13
    },
    "& p": {
      margin: 0
    },
    "& strong": {
      fontWeight: 900
    }
  },
  titleWithError: {
    color: theme.palette.questionnaire.textRequired
  },
  markdownWrapper: {
    display: "flex",
    flexDirection: "column"
  },
  description: {
    fontFamily: theme.typography.fontFamily,
    marginTop: "5px",
    display: "block",
    color: theme.palette.questionnaire.textSecondary,
    fontSize: "16px",
    paddingBottom: 4,
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      lineHeight: "normal"
    },
    "@media (max-width: 320px)": {
      fontSize: 9
    },
    "& p": {
      margin: 0,
      fontSize: "inherit"
    }
  },
  required: {
    marginLeft: theme.spacing.unit,
    fontSize: "20px",
    color: theme.palette.questionnaire.textRequired,
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      lineHeight: "normal"
    }
  },
  error: {
    color: theme.palette.questionnaire.textRequired,
    fontFamily: theme.typography.fontFamily,
    fontStyle: "italic",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      lineHeight: "normal"
    }
  }
});

const QuestionTitle = ({
  classes,
  title,
  isSurveyDraft,
  questionId,
  description,
  required,
  float,
  error
}) => (
  <AppBar
    position={float ? "static" : "fixed"}
    className={classes.appBar}
    color="default"
  >
    <Toolbar className={classes.toolbar}>
      <Typography
        variant="h6"
        className={cn({
          [classes.title]: true,
          [classes.titleWithError]: !isEmpty(error)
        })}
      >
        <div className={classes.markdownWrapper}>
          <ReactMarkdown
            source={isSurveyDraft ? `${questionId}: ${title}` : title}
            escapeHtml
          />
        </div>
        {required ? <span className={classes.required}>*</span> : ""}
      </Typography>
      {!isEmpty(description) && (
        <ReactMarkdown
          className={classes.description}
          source={description}
          escapeHtml
        />
      )}
      {!isEmpty(error) && (
        <span className={classes.error}>{I18n.t(error)}</span>
      )}
    </Toolbar>
  </AppBar>
);

QuestionTitle.propTypes = {
  classes: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  isSurveyDraft: PropTypes.bool,
  questionId: PropTypes.string
};

QuestionTitle.defaultProps = {
  description: "",
  error: "",
  questionId: "",
  required: false,
  isSurveyDraft: false
};

const mapStateToProps = state => ({
  isSurveyDraft: state.survey.status === "draft",
  questionId: state.cursor.path[state.cursor.index]
});

export default connect(mapStateToProps)(withStyles(styles)(QuestionTitle));
