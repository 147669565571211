import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  group: {
    // height: "4em",
    textAlign: "right",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    border: "2px solid transparent",
    "&:hover": {
      cursor: "default !important",
      backgroundColor: "transparent !important",
      borderColor: "transparent !important"
    }
  }
});

const GridGroup = ({ classes, children }) => (
  <div className={classes.group}>{children}</div>
);

export default withStyles(styles)(GridGroup);
