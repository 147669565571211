import assign from 'lodash/assign';
import flow from 'lodash/flow';
import pickBy from 'lodash/pickBy';
import keys from 'lodash/keys';
import curry from 'lodash/curry';
import join from 'lodash/join';
import partialRight from 'lodash/partialRight';

const combineClassNames = flow([
  curry(assign, {}),
  partialRight(pickBy, (e) => e === true),
  keys,
  partialRight(join, ' '),
]);

export default combineClassNames;
