import get from "lodash/get";

export const isLastQuestion = state => {
  const curIndex = get(state, ["cursor", "index"]);
  const pathLength = get(state, ["cursor", "path", "length"]);
  return Number(curIndex) === Number(pathLength) - 1;
};

export const getCompletedPct = state =>
  Math.round(100 * (state.cursor.index / (state.cursor.path.length - 1)));

export default {
  isLastQuestion,
  getCompletedPct
};
