import palette from "./palette";
import typography from "./typography";

export default {
  typography,
  palette,
  zIndex: {
    question: 8,
    navigation: 9,
    title: 11,
    footer: 11
  }
};
