import { ASSIGN_ANSWER } from "../types";

export default function(key, questionType, value, duration = 0) {
  return {
    key,
    questionType,
    value,
    duration,
    type: ASSIGN_ANSWER
  };
}
