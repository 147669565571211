import find from "lodash/find";

import { QUESTION_NOT_FOUND, QUESTION_REQUIRED } from "../userErrors";

export default function(userErrors, question) {
  if (find(userErrors, { category: QUESTION_NOT_FOUND, question })) {
    return "userError.question-not-found";
  }
  if (find(userErrors, { category: QUESTION_REQUIRED, question })) {
    return "userError.question-required";
  }

  return "";
}
