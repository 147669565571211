import jsonLogic from 'json-logic-js';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';

/*
 * Given a question and answer, find out whether that question has been answered or not.
 *
 * No validation performed other than checking if a valid value is used in answer. Proper validation should be made server side.
 *
 * @param {Object} question       : question object
 * @param {String} answer         : answer object from payload
 *
 * @return {Boolean}
 */
export function isQuestionAnswered(question, answer, context) {
  if (!answer) {
    return false;
  }

  switch (question.type) {
    case 'READONLY':
      return answer.value === null;  // Special case: read-only questions always have null answers

    case 'RADIO_LIST':
    case 'SEARCHABLE_LIST':
    case 'TEXT':
    case 'TEXTAREA':
      return answer.value;

    case 'CHECKBOX_LIST':
    case 'RATING_LIST':
      // return some(context.options, (option) => answer[option.name] && answer[option.name].value === option.value);
      return some(context.options, (option) => answer[option.name] && answer[option.name].value);

    case 'CHECKBOX_GRID':
    case 'RATING_GRID':
      return some(
        context.groups,
          (group) => answer[group.name] &&
            some(context.options,
              (option) =>
                // answer[group.name].value[option.name] && answer[group.name].value[option.name].value === option.value
                answer[group.name].value[option.name] && answer[group.name].value[option.name].value
            )
      );

    case 'RADIO_GRID':
      return some(context.groups,
        (group) => answer[group.name] &&
          some(context.options,
            // (option) => answer[group.name].value === option.value
            () => answer[group.name].value
          )
      );

    default:
      throw Error(`Unknown question type: ${question.type}`);
  }
}

export function isQuestionRequiredAndNotAnswered(question, answer, context) {
  // If required is an object then we apply json logic to it, otherwise we apply the
  // default 'required' behaviour: at least one option must be selected to be considered 'answered'
  return typeof (question.required) === 'object' ?
    (isEmpty(answer) ? true : !jsonLogic.apply(question.required, answer)) :
    question.required && !isQuestionAnswered(question, answer, context);
}
