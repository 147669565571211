import { API } from "aws-amplify";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import omitBy from "lodash/omitBy";
import pick from "lodash/pick";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import isFunction from "lodash/isFunction";

import unmarkDirtyQuestionBatch from "./unmarkDirtyQuestionBatch";
import setAppStatus from "./setAppStatus";
import {
  PAYLOAD_STATUS,
  STATUS_ERROR,
  STATUS_PENDING,
  STATUS_OK
} from "../status";

import { Logger } from "../../utils/logger";

//eslint-disable-next-line no-unused-vars
const logger = new Logger("actions/pushResponses");

/**
 * Push response to server
 *
 * @param {String} options.force: force push of response (default: false)
 * @param {String} options.verbose: add text string when dispatching status (default: false)
 * @param {Function} onSuccess: callback to be invoked on success
 * @param {Function} onError: callback to be invoked on error
 */
export default function(options = {}, onSuccess, onError) {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      dirtyQuestions,
      questions,
      responseId,
      i18n,
      payload,
      survey,
      version,
      user: { userId, jwtToken }
    } = state;

    if (isNull(responseId) || isUndefined(responseId) || isEmpty(responseId)) {
      return true; // Exit
    }

    /**
     * The null user status means that the user hasn't decided whether
     * wants to continue as anonymous or to login. The system should not
     * create responses when the user is in this state.
     */
    // logger.debug("Attempt to push a response");

    // Optimise network usage unless forced to push response
    if (!get(options, "force", false)) {
      // logger.debug("check if dirty questions exist");
      // check if dirty questions exist
      if (isEmpty(dirtyQuestions) || isEmpty(questions)) return true;
    }

    const pendingText = get(options, "verbose", false)
      ? "status.response-upload-pending"
      : "";
    dispatch(setAppStatus(PAYLOAD_STATUS, STATUS_PENDING, pendingText));

    const surveyId = get(survey, "_id", undefined);

    // format answer object
    const formatAnswer = source => pick(payload.answer, source);
    // format context object
    const formatContext = source => pick(payload.context, source);

    const body = {
      locale: i18n.locale,
      payload: omitBy(
        {
          answer: omitBy(formatAnswer(dirtyQuestions), isEmpty),
          context: omitBy(formatContext(dirtyQuestions), isEmpty),
          current: get(payload, "current", null)
        },
        isEmpty
      )
    };
    const authHeader =
      isEmpty(jwtToken) && version.auth === false
        ? {}
        : { Authorization: `Bearer ${jwtToken}` };

    const headers = { "Content-Type": "application/json", ...authHeader };

    // Params to be sent to the server
    const params = {
      body,
      headers
    };

    // logger.debug("PATCHing response", responseId);

    const basicURI = `surveys/${surveyId}/responses/${responseId}`;
    const uri =
      !isEmpty(userId) && version.auth === true
        ? `/users/${userId}/${basicURI}`
        : `/${basicURI}`;
    try {
      await API.patch("surveys", uri, params);
      dispatch(unmarkDirtyQuestionBatch(dirtyQuestions));
      dispatch(setAppStatus(PAYLOAD_STATUS, STATUS_OK));
      if (isFunction(onSuccess)) onSuccess();
    } catch (error) {
      const errorText = get(options, "verbose", false)
        ? "status.response-update-error"
        : "";
      dispatch(setAppStatus(PAYLOAD_STATUS, STATUS_ERROR, errorText));
      if (isFunction(onError)) onError();
    }
  };
}
