import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
  root: {
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    zIndex: "2000",
    position: "fixed",
    touchAction: "none",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  loader: {
    zIndex: "2000"
  }
});

const FullPageTransparentLoader = ({ classes }) => (
  <div className={classes.root}>
    <CircularProgress className={classes.loader} />
  </div>
);

export default withStyles(styles)(FullPageTransparentLoader);
