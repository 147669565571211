import React from "react";
import cn from "classnames";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  group: {
    paddingLeft: "16px",
    paddingRight: "12px",
    fontSize: "18px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      paddingLeft: "20px",
      paddingRight: "20px"
    },
    "@media (max-width: 320px)": {
      fontSize: "13px"
    }
  }
});

const Group = ({ classes, className = "", children }) => (
  <div className={cn(classes.group, className)}>{children}</div>
);

export default withStyles(styles)(Group);
