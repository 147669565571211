import "babel-polyfill";

import React from "react";
import ReactDOM from "react-dom";

import Amplify from "aws-amplify";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import {
  loadTranslations,
  syncTranslationWithStore,
  setLocale
} from "react-redux-i18n";

// import isEmpty from "lodash/isEmpty";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import { AuthState, AuthReducer } from "@slashdata/authenticator";

import theme from "./components/Theme";

import App from "./app";
import rootReducer from "./state/rootReducer";
// import pushResponse from "./state/actions/pushResponse";
import resetAppStatus from "./state/actions/resetAppStatus";
// import { PAYLOAD_STATUS, STATUS_OK } from "./state/status";
import setupJsonLogic from "./utils/jsonLogic";
import translationsObject from "./lang";
import config from "./config";

// const {
//   REACT_APP_AUTH_REGION,
//   REACT_APP_AUTH_USER_POOL_ID,
//   REACT_APP_AUTH_USER_POOL_CLIENT_ID,
//   REACT_APP_AUTH_CUSTOM_DOMAIN,
//   REACT_APP_AUTH_USE_SSL,
//   REACT_APP_SERVER_API_URL,
//   REACT_APP_USER_API_URL
// } = process.env;

// const config = {
//   Auth: {
//     region: REACT_APP_AUTH_REGION,
//     userPoolId: REACT_APP_AUTH_USER_POOL_ID,
//     userPoolWebClientId: REACT_APP_AUTH_USER_POOL_CLIENT_ID,
//     mandatorySignIn: true,
//     cookieStorage: isEmpty(REACT_APP_AUTH_CUSTOM_DOMAIN)
//       ? {}
//       : {
//           domain: REACT_APP_AUTH_CUSTOM_DOMAIN,
//           path: "/",
//           expires: 365,
//           secure: REACT_APP_AUTH_USE_SSL === "true" // must be Boolean!
//         }
//   },
//   API: {
//     endpoints: [
//       {
//         name: "surveys",
//         endpoint: REACT_APP_SERVER_API_URL
//       },
//       {
//         name: "users",
//         endpoint: REACT_APP_USER_API_URL
//       }
//     ]
//   }
// };

Amplify.configure(config);

// setup custom json-logic operations
setupJsonLogic();

const coreMiddlewares = [thunk];
const featureMiddlewares = [];

const middleware = [...featureMiddlewares, ...coreMiddlewares];

const enhancers = [];

// enable redux-logger in development
if (process.env.NODE_ENV === "development") {
  const { createLogger } = require("redux-logger"); // eslint-disable-line global-require
  middleware.push(createLogger());
}

// enable devToolsExtension in development
if (process.env.NODE_ENV === "development") {
  const { devToolsExtension } = window;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

// load state from localStorage
// const persistedState = loadState();

// compose middlewares and enhancers
const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

// create redux store
const store = createStore(rootReducer, {}, composedEnhancers);

// // push response to server
// store.subscribe(
//   throttle(() => {
//     store.dispatch(pushResponse(store.getState()));
//   }, 20000)
// ); // 20 secs

// reset app status
store.dispatch(resetAppStatus());

// window.addEventListener("beforeunload", event => {
//   const { status } = store.getState();
//   const value = get(status, [PAYLOAD_STATUS, "value"], "");
//   const message = value !== STATUS_OK ? "onBeforeUnload" : null;

//   if (message) {
//     event.returnValue = message;

//     store.dispatch(pushResponse(store.getState()));
//   }

//   return message;
// });

// load translation and set locale
syncTranslationWithStore(store);
store.dispatch(loadTranslations(translationsObject));
store.dispatch(setLocale("en"));

// Setup router
// createRouter(store, history);

// render app
ReactDOM.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <Provider store={store}>
    <MuiThemeProvider theme={createMuiTheme(theme)}>
      <AuthState.AuthStateProvider
        initialState={AuthReducer.initialState}
        reducer={AuthReducer.reducer}
        config={config}
      >
        <App />
      </AuthState.AuthStateProvider>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("root")
);
