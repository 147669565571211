import React from "react";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      flexDirection: "column"
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "100px",
      paddingRight: "100px"
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "350px",
      paddingRight: "400px"
    }
  }
});

const ControlWrapper = ({ classes, children }) => (
  <div className={classes.root}>{children}</div>
);

export default withStyles(styles)(ControlWrapper);
