import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

import { getCompletedPct } from "../../state/selectors/questionnaire";

const styles = theme => ({
  container: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "12px",
      display: "flex",
      flexDirection: "column-reverse",
      width: "calc(100% - 150px)"
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "100px",
      paddingRight: "100px"
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "350px",
      paddingRight: "400px"
    }
  },
  root: {
    display: "inline-block",
    height: "10px",
    width: "calc(100% - 57px)"
  },
  colorPrimary: {
    backgroundColor: theme.palette.questionnaire.stripe
  },
  percentage: {
    width: "57px",
    display: "inline-block",
    fontSize: "20px",
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
      textAlign: "left"
    },
    "@media (max-width: 320px)": {
      fontSize: 12.5
    }
  }
});

const ProgressBar = ({ pct, classes }) => (
  <div className={classes.container}>
    <LinearProgress
      color="primary"
      variant="determinate"
      value={pct}
      classes={{ root: classes.root, colorPrimary: classes.colorPrimary }}
    />
    <Typography color="primary" className={classes.percentage} align="center">
      {pct}%
    </Typography>
  </div>
);

ProgressBar.propTypes = {
  classes: PropTypes.shape().isRequired,
  pct: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  pct: getCompletedPct(state)
});

export default connect(mapStateToProps)(withStyles(styles)(ProgressBar));
