import React, { useEffect } from "react";

import { Helmet } from "react-helmet";

import get from "lodash/get";

import { initializeGA, handleGACookies } from "../../utils/analytics";

const CookieBot = () => {
  useEffect(() => {
    const initServicesWithCookies = () => {
      if (get(window, ["Cookiebot", "consent", "statistics"], false)) {
        initializeGA();
      }
    };

    const removeCookies = () => {
      handleGACookies();
    };

    window.addEventListener("CookiebotOnAccept", initServicesWithCookies);
    window.addEventListener("CookiebotOnDecline", removeCookies);

    return function cleanup() {
      window.removeEventListener("CookiebotOnAccept", initServicesWithCookies);
      window.removeEventListener("CookiebotOnDecline", removeCookies);
    };
  });

  return (
    <Helmet
      title="Survey tool"
      meta={[
        { name: "description", content: "Developer Economics" },
        { name: "keywords", content: "developer, economics" }
      ]}
      script={[
        {
          id: "Cookiebot",
          src: "https://consent.cookiebot.com/uc.js",
          "data-cbid": process.env.REACT_APP_COOKIEBOT_ID,
          type: "text/javascript",
          async: true
        }
      ]}
    />
  );
};

export default CookieBot;
