import React from "react";

import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  title: {
    fontFamily: "'Paytone One', sans- serif",
    textAlign: "left"
  }
});

const Heading = ({ level, children, classes }) => (
  <Typography variant={`h${level}`} color="primary" className={classes.title}>
    {children}
  </Typography>
);

export default withStyles(styles)(Heading);
