import get from "lodash/get";

import {
  SET_USER_PROFILE,
  SIGN_OUT,
  CHANGE_AUTHENTICATOR_VIEW
} from "../types";

export const initialState = {
  userId: "",
  email: "",
  groups: [],
  jwtToken: "",
  authenticatorView: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_PROFILE:
      return {
        ...state,
        ...get(action, "payload", {})
      };
    case SIGN_OUT:
      return {
        ...state,
        ...initialState
      };
    case CHANGE_AUTHENTICATOR_VIEW:
      return {
        ...state,
        authenticatorView: get(action, "payload")
      };
    default:
      return state;
  }
};

export default reducer;
