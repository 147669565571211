import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import noop from "lodash/noop";

const styles = theme => ({
  root: {
    color: theme.palette.questionnaire.selection,
    "& .checked": {
      color: theme.palette.questionnaire.checked
    }
  }
});

const CheckboxField = ({
  classes,
  value,
  name,
  checked,
  disabled,
  onChange
}) => (
  <Checkbox
    name={name}
    checked={checked}
    onChange={onChange}
    value={value}
    disabled={disabled}
    classes={{ root: classes.root }}
  />
);

// /**
//  * A checkbox component to be used at checkbox question types
//  */
// class Checkbox extends Component {
//   render() {
//     return (
//       <label>
//         <input
//           className={styles.input}
//           type="checkbox"
//           name={this.props.name}
//           value={this.props.value}
//           checked={this.props.checked}
//           disabled={this.props.disabled}
//           onChange={this.props.onChange}
//         />
//         <span className={styles.check} />
//       </label>
//     );
//   }
// }

CheckboxField.propTypes = {
  classes: PropTypes.shape().isRequired,
  // The name of the checkbox
  name: PropTypes.string,
  // The value of the checkbox
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // Whether is checked
  checked: PropTypes.bool,
  // Whether is disabled
  disabled: PropTypes.bool,
  // Callback to be invoked on change
  onChange: PropTypes.func
};

CheckboxField.defaultProps = {
  name: null,
  value: null,
  checked: false,
  disabled: false,
  onChange: noop
};

// export default Checkbox;
export default withStyles(styles)(CheckboxField);
