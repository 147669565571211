import React from "react";

import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  text: {
    fontFamily: "Roboto",
    color: "#2b2a28",
    fontSize: "14px",
    fontWeight: 300,
    textAlign: "left",
    margin: "15px 0",
    lineHeight: "normal"
  }
});

const Paragraph = ({ level, children, classes }) => (
  <Typography color="primary" className={classes.text}>
    {children}
  </Typography>
);

export default withStyles(styles)(Paragraph);
