import React, { Component } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import ReactMarkdown from "react-markdown";

import Image from "./Markdown/renderers/Image";
import imageUrlTransformer from "./Markdown/transformers/image";
import styles from "./ReadOnly.module.css";

class ReadOnly extends Component {
  componentWillMount() {
    // Pass state and answer to parent (Question) component right before being appended to DOM
    this.props.onInit(
      this.props.name,
      this.props.type,
      {
        custom: this.props.custom
      },
      { value: null },
      this.props.duration
    );
  }

  render() {
    return (
      <ReactMarkdown
        className={styles.markdown}
        renderers={{ image: Image }}
        source={this.props.content}
        escapeHtml={
          false // The content must be joined with double line character to have an appropriate markdown markup. Single new line does not work for multiple images.
        }
        transformImageUri={imageUrlTransformer(this.props.imagesHost)}
        linkTarget="_blank"
      />
    );
  }
}

ReadOnly.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  onInit: PropTypes.func,
  duration: PropTypes.number,
  imagesHost: PropTypes.string,
  custom: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
      text: PropTypes.string
    })
  )
};

ReadOnly.defaultProps = {
  onInit: noop,
  imagesHost: "",
  duration: 0, // Provide default value as this component doesn't have onChange handler
  custom: null
};

export default ReadOnly;
