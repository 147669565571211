import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { setLocale } from "react-redux-i18n";

import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    width: 70,
    height: 30,
    color: theme.palette.primary.main,
    marginLeft: 10,
    [theme.breakpoints.down("sm")]: {
      width: 45,
      height: 33
    },
    "@media (max-width: 320px)": {
      width: 35,
      height: 25
    }
  },
  notchedOutline: {
    borderColor: `${theme.palette.primary.main} !important`,
    [theme.breakpoints.down("sm")]: {
      borderWidth: "2px",
      borderRadius: "15.7px",
      paddingLeft: "0px !important"
    },
    "@media (max-width: 320px)": {
      borderRadius: 12.1
    }
  },
  input: {
    backgroundColor: "transparent",
    "&:focus": {
      backgroundColor: "transparent"
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: "15.7px"
    }
  },
  select: {
    borderColor: "red",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0px !important",
      padding: 0,
      textAlign: "center !important"
    }
  },
  icon: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
});

const Languages = ({ classes, isLocalized, languages, locale, onChange }) => {
  if (!isLocalized || isEmpty(languages)) return null;

  return (
    <Select
      value={locale}
      onChange={({ target: { value } }) => onChange(value)}
      classes={{
        icon: classes.icon,
        select: classes.select
      }}
      input={
        <OutlinedInput
          notched={false}
          name="locale"
          labelWidth={70}
          classes={{
            root: classes.root,
            input: classes.input,
            notchedOutline: classes.notchedOutline
          }}
        />
      }
    >
      {map(languages, option => (
        <MenuItem key={option.name} value={option.name}>
          {option.name.toUpperCase()}
        </MenuItem>
      ))}
    </Select>
  );
};

Languages.propTypes = {
  classes: PropTypes.shape().isRequired,
  isLocalized: PropTypes.bool.isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  locale: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const getAvailableLanguages = state =>
  filter(state.survey.locales, ({ status }) => status === "published");

const isLocalizedSurvey = state => {
  const languages = getAvailableLanguages(state);

  return languages.length > 1;
};

const mapStateToProps = state => ({
  languages: getAvailableLanguages(state),
  isLocalized: isLocalizedSurvey(state),
  locale: state.i18n.locale
});

const mapDispatchToProps = dispatch => ({
  onChange: locale => dispatch(setLocale(locale))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Languages));
