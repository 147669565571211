import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import Fab from "@material-ui/core/Fab";
import KeyboardArrowLeftOutlined from "@material-ui/icons/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlined from "@material-ui/icons/KeyboardArrowRightOutlined";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  button: {
    width: "58px",
    height: "57px",
    display: "flex",
    justifyContent: "center",
    boxShadow: "none",
    color: "white",
    [theme.breakpoints.down("md")]: {
      width: "61px",
      height: "61px"
    },
    "@media (max-width: 320px)": {
      width: "47px",
      height: "47px"
    }
  },
  label: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});

const NavigationButton = ({ classes, onClick, next, className }) => (
  <Fab
    color="primary"
    className={cn(classes.button, className)}
    onClick={onClick}
    classes={{ label: classes.label }}
  >
    {next ? <KeyboardArrowRightOutlined /> : <KeyboardArrowLeftOutlined />}
  </Fab>
);

NavigationButton.propTypes = {
  classes: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  next: PropTypes.bool
};

NavigationButton.defaultProps = {
  className: "",
  next: false
};

export default withStyles(styles)(NavigationButton);
