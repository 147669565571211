import { API } from "aws-amplify";
import isEmpty from "lodash/isEmpty";
import { IMPORT_SURVEY } from "../types";
import {
  SURVEY_STATUS,
  STATUS_PENDING,
  STATUS_OK,
  STATUS_ERROR
} from "../status";
import setAppStatus from "./setAppStatus";

const importSurvey = survey => ({
  type: IMPORT_SURVEY,
  survey
});

const pullSurvey = (slug = "") => async dispatch => {
  if (!isEmpty(slug)) {
    dispatch(setAppStatus(SURVEY_STATUS, STATUS_PENDING, ""));

    try {
      const surveyData = await API.get(
        "surveys",
        `/surveys/branches/name/${slug}`
      );
      if (isEmpty(surveyData)) {
        throw new Error(`No Survey running for the ${slug} branch`);
      }

      dispatch(importSurvey({ ...surveyData, branch: slug }));
      dispatch(setAppStatus(SURVEY_STATUS, STATUS_OK));
    } catch (error) {
      dispatch(setAppStatus(SURVEY_STATUS, STATUS_ERROR, error.message));
    }
  }
};

export default pullSurvey;
