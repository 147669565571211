import { SET_CURSOR_PREVIOUS } from "../types";

export default function(blocks, questions, payload) {
  return {
    blocks,
    questions,
    payload,
    type: SET_CURSOR_PREVIOUS
  };
}
