import React from "react";
import cn from "classnames";

import ReactMarkdown from "react-markdown";

import { withStyles } from "@material-ui/core/styles";

import Heading from "./heading";
import Paragraph from "./paragraph";

const styles = theme => ({
  message: {
    "& li": {
      fontFamily: "Roboto",
      color: "#2b2a28",
      fontSize: "14px",
      fontWeight: 300,
      textAlign: "left"
    }
  },
  linkContainer: { display: "inline-flex" },
  link: {
    fontWeight: "normal",
    color: theme.palette.primary.main
  },
  buttonContainer: { display: "flex", justifyContent: "center" },
  button: {
    padding: 20,
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    fontFamily: "Paytone One",
    textTransform: "uppercase",
    textDecoration: "none",
    borderRadius: 30
  }
});

const Markdown = ({ classes, content, className = "" }) => (
  <ReactMarkdown
    source={content}
    className={cn(classes.message, className)}
    escapeHtml={false}
    renderers={{
      paragraph: Paragraph,
      heading: Heading,
      link: ({ href, title, children }) => (
        <div
          className={cn(classes.linkContainer, {
            [classes.buttonContainer]: title === "button"
          })}
        >
          <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            className={cn(classes.link, {
              [classes.button]: title === "button"
            })}
          >
            {children}
          </a>
        </div>
      )
    }}
  />
);

export default withStyles(styles)(Markdown);
