import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";

import ProgressBar from "./ProgressBar";
import NavigationButton from "../NavigationButton";
import Copyright from "../Copyright";
import { navigatePrevious, navigateNext } from "../../state/actions/navigate";

const styles = theme => ({
  footer: {
    height: "90px",
    background: "#ffffff",
    boxShadow: "0px 4px 7px 0px rgba(0, 0, 0, 0.34)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "fixed",
    bottom: "0",
    left: "0",
    right: "0",
    zIndex: theme.zIndex.footer,
    [theme.breakpoints.down("sm")]: {
      height: "82px"
    },
    "@media (max-width: 320px)": {
      height: "65px"
    }
  },
  buttonContainer: {
    display: "none",
    "@media only screen and (max-width: 1280px) and (orientation: landscape)": {
      display: "inline-flex"
    },
    [theme.breakpoints.down("sm")]: {
      display: "inline-flex"
    }
  },
  button: {
    [theme.breakpoints.down("md")]: {
      marginLeft: "4px",
      "& + &": {
        marginRight: "4px"
      }
    }
  }
});

const Footer = ({ classes, onNext, onPrevious }) => (
  <footer className={classes.footer}>
    <ProgressBar />
    <div className={classes.buttonContainer}>
      <NavigationButton className={classes.button} onClick={onPrevious} />
      <NavigationButton className={classes.button} onClick={onNext} next />
    </div>
    <Hidden smDown>
      <Copyright />
    </Hidden>
  </footer>
);

Footer.propTypes = {
  classes: PropTypes.shape().isRequired,
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired
};

export { Footer as Component };

const mapDispatchToProps = dispatch => ({
  onNext: () => dispatch(navigateNext()),
  onPrevious: () => dispatch(navigatePrevious())
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(Footer));
