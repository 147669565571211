import { API } from "aws-amplify";
import isEmpty from "lodash/isEmpty";

export const createResponse = (
  surveyId,
  userId,
  locale,
  payload,
  metadata,
  jwtToken
) =>
  isEmpty(userId)
    ? API.post("surveys", `/surveys/${surveyId}/responses`, {
        body: {
          locale,
          payload,
          ...metadata
        }
      })
    : API.post("surveys", `/users/${userId}/surveys/${surveyId}/responses`, {
        body: {
          locale,
          payload,
          ...metadata
        },
        headers: { Authorization: `Bearer ${jwtToken}` }
      });

export const getPayload = (surveyId, userId, responseId, jwtToken) =>
  isEmpty(userId)
    ? API.get("surveys", `/surveys/${surveyId}/responses/${responseId}`)
    : API.get(
        "surveys",
        `/users/${userId}/surveys/${surveyId}/responses/${responseId}`,
        {
          headers: { Authorization: `Bearer ${jwtToken}` }
        }
      );

export const getUserResponses = (surveyId, userId, jwtToken) =>
  API.get("surveys", `/users/${userId}/surveys/${surveyId}/responses`, {
    headers: { Authorization: `Bearer ${jwtToken}` }
  });
