import startsWith from "lodash/startsWith";
import filter from "lodash/filter";
// import orderBy from "lodash/orderBy";
import take from "lodash/take";
// import reverse from "lodash/reverse";
import flatten from "lodash/flatten";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import includes from "lodash/includes";
import uniqBy from "lodash/uniqBy";

/**
 * Given a list of SEACRHABLE_LIST option objects, a query, and a limit,
 * returns an array of option.text strings that belong to objects whose text attribute
 * or at least one alias are prefixed with the given query.
 *
 * @return {Array.<Object>} options.list : an array of SEARCHABLE_LIST option objects.
 * @param {String} options.query         : the query to match
 * @param {String} options.limit         : limit results to this count
 *
 * @return {Array.<String>}
 */
export default function suggest(options) {
  if (isEmpty(options.query) || isEmpty(options.list)) {
    return [];
  }

  const query = options.query.toLowerCase().trim();

  const filteredStartsWith = filter(options.list, item =>
    find(flatten([item.text, item.aliases || []]), str =>
      startsWith(str.toLowerCase(), query)
    )
  );

  const filteredContains = filter(options.list, item =>
    find(flatten([item.text, item.aliases || []]), str =>
      includes(str.toLowerCase(), query)
    )
  );

  const filtered = options.limit
    ? take(
        uniqBy([...filteredStartsWith, ...filteredContains], "value"),
        options.limit
      )
    : uniqBy([...filteredStartsWith, ...filteredContains], "value");

  // if the query is not empty but no matches could be found return the entire list
  if (query && filtered.length === 0) {
    return options.list.map(item => item.text);
  }

  const results = filtered.map(option => option.text);

  return results;
}
