import concat from "lodash/concat";
import clone from "lodash/clone";
import forEach from "lodash/forEach";
import indexOf from "lodash/indexOf";
import pullAt from "lodash/pullAt";

import {
  ASSIGN_ANSWER,
  ASSIGN_CONTEXT,
  UNMARK_DIRTY_QUESTION_BATCH,
  RESET
} from "../types";

function handleUnmarkDirtyQuestionBatch(state, dirty) {
  // Clone state as we will be mutating it
  const copy = clone(state);

  // For each dirty question, remove its first occurrence from the copy of the state
  forEach(dirty, v => {
    const index = indexOf(copy, v);
    pullAt(copy, [index]);
  });

  return copy;
}

export default function(state = [], action) {
  switch (action.type) {
    case ASSIGN_ANSWER:
    case ASSIGN_CONTEXT:
      return concat(state, action.key);

    // case MARK_DIRTY_QUESTION:
    //   if (includes(state, action.question)) {
    //     return state; // question already marked as dirty - avoid duplicates
    //   }

    //   return concat(state, action.question);

    case UNMARK_DIRTY_QUESTION_BATCH:
      return handleUnmarkDirtyQuestionBatch(state, action.questions);

    case RESET:
      return [];

    default:
      return state;
  }
}
