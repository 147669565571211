import jsonLogic from 'json-logic-js';
import extend from 'lodash/extend';
import filter from 'lodash/filter';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import has from 'lodash/has';
import includes from 'lodash/includes';
// import isUndefined from 'lodash/isUndefined';
import keys from 'lodash/keys';
import last from 'lodash/last';
import map from 'lodash/map';
// import omitBy from 'lodash/omitBy';
// import pickBy from 'lodash/pickBy';
import shuffle from 'lodash/shuffle';
import split from 'lodash/split';
import template from 'lodash/template';

/**
 * Returns an interpolated text based on current context
 * @param {*} field
 * @param {*} context
 */
export function extendTextField(field, context) {
  const compiled = template(field);

  // Interpolate text, and in case of error return original text
  try {
    return compiled(context);
  } catch(error) {
    console.error(error);
    return field;
  }
}

/**
 * Return question group array (TBD)
 * @param {*} groups
 */
export function loadGroups(groups) {
/*
    question.groups = map(initialState.groups, (v) => {
      const source = v.source ? v.source : question.name;
      const path = v.path ? v.path : 'groups';
      const group = find(get(find(state.questions, { name: source }), [path]), { name: v.name });

//      return omitBy({ ...group, source: v.source, path: v.path }, isUndefined);
      return group;
    });
*/
  return groups;
}

/**
 * Given a group definition, replace any import object with an appropriate
 * sequence of groups piped from the import source.
 *
 * @param  {Array.<Object>} questions: the questions spec (state.questions)
 * @param  {Object} group       : the group to pipe from
 * @param  {Object} answer      : the answer object containing current responses
 * @return {Array.<Object>}      : an array of piped group options
 */
export function extendGroup(questions, group, answer) {
  // Ignore non-piped groups or unanswered pipe targets
  // Return as a one-item array as these will be flatten

  if (!has(group, 'import')) {
    return [group];
  }

  if (!has(answer, get(group, 'import.from.name'))) {
    return [];
  }

  // TODO: replace by context to allow "chaining"
  const sourceQuestion = find(questions, (q) => q.name === group.import.from.name);

  // generate group items by applying json logic to each entry
  let choices = map(
    filter(sourceQuestion[group.import.from.path],
      (g) => jsonLogic.apply(
        group.import.filter || true, // default filter - pick all values
        answer[sourceQuestion.name].answer[g.name]
      )),
    (choice) => ({
      name: choice.name,
      text: choice.text,
      source: group.import.from.name,
      path: group.import.from.path
    })
  );

  // true if only specific groups should be piped
  const shouldIncludeOnly = has(group, 'import.includeOnly') && group.import.includeOnly.length > 0;

  // true if some groups should be excluded from piping
  const shouldExclude = has(group, 'import.exclude') && group.import.exclude.length > 0;

  if (shouldIncludeOnly) {
    choices = filter(choices, (choice) => includes(group.import.includeOnly, choice.name));
  } else if (shouldExclude) {
    choices = filter(choices, (choice) => !includes(group.import.exclude, choice.name));
  }

  return choices;
}

/**
 * Given a question, replaces any import objects in its groups array
 * with an appropriate sequence of groups piped from the import source.
 *
 * @param  {Array.<Object>} questions: the questions spec (state.questions)
 * @param  {Object} question    : the question to pipe groups from
 * @param  {Object} answer      : the answer object containing current responses
 * @return {Array.<Object>}      : an array of piped group options
 */
export function extendGroups(questions, question, answer) {
  return flatten(map(question.groups, (group) => {
    let choices = extendGroup(questions, group, answer);

    // always shuffle items
    choices = shuffle(choices);

    // true if a limit is declared and that limit is lower than the count of groups to pipe
    const shouldLimit = has(group, 'import.limit') && keys(choices).length > group.import.limit;

    if (shouldLimit) {
      choices = choices.splice(0, group.import.limit);
    }

    return choices;
  }));
}

/**
 * Return question option array (TBD)
 * @param {*} options
 */
export function loadOptions(options) {
/*
  question.options = map(initialState.options, (v) => {
      const source = v.source ? v.source : question.name;
      const path = v.path ? v.path : 'options';
      const option = find(get(find(state.questions, { name: source }), [path]), { name: v.name });

//      return omitBy({ ...option, source: v.source, path: v.path }, isUndefined);
      return option;
    });
*/
  return options;
}

/**
 * Given a group definition, replace any import object with an appropriate
 * sequence of groups piped from the import source.
 *
 * @param  {Array.<Object>} questions: the questions spec (state.questions)
 * @param  {Object} option      : the option to pipe from
 * @param  {Object} answer      : the answer object containing current responses
 * @param  {Object} context     : the context object
 * @return {Array.<Object>}      : an array of piped options
 */
export function extendOption(questions, option, answer, context) {
  // Ignore non-piped options or unanswered pipe targets
  // Return as a one-item array as these will be flatten
  if (!has(option, 'import') || !has(answer, get(option, 'import.from.name'))) {
    return [option];
  }

  // Retrieve source question object from where we are piping from
  const sourceQuestion = find(questions, (q) => q.name === option.import.from.name);
  // Retrieve items from source question context, as these might have been piped from another question
  const options = context[option.import.from.name][option.import.from.path];

  let choices = []; // options chosen by the user

  // generate option items by applying json logic to each entry
  switch (sourceQuestion.type) {
    /*
    case 'SEARCHABLE_LIST': // TODO: add jsonLogic support if needed
      choices = [
        pickBy(
          find(sourceQuestion.options, (o) => o.text === answer[sourceQuestion.name].value),
          (value, key) => includes(['name', 'text', 'value'], key)
        )
      ];
      break;
    */
    case 'CHECKBOX_LIST':
    case 'RATING_LIST':
      choices = map(
        filter(options, (o) =>
          jsonLogic.apply(
            option.import.filter || true, // default filter - pick all values
            answer[sourceQuestion.name].answer[o.name])),
        (o) => extend(o, { writeIn: answer[sourceQuestion.name].answer[o.name].writeIn || null })
      );
      break;

    case 'RADIO_GRID':
      choices = map(
        filter(options, (o) =>
          jsonLogic.apply(
            option.import.filter || true, // default filter - pick all values
            answer[sourceQuestion.name].answer[o.name])),
        (o) => extend(o, { value: last(split(o.name, '_')) }, { writeIn: answer[sourceQuestion.name].answer[o.name].writeIn || null })
      );
      // console.log(choices);
      break;

    // FIXME: needs json logic
    case 'RADIO_LIST':
      choices = map(
        filter(options, (o) =>
          o.value === answer[sourceQuestion.name].answer.value),
        (o) => extend(o, { writeIn: answer[sourceQuestion.name].answer.writeIn || null })
      );
      break;

    default:
      throw new Error(`Unsupported question type: ${sourceQuestion.type}`);
  }

  choices = map(choices, (choice) => ({
    name: choice.name,
    text: has(choice, 'writeIn') && choice.writeIn !== null
      ? choice.writeIn
      : choice.text,
    // value: `${targetQuestion.name}:${choice.value}`,
    value: choice.value,
    source: option.import.from.name,
    path: option.import.from.path
  }));

  // true if only specific options should be piped
  const shouldIncludeOnly = has(option.import, 'includeOnly') && option.import.includeOnly.length > 0;

  // true if some options should be excluded from piping
  const shouldExclude = has(option.import, 'exclude') && option.import.exclude.length > 0;

  if (shouldIncludeOnly) {
    choices = filter(choices, (choice) => includes(option.import.includeOnly, choice.name));
  } else if (shouldExclude) {
    choices = filter(choices, (choice) => !includes(option.import.exclude, choice.name));
  }

  return choices;
}

/**
 * Given a question, replaces any import objects in its options array
 * with an appropriate sequence of options piped from the import source.
 *
 * @param  {Array.<Object>} questions: the questions spec (state.questions)
 * @param  {Object} question    : the question to pipe options from
 * @param  {Object} answer      : the answer object containing current responses
 * @param  {Object} context     : the context object
 * @return {Array.<Object>}      : an array of piped options
 */
export function extendOptions(questions, question, answer, context) {
  return flatten(map(question.options, (option) => {
    let choices = extendOption(questions, option, answer, context);

    // always shuffle items
    choices = shuffle(choices);

    // true if a limit is declared and that limit is lower than the count of options to import
    const shouldLimit = has(option.import, 'limit') && keys(choices).length > option.import.limit;

    if (shouldLimit) {
      choices = choices.splice(0, option.import.limit);
    }

    return choices;
  }));
}
