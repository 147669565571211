import get from "lodash/get";
import {
  IMPORT_QUESTIONNAIRE,
  SET_RESPONSE_ID,
  RESET,
  SIGN_OUT
} from "../types";

export default function(state = null, action) {
  switch (action.type) {
    case IMPORT_QUESTIONNAIRE:
      return get(action, "responseId", null);

    case SET_RESPONSE_ID:
      return action.value;

    case SIGN_OUT:
    case RESET:
      return null;

    default:
      return state;
  }
}
