import React from "react";
import PropTypes from "prop-types";

import includes from "lodash/includes";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import theme from "../../../../components/Theme";
import { de, arvr, mulesoft, salesforce, dpl } from "../../../../components/Theme/palette";

/**
 * Define hard coded values for css.
 * Will be deleted once all question types migrated to material-ui
 */
const Theme = ({ slug, children }) => {
  const palette = includes(slug, "arvr") ? arvr
  : includes(slug, "dn") ? de
  : includes(slug, "mu_") ? mulesoft
  : includes(slug, "sf_") ? salesforce
  : includes(slug, "dpl") ? dpl
  : de;

  return (
    <MuiThemeProvider theme={createMuiTheme({ ...theme, palette })}>
      <div
        style={{
          "--text-color": "#2b2d42",
          "--color-error": "#e40000",
          "--color-text-gray": "#b0c0da",
          height: "100%",
          "line-height": "normal"
        }}
      >
        {children}
      </div>
    </MuiThemeProvider>
  );
};

Theme.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element]))
  ]).isRequired,
  slug: PropTypes.string.isRequired
};

export default Theme;
