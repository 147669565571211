/* eslint-disable import/no-dynamic-require */

import DEAccountIcon from "./de/app-bar-account.svg";
import DELoginIcon from "./de/app-bar-login.svg";
import DESaveIcon from "./de/app-bar-save.svg";
import DELogo from "./de/app-bar-logo.svg";
import DEBeforeYourStartAsset from "./de/before-you-start.svg";
import DEClosingAsset from "./de/closing-img.svg";
import DELoadingAsset from "./de/loading-img.png";

import ARVRAccountIcon from "./arvr/app-bar-account.svg";
import ARVRLoginIcon from "./arvr/app-bar-login.svg";
import ARVRSaveIcon from "./arvr/app-bar-save.svg";
import ARVRLogo from "./arvr/app-bar-logo.svg";
import ARVRBeforeYourStartAsset from "./arvr/before-you-start.svg";
import ARVRClosingAsset from "./arvr/closing-img.png";
import ARVRLoadingAsset from "./arvr/loading-img.svg";

import MulesoftAccountIcon from "./mulesoft/app-bar-account.svg";
import MulesoftLoginIcon from "./mulesoft/app-bar-login.svg";
import MulesoftSaveIcon from "./mulesoft/app-bar-save.svg";
import MulesoftLogo from "./mulesoft/app-bar-logo.svg";
import MulesoftBeforeYourStartAsset from "./mulesoft/before-you-start.svg";
import MulesoftClosingAsset from "./mulesoft/closing-img.svg";
import MulesoftLoadingAsset from "./mulesoft/loading-img.svg";

import SalesforceAccountIcon from "./salesforce/app-bar-account.svg";
import SalesforceLoginIcon from "./salesforce/app-bar-login.svg";
import SalesforceSaveIcon from "./salesforce/app-bar-save.svg";
import SalesforceLogo from "./salesforce/app-bar-logo.svg";
import SalesforceBeforeYourStartAsset from "./salesforce/before-you-start.svg";
import SalesforceClosingAsset from "./salesforce/closing-img.svg";
import SalesforceLoadingAsset from "./salesforce/loading-img.svg";

import DPLAccountIcon from "./dpl/app-bar-account.svg";
import DPLLoginIcon from "./dpl/app-bar-login.svg";
import DPLSaveIcon from "./dpl/app-bar-save.svg";
import DPLLogo from "./dpl/app-bar-logo.svg";
import DPLBeforeYourStartAsset from "./dpl/before-you-start.svg";
import DPLClosingAsset from "./dpl/closing-img.svg";
import DPLLoadingAsset from "./dpl/loading-img.svg";

const config = {
  de: {
    AccountIcon: DEAccountIcon,
    LoginIcon: DELoginIcon,
    SaveIcon: DESaveIcon,
    Logo: DELogo,
    BeforeYourStartAsset: DEBeforeYourStartAsset,
    ClosingAsset: DEClosingAsset,
    LoadingAsset: DELoadingAsset
  },
  arvr: {
    AccountIcon: ARVRAccountIcon,
    LoginIcon: ARVRLoginIcon,
    SaveIcon: ARVRSaveIcon,
    Logo: ARVRLogo,
    BeforeYourStartAsset: ARVRBeforeYourStartAsset,
    ClosingAsset: ARVRClosingAsset,
    LoadingAsset: ARVRLoadingAsset
  },
  mulesoft: {
    AccountIcon: MulesoftAccountIcon,
    LoginIcon: MulesoftLoginIcon,
    SaveIcon: MulesoftSaveIcon,
    Logo: MulesoftLogo,
    BeforeYourStartAsset: MulesoftBeforeYourStartAsset,
    ClosingAsset: MulesoftClosingAsset,
    LoadingAsset: MulesoftLoadingAsset
  },
  salesforce: {
    AccountIcon: SalesforceAccountIcon,
    LoginIcon: SalesforceLoginIcon,
    SaveIcon: SalesforceSaveIcon,
    Logo: SalesforceLogo,
    BeforeYourStartAsset: SalesforceBeforeYourStartAsset,
    ClosingAsset: SalesforceClosingAsset,
    LoadingAsset: SalesforceLoadingAsset
  },
  dpl: {
    AccountIcon: DPLAccountIcon,
    LoginIcon: DPLLoginIcon,
    SaveIcon: DPLSaveIcon,
    Logo: DPLLogo,
    BeforeYourStartAsset: DPLBeforeYourStartAsset,
    ClosingAsset: DPLClosingAsset,
    LoadingAsset: DPLLoadingAsset
  }
};

export default config;

// const themes = {
//   de: {
//     AccountIcon: "de/app-bar-account.svg",
//     LoginIcon: "de/app-bar-login.svg",
//     SaveIcon: "de/app-bar-save.svg",
//     Logo: "de/app-bar-logo.svg",
//     BeforeYourStartAsset: "de/before-you-start.svg",
//     ClosingAsset: "de/closing-img.svg",
//     LoadingAsset: "de/loading-img.png"
//   },
//   arvr: {
//     AccountIcon: "arvr/app-bar-account.svg",
//     LoginIcon: "arvr/app-bar-login.svg",
//     SaveIcon: "arvr/app-bar-save.svg",
//     Logo: "arvr/app-bar-logo.svg",
//     BeforeYourStartAsset: "arvr/before-you-start.svg",
//     ClosingAsset: "arvr/closing-img.png",
//     LoadingAsset: "arvr/loading-img.svg"
//   }
// };

// const config = themes[process.env.REACT_APP_THEME];

// const AccountIcon = require(`./${config.AccountIcon}`);
// const LoginIcon = require(`./${config.LoginIcon}`);
// const SaveIcon = require(`./${config.SaveIcon}`);

// const Logo = require(`./${config.Logo}`);

// const BeforeYourStartAsset = require(`./${config.BeforeYourStartAsset}`);
// const ClosingAsset = require(`./${config.ClosingAsset}`);
// const LoadingAsset = require(`./${config.LoadingAsset}`);

// export default {
//   AccountIcon,
//   LoginIcon,
//   SaveIcon,
//   Logo,
//   BeforeYourStartAsset,
//   ClosingAsset,
//   LoadingAsset
// };
