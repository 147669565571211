import React, { Component } from "react";
import PropTypes from "prop-types";
import assign from "lodash/assign";
import every from "lodash/every";
import filter from "lodash/filter";
import find from "lodash/find";
import flow from "lodash/flow";
import get from "lodash/get";
import isUndefined from 'lodash/isUndefined';
import keyBy from "lodash/keyBy";
import mapValues from "lodash/mapValues";
import noop from "lodash/noop";
import partial from "lodash/partial";
import partialRight from "lodash/partialRight";
import size from "lodash/size";
import some from "lodash/some";
import MediaQuery from "react-responsive";

import GridContainer from "../GridQuestion/GridContainer";
import GridHeader from "../GridQuestion/GridHeader";
import GridRow from "../GridQuestion/GridRow";
import GridGroup from "../GridQuestion/GridGroup";
import GridItem from "../GridQuestion/GridItem";
import Container from "../GridMobile/Container";
import Row from "../GridMobile/Row";
import Group from "../GridMobile/Group";
import Options from "../GridMobile/Options";
import Option from "../GridMobile/Option";
import Checkbox from "../Checkbox";
import Input from "../Input";
import Radio from "../Radio";
// import Toggle from '../Toggle';
import styles from "./RadioGrid.module.css";

import { Tooltip } from "@material-ui/core";

const countGroupsSelected = flow([
  partialRight(filter, group => some(group.options, option => option === true)),
  size
]);

/**
 * Component to handle RADIO_GRID question type
 */
class RadioGrid extends Component {
  constructor(props) {
    super(props);

    const groupsMap = keyBy(props.groups, "name");
    const optionsMap = keyBy(props.options, "name");

    /**
     * Example group object:
     *  {
     *    group_a: {
     *      options: {
     *        option_a: true,
     *        option_b: false,
     *        option_c: false
     *      },
     *      writeIn: 'foobar',
     *      showOptionsInMobile: true
     *    },
     *    group_b
     *  }
     */

    const groups = mapValues(groupsMap, group => {
      // extract group from props.value
      const obj = get(props.value, group.name, {
        value: null,
        writeIn: group.writeIn ? "" : undefined,
        source: group.source
      });

      return {
        options: mapValues(optionsMap, option => obj.value === option.value),
        writeIn: obj.writeIn,
        source: obj.source,
        showOptionsInMobile: true // obj.value !== null
      };
    });

    const disabled =
      countGroupsSelected(groups) === this.props.maxGroupsAllowed;

    this.state = { groups, disabled };
    this.handleChange = this.handleChange.bind(this);
    this.handleWriteIn = this.handleWriteIn.bind(this);
    this.toggleOptions = this.toggleOptions.bind(this);
  }

  // TODO: Use componentDidMount instead of componentWillMount
  componentWillMount() {
    // Pass state and answer to parent (Question) component right before being appended to DOM
    this.props.onInit(
      this.props.name,
      this.props.type,
      {
        //      groups: map(this.props.groups, (v) => omitBy({ name: v.name, source: v.source, path: v.path }, isUndefined)),
        //      options: map(this.props.options, (v) => omitBy({ name: v.name, source: v.source, path: v.path }, isUndefined))
        groups: this.props.groups,
        options: this.props.options,
        custom: this.props.custom
      },
      this.props.value,
      this.props.duration
    );
  }

  formatValue(groups) {
    return mapValues(groups, group => {
      const option = find(
        this.props.options,
        o => group.options[o.name] === true
      );

      return {
        value: get(option, "value", null),
        writeIn: group.writeIn,
        source: group.source
      };
    });
  }

  handleChange(group, option, e) {
    const checked = !this.state.groups[group.name].options[option.name];

    let groups = mapValues(this.state.groups, (groupObj, groupKey) => {
      // toggle current selection
      if (groupKey === group.name) {
        return assign({}, groupObj, {
          options: mapValues(
            groupObj.options,
            (optionValue, optionKey) => optionKey === option.name && checked
          )
        });
      }

      // handle unique options
      if (checked && option.unique && groupObj.options[option.name]) {
        return assign({}, groupObj, {
          options: assign({}, groupObj.options, {
            [option.name]: false
          })
        });
      }

      return groupObj;
    });

    groups = mapValues(groups, (groupObj) => {
      // Check if this group has any option(s) set
      const hasOptionSet = some(groupObj.options, (value) => value);

      // If group has a writeIn and no option set, reset writeIn content 
      if (!isUndefined(groupObj.writeIn) && !hasOptionSet) {
         groupObj.writeIn = "";
      }

      return groupObj;
    });

    const disabled =
      countGroupsSelected(groups) === this.props.maxGroupsAllowed;

    this.setState({ groups, disabled });
    this.props.onChange(e, this.formatValue(groups));
  }

  handleSelectAll(option, e) {
    // toggleAll is true if all groups' options are set
    const checked = every(this.state.groups, g => g.options[option]);

    // Reset all values and only set the value of the toggleAll option
    const groups = mapValues(this.state.groups, groupObj =>
      assign({}, groupObj, {
        options: mapValues(groupObj.options, (optionValue, optionKey) =>
          optionKey === option ? !checked : false
        )
      })
    );

    const disabled =
      countGroupsSelected(groups) === this.props.maxGroupsAllowed;

    this.setState({ groups, disabled });
    this.props.onChange(e, this.formatValue(groups));
  }

  handleWriteIn(group, e) {
    const groups = mapValues(this.state.groups, (groupObj, groupKey) => {
      if (groupKey === group.name) {
        return assign({}, groupObj, { writeIn: e.target.value });
      }

      return groupObj;
    });

    this.setState({ groups });
    this.props.onChange(e, this.formatValue(groups));
  }

  toggleOptions(group) {
    const checked = !this.state.groups[group.name].showOptionsInMobile;

    const groups = mapValues(this.state.groups, (groupObj, groupKey) => {
      if (checked) {
        if (groupKey === group.name) {
          return assign({}, groupObj, { showOptionsInMobile: true });
        }

        // close other groups without value
        return assign({}, groupObj, {
          showOptionsInMobile: some(groupObj.options, option => option === true)
        });
      }

      // unchecked
      if (groupKey === group.name) {
        // close + nullify open groups
        return assign({}, groupObj, {
          showOptionsInMobile: false,
          options: mapValues(groupObj.options, () => false)
        });
      }

      return groupObj; // return as is
    });

    const disabled =
      countGroupsSelected(groups) === this.props.maxGroupsAllowed;

    this.setState({ groups, disabled });
  }

  renderMobile() {
    return (
      <Container>
        {this.props.options.map(option => {
          if (!option.toggleAll) return null;

          return (
            <Option key={option.name}>
              <Checkbox
                name={option.name}
                value={option.value}
                checked={every(this.state.groups, g => g.options[option.name])}
                onChange={e => this.handleSelectAll(e.target.name, e)}
              />
              <span className={styles.optionText}>{option.toggleAll.text}</span>
            </Option>
          );
        })}
        {this.props.groups.map(group => {
          const currentGroup = this.state.groups[group.name];
          const isGroupAnswered = some(currentGroup.options, (value) => value);

          return (
          <Row key={group.name}>
            <Group>
              {group.writeIn ? (
                <Tooltip
                  arrow={true}
                  open={currentGroup.writeIn && !isGroupAnswered ? true : false}
                  title="Please select one option"
                  >
                  <Input
                    name={group.name}
                    placeholder={group.text}
                    /*disabled={!isGroupAnswered}*/
                    onChange={partial(this.handleWriteIn, group)}
                    value={currentGroup.writeIn}
                  />
                </Tooltip>
              ) : (
                group.text
              )}
              {/*
              <div>
                <Toggle
                  onChange={partial(this.toggleOptions, group)}
                  checked={this.state.groups[group.name].showOptionsInMobile}
                />
              </div>
              */}
            </Group>
            <Options
              hide={this.state.groups[group.name].showOptionsInMobile === false}
            >
              {this.props.options.map(option => (
                <Option key={`${group.name}_${option.name}`}>
                  <div>
                    <Radio
                      value={option.value}
                      checked={
                        this.state.groups[group.name].options[option.name]
                      }
                      disabled={
                        this.state.disabled &&
                        every(
                          this.state.groups[group.name].options,
                          v => v === false
                        )
                      }
                      onClick={partial(this.handleChange, group, option)}
                    />
                  </div>
                  <div className={styles.optionText}>{option.text}</div>
                </Option>
              ))}
            </Options>
          </Row>
        )})}
      </Container>
    );
  }

  renderIEMarkup() {
    return (
      <Container>
        {this.props.options.map(option => {
          if (!option.toggleAll) return null;

          return (
            <Option key={option.name} className={styles.ieOption}>
              <Checkbox
                name={option.name}
                value={option.value}
                checked={every(this.state.groups, g => g.options[option.name])}
                onChange={e => this.handleSelectAll(e.target.name, e)}
              />
              <span className={styles.optionText}>{option.toggleAll.text}</span>
            </Option>
          );
        })}
        {this.props.groups.map(group => {
          const currentGroup = this.state.groups[group.name];
          const isGroupAnswered = some(currentGroup.options, (value) => value);

          return (
          <Row key={group.name}>
            <Group className={styles.ieGroup}>
              {group.writeIn ? (
                <Tooltip
                  arrow={true}
                  open={currentGroup.writeIn && !isGroupAnswered ? true : false}
                  title="Please select one option"
                  >
                  <Input
                    name={group.name}
                    placeholder={group.text}
                    /*disabled={!isGroupAnswered}*/
                    onChange={partial(this.handleWriteIn, group)}
                    value={currentGroup.writeIn}
                  />
                </Tooltip>
              ) : (
                group.text
              )}
              {/*
              <div>
                <Toggle
                  onChange={partial(this.toggleOptions, group)}
                  checked={this.state.groups[group.name].showOptionsInMobile}
                />
              </div>
              */}
            </Group>
            <Options
              hide={this.state.groups[group.name].showOptionsInMobile === false}
            >
              {this.props.options.map(option => (
                <Option
                  key={`${group.name}_${option.name}`}
                  className={styles.ieOption}
                >
                  <div>
                    <Radio
                      value={option.value}
                      checked={
                        this.state.groups[group.name].options[option.name]
                      }
                      disabled={
                        this.state.disabled &&
                        every(
                          this.state.groups[group.name].options,
                          v => v === false
                        )
                      }
                      onClick={partial(this.handleChange, group, option)}
                    />
                  </div>
                  <div className={styles.optionText}>{option.text}</div>
                </Option>
              ))}
            </Options>
          </Row>
        )})}
      </Container>
    );
  }

  renderDesktop() {
    return (
      <GridContainer optionColumns={this.props.options.length}>
        <GridHeader>
          <GridGroup />
          {this.props.options.map((option, columnIndex) => (
            <GridItem key={`option-${columnIndex}`}>
              {option.toggleAll ? (
                <label className={styles.toggleAllLabel}>
                  <Checkbox
                    name={option.name}
                    value={option.value}
                    checked={every(
                      this.state.groups,
                      g => g.options[option.name]
                    )}
                    onChange={e => this.handleSelectAll(e.target.name, e)}
                  />
                  <span className={styles.optionText}>{option.text}</span>
                </label>
              ) : (
                option.text
              )}
            </GridItem>
          ))}
        </GridHeader>

        {this.props.groups.map((group, groupIndex) => {
          const currentGroup = this.state.groups[group.name];
          const isGroupAnswered = some(currentGroup.options, (value) => value);

          return (
          <GridRow key={`group-${groupIndex}`}>
            <GridGroup>
              {group.writeIn ? (
                <Tooltip
                  arrow={true}
                  open={currentGroup.writeIn && !isGroupAnswered ? true : false}
                  title="Please select one option"
                  >
                  <Input
                    name={group.name}
                    placeholder={group.text}
                    /*disabled={!isGroupAnswered}*/
                    onChange={partial(this.handleWriteIn, group)}
                    value={this.state.groups[group.name].writeIn}
                  />
                </Tooltip>
              ) : (
                group.text
              )}
            </GridGroup>
            {this.props.options.map((option, optionIndex) => (
              <GridItem key={`option-${optionIndex}`}>
                <label key={option.name} className={styles.optionLabel}>
                  <Radio
                    value={option.value}
                    checked={get(
                      this.state.groups[group.name],
                      `options.${option.name}`
                    )}
                    disabled={
                      this.state.disabled &&
                      every(
                        this.state.groups[group.name].options,
                        v => v === false
                      )
                    }
                    onClick={partial(this.handleChange, group, option)}
                  />
                </label>
              </GridItem>
            ))}
          </GridRow>
        )})}
      </GridContainer>
    );
  }

  render() {
    const isIE = false || !!document.documentMode;

    if (isIE) {
      return <div>{this.renderIEMarkup()}</div>;
    }
    return (
      <MediaQuery maxWidth={599.95}>
        {matches => (matches ? this.renderMobile() : this.renderDesktop())}
      </MediaQuery>
    );
  }
}

RadioGrid.propTypes = {
  // The name of the question
  name: PropTypes.string.isRequired,
  // The type of the question (RADIO_GRID)
  type: PropTypes.string.isRequired,
  // The groups of the question
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      // The name of the group
      name: PropTypes.string.isRequired,
      // The text of the group to be displayed
      text: PropTypes.string.isRequired,
      // Whether the position of the group is fixed
      fixed: PropTypes.boolean,
      // Whether the group is editable
      writeIn: PropTypes.boolean,
      // TODO: Add description about the source
      source: PropTypes.string
    })
  ).isRequired,
  // The options of the group
  options: PropTypes.arrayOf(
    PropTypes.shape({
      // The name of the option
      name: PropTypes.string.isRequired,
      // The value of the option
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      // The text of the option to be displayed
      text: PropTypes.string.isRequired,
      // Whether option position is fixed
      fixed: PropTypes.boolean,
      // TODO: Investigate if same as exclusive
      unique: PropTypes.boolean,
      // TODO: Add description about the source
      source: PropTypes.string,
      // TODO: Add description about path
      // NOTE: The option.path doesn't get used internally
      path: PropTypes.string
    })
  ).isRequired,
  // The count of the groups that can be selected
  maxGroupsAllowed: PropTypes.number,
  // The answer of the user
  value: PropTypes.shape({
    // The actual answer of the user
    value: PropTypes.string
  }),
  // The duration the user spent on this question
  duration: PropTypes.number.isRequired,
  // TODO: Add description about custom
  custom: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
      text: PropTypes.string
    })
  ),
  // Callback to be invoked on mount
  onInit: PropTypes.func,
  // Callback to be invoked on change
  onChange: PropTypes.func
};

RadioGrid.defaultProps = {
  maxGroupsAllowed: null,
  value: {},
  custom: null,
  onInit: noop,
  onChange: noop
};

export default RadioGrid;
