import React from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";

import Screen from "./components/Screen";
import SurveyForm from "./components/SurveyForm";
import { getJwtToken } from "../../state/selectors/user";

const CreateView = ({ jwtToken }) => (
  <Screen>
    <Grid container spacing={16} justify="center">
      <Grid item>
        <SurveyForm jwtToken={jwtToken} />
      </Grid>
    </Grid>
  </Screen>
);

const mapStateToProps = state => ({
  jwtToken: getJwtToken(state)
});

export default connect(mapStateToProps)(CreateView);
