import React from "react";
import cn from "classnames";

import isEmpty from "lodash/isEmpty";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  row: {
    display: "grid",
    gridTemplateRows: "minmax(min-content, max-content)",
    alignItems: "center",
    gridTemplateColumns:
      "350px 250px repeat(var(--count), minmax(50px, 250px)) 400px",
    [theme.breakpoints.down("lg")]: {
      gridTemplateColumns:
        "105px 250px repeat(var(--count), minmax(50px, 250px)) 400px"
    },
    "@media only screen and (max-width: 1280px) and (orientation: landscape)": {
      gridTemplateColumns:
        "100px 250px repeat(var(--count), minmax(50px, 250px)) 100px"
    },
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns:
        "50px 250px repeat(var(--count), minmax(50px, 250px)) 150px"
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "0 250px repeat(var(--count), minmax(50px, 250px)) 0"
    },
    "&:nth-child(even)": {
      backgroundColor: theme.palette.questionnaire.stripe,
      "& div:hover": {
        cursor: "pointer",
        backgroundColor: "#ffffff",
        border: `2px solid ${theme.palette.questionnaire.stripe}`
      }
    },
    "&:nth-child(odd):not(:first-child) div:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.questionnaire.stripe,
      border: `2px solid #ffffff`
    }
  }
});

const GridRow = ({ classes, children, className }) => (
  <div className={cn({ [className]: !isEmpty(className) }, classes.row)}>
    <div />
    {children}
    <div />
  </div>
);

export default withStyles(styles)(GridRow);
