import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  navigateNext,
  navigatePrevious
} from "../../../../state/actions/navigate";

class KeyboardShortcuts extends Component {
  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = event => {
    // Disable while user in textarea
    if (event.target.type === "textarea") return false;
    // Disable while user in input text
    if (event.target.type === "text") return false;

    if (
      (event.ctrlKey || event.metaKey) &&
      (event.key === "ArrowRight" || event.key === "Enter")
    ) {
      this.props.onNavigateNext();
    }

    if ((event.ctrlKey || event.metaKey) && event.key === "ArrowLeft") {
      this.props.onNavigatePrevious();
    }
  };

  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

KeyboardShortcuts.propTypes = {
  onNavigateNext: PropTypes.func.isRequired,
  onNavigatePrevious: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element]))
  ]).isRequired
};

const mapDispatchToProps = dispatch => ({
  onNavigateNext: () => dispatch(navigateNext()),
  onNavigatePrevious: () => dispatch(navigatePrevious())
});

export default connect(
  null,
  mapDispatchToProps
)(KeyboardShortcuts);
