import React, { useEffect } from "react";

import { Router } from "@reach/router";
import { connect } from "react-redux";

import Home from "./screens/Home";
import Questionnaire from "./screens/Questionnaire";
import Surveys from "./screens/Surveys";
import SurveyCreateView from "./screens/Surveys/CreateView";
import SurveyEditView from "./screens/Surveys/EditView";
import CookiePolicy from "./screens/CookiePolicy";

import { importUserFromSession } from "./state/actions/user";
import { isLoggedIn, isInAdminGroup } from "./state/selectors/user";
import CookieBot from "./components/CookieBot/CookieBot";

const App = ({ isAnonymous, isAdmin, onStart }) => {
  useEffect(() => {
    onStart();
  }, [isAnonymous]);

  return (
    <>
      <CookieBot />
      <Router style={{ height: "100%" }}>
        {isAdmin && <SurveyCreateView path="/admin/surveys/new" />}
        {isAdmin && <SurveyEditView path="/admin/surveys/:slug" />}
        {isAdmin && <Surveys path="/admin" />}
        <Questionnaire path="/name/:slug" />
        <CookiePolicy path="/cookie-policy" />
        <Home path="/" />
      </Router>
    </>
  );
};

const mapStateToProps = (state) => ({
  isAnonymous: !isLoggedIn(state),
  isAdmin: isInAdminGroup(state),
});

const mapDispatchToProps = (dispatch) => ({
  onStart: () => dispatch(importUserFromSession()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
