import { useEffect, useState } from "react";
import get from "lodash/get";

import { extractQueryParams } from "../../../utils/url";

const useQueryParams = (location, initialLocale) => {
  const [metadata, setMetadata] = useState({});
  const [locale, setLocale] = useState(initialLocale);
  const [questionId, setQuestionId] = useState(undefined);
  const [versionName, setVersionName] = useState(undefined);

  useEffect(() => {
    const query = extractQueryParams(location);
    // The query param locale should override the one already set in state
    const queryLocale = get(query, "locale");
    const question = get(query, "questionId");
    const utm_campaign = get(query, "utm_campaign");
    const utm_source = get(query, "utm_source");
    const utm_medium = get(query, "utm_medium");
    const utm_content = get(query, "utm_content");
    const member_id = get(query, "member_id");
    const ext_uuid = get(query, "ext_uuid");
    const version = get(query, "version");

    setMetadata({
      utm_campaign,
      utm_source,
      utm_medium,
      utm_content,
      member_id,
      ext_uuid
    });
    setLocale(queryLocale);
    setQuestionId(question);
    setVersionName(version);

    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      window.history.pushState({}, document.title, location.pathname);
    }
  }, [location]);

  // const doSetupMetadata = loc => setLocation(loc);

  return {
    metadata,
    queryLocale: locale,
    questionId,
    versionName,
    clearQueryParamLocale: () => setLocale(undefined)
  };
};

export default useQueryParams;
