import React from "react";
import cn from "classnames";

import ReactMarkdown from "react-markdown";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    "& h3": {
      fontFamily: "Roboto",
      color: "#2b2a28",
      fontWeight: 300,
      fontSize: 20
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      marginRight: "-20px",
      "& h3": {
        fontSize: 16
      }
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "100px",
      paddingRight: "100px"
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "350px",
      paddingRight: "400px"
    }
  }
});

const QuestionExtraText = ({ content, classes, className = "" }) => (
  <ReactMarkdown
    className={cn(classes.root, className)}
    source={content}
    escapeHtml
  />
);

export default withStyles(styles)(QuestionExtraText);
