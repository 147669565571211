import React from "react";

import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

import Copyright from "../../../../components/Copyright";
import Markdown from "../shared/md";
import PageImage from "../shared/PageImage/index";
import { getPageContent } from "../shared/selectors";
import { CLOSED_PAGE } from "../types";

const styles = theme => ({
  container: {
    padding: 80,
    [theme.breakpoints.down("xs")]: {
      padding: 24
    }
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  image: {
    [theme.breakpoints.up("xl")]: {
      justifyContent: "center"
    }
  },
  content: {
    maxWidth: 450
  }
});

const ClosingPage = ({ classes, content, Images }) => {
  const { ClosingAsset } = Images;

  return (
    <Grid container spacing={16} className={classes.container}>
      <Grid item xs={12} className={classes.contentWrapper}>
        <PageImage
          asset={ClosingAsset}
          alt="closing-img"
          className={classes.image}
        />
        <Markdown content={content} className={classes.content} />
      </Grid>
      <Grid item xs={12}>
        <Copyright />
      </Grid>
    </Grid>
  );
};

const getDefault = () => I18n.t("close-dialog-content");

const mapStateToProps = state => ({
  content: getPageContent(state, CLOSED_PAGE, getDefault)
});

export default connect(mapStateToProps)(withStyles(styles)(ClosingPage));
