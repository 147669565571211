import find from "lodash/find";
import get from "lodash/get";
import template from "lodash/template";
import includes from "lodash/includes";
import replace from "lodash/replace";

const BRANCH_DE = "dn21";
const BRANCH_ARVR = "arvr4";

export const getPageContent = (state, name, getDefault = () => "") => {
  const {
    survey: { branch: currentBranch, pages },
    i18n: { locale }
  } = state;
  const page = find(pages, { name, locale });

  let BRANCH = "";
  let SURVEY_INTRO_URL = "";
  let SURVEY_OUTRO_URL = "";
  if (includes(currentBranch, BRANCH_DE)) {
    BRANCH = BRANCH_DE;
    SURVEY_INTRO_URL = process.env.REACT_APP_DE_INTRO_URL;
    SURVEY_OUTRO_URL = process.env.REACT_APP_DE_OUTRO_URL;
  } else if (includes(currentBranch, BRANCH_ARVR)) {
    BRANCH = BRANCH_ARVR;
    SURVEY_INTRO_URL = process.env.REACT_APP_ARVR_INTRO_URL;
    SURVEY_OUTRO_URL = process.env.REACT_APP_DE_OUTRO_URL;
  } else {
    SURVEY_INTRO_URL = process.env.REACT_APP_DE_INTRO_URL;
    SURVEY_OUTRO_URL = process.env.REACT_APP_OTHER_OUTRO_URL;
  }

  const content = get(page, "content", getDefault());
  const compiled = template(content);
  let compiledContent = "";

  try {
    compiledContent = compiled({
      BRANCH,
      LOCALE: locale,
      SURVEY_OUTRO_URL,
      SURVEY_INTRO_URL
    });
    console.log({
      BRANCH,
      LOCALE: locale,
      SURVEY_OUTRO_URL,
      SURVEY_INTRO_URL
    });
    console.log(compiledContent);
  } catch (error) {
    console.log(error);
  }

  // HACK: ARVR doesn't support all locales and WIX breaks if there's not such a locale
  // ["en", "ru", "zh", "es", "vi"];
  if (includes(currentBranch, BRANCH_ARVR) && locale === "pt") {
    compiledContent = replace(compiledContent, "lang=pt", "lang=en");
  } else if (includes(currentBranch, BRANCH_ARVR) && locale === "ko") {
    compiledContent = replace(compiledContent, "lang=ko", "lang=en");
  } else if (includes(currentBranch, BRANCH_ARVR) && locale === "ja") {
    compiledContent = replace(compiledContent, "lang=ja", "lang=en");
  } else if (includes(currentBranch, BRANCH_ARVR) && locale === "tw") {
    compiledContent = replace(compiledContent, "lang=tw", "lang=en");
  }

  return compiledContent;
};

export default getPageContent;
