import jsonLogic from "json-logic-js";

import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import { createResponse, getPayload } from "./api";
import getVersion from "./getVersion";

const execAnonymousFlow = async (
  survey,
  metadata,
  versionName,
  locale,
  branch
) => {
  const surveyId = get(survey, "_id");
  let responseId = null;
  let payload = {};
  const userId = undefined;
  const jwtToken = undefined;
  const authStatus = false;

  // Get version by query param only in draft mode
  const name =
    survey.status === "draft" && !isEmpty(versionName) ? versionName : "";
  const version = getVersion(name, survey, branch, authStatus);

  if (version === undefined) {
    return new Promise((resolve, reject) =>
      reject(new Error("The specified version does not exist"))
    );
  }

  // Create graph from survey spec
  const graph = jsonLogic.apply(version.blocks);

  return new Promise(async (resolve, reject) => {
    try {
      const response = await createResponse(
        surveyId,
        userId,
        locale,
        { graph, version: version.name },
        metadata,
        jwtToken
      );
      responseId = get(response, "id", null);
      payload = await getPayload(surveyId, userId, responseId, jwtToken);

      resolve({ responseId, payload, version });
    } catch (error) {
      reject(error);
    }
  });
};

export default execAnonymousFlow;
