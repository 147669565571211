import en from './en.json';
import es from './es.json';
import ja from './ja.json';
import ko from './ko.json';
import pt from './pt.json';
import ru from './ru.json';
import tw from './tw.json';
import vi from './vi.json';
import zh from './zh.json';

export default {
  en,
  es,
  ja,
  ko,
  pt,
  ru,
  tw,
  vi,
  zh
};
