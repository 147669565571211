import React, { useEffect } from "react";

import { connect } from "react-redux";

import get from "lodash/get";
import find from "lodash/find";
import some from "lodash/some";
import isEmpty from "lodash/isEmpty";

import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

import Authenticator from "../../../../components/Authenticator";
import { importUserFromSession } from "../../../../state/actions/user";
import Markdown from "../shared/md";
import Footer from "./Footer";
import PageImage from "../shared/PageImage/index";
import { LANDING_PAGE } from "../types";
import { getPageContent } from "../shared/selectors";
import { gaPageView } from "../../../../utils/analytics";

const styles = theme => ({
  container: {
    padding: "145px 308px 0 250px",
    fontFamily: "Roboto",
    height: "100%",
    boxSizing: "border-box",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      padding: "168px 16px 0 16px"
    }
  },
  body: {
    justifyContent: "center",
    [theme.breakpoints.up("xl")]: {
      justifyContent: "center"
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse"
    }
  },
  item: {
    paddingLeft: "64px",
    [theme.breakpoints.down("sm")]: {
      padding: "0"
    }
  },
  footer: {
    marginTop: "25px",
    height: "220px"
  }
});

const LandingPage = ({
  classes,
  showAuthorizer,
  content,
  newsletters,
  Images,
  doPrepareQuestionnaire,
  onFetchUser
}) => {
  useEffect(() => {
    gaPageView();
  }, []);

  const { BeforeYourStartAsset } = Images;

  return (
    <div className={classes.container}>
      <Grid container className={classes.body}>
        {showAuthorizer && (
          <Grid item sm={12} lg={6} xl={4} id="authenticator-form">
            <Authenticator
              newsletters={newsletters}
              doUpdateState={onFetchUser}
            />
          </Grid>
        )}
        {!isEmpty(content) && (
          <Grid
            item
            sm={12}
            lg={6}
            xl={6}
            className={classes.item}
            align="center"
          >
            <PageImage asset={BeforeYourStartAsset} alt="before-you-start" />
            <Markdown content={content} />
          </Grid>
        )}
      </Grid>
      <Grid container className={classes.footer}>
        <Grid item xs={12}>
          <Footer doPrepareQuestionnaire={doPrepareQuestionnaire} />
        </Grid>
      </Grid>
    </div>
  );
};

const shouldShowAuthorizer = (state, { branch }) => {
  const { survey } = state;
  const versions = get(find(survey.branches, { name: branch }), "versions", []);

  return some(versions, { auth: true });
};

const getSurveyNewsletters = state =>
  get(
    state,
    ["survey", "newsletters"],
    [
      {
        category: "DE_COMMUNITY",
        value: "community-newsletter",
        status: "unsubscribed"
      },
      {
        category: "DE_SURVEY",
        value: "survey-newsletter",
        status: "unsubscribed"
      }
    ]
  );

const mapStateToProps = (state, ownProps) => ({
  showAuthorizer: shouldShowAuthorizer(state, ownProps),
  content: getPageContent(state, LANDING_PAGE),
  newsletters: getSurveyNewsletters(state)
});

const mapDispatchToProps = dispatch => ({
  onFetchUser: () => dispatch(importUserFromSession())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LandingPage));
