import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import indexOf from "lodash/indexOf";

import { normalizeUser } from "../actions/user";

export const isLoggedIn = state => !isEmpty(get(state, ["user", "userId"], ""));

export const getUserId = state => get(state, ["user", "userId"], "");

export const isInAdminGroup = state =>
  indexOf(get(state, ["user", "groups"], []), "admin") !== -1;

export const getJwtToken = state => get(state, ["user", "jwtToken"], "");

export const getCognitoUserId = cognitoUser =>
  get(normalizeUser(cognitoUser), "userId", "");

export const getCognitoUserJwt = cognitoUser =>
  get(normalizeUser(cognitoUser), "jwtToken", "");
