import { API } from "aws-amplify";

import setAppStatus from "./setAppStatus";
import importQuestionnaire from "./importQuestionnaire";
import {
  QUESTIONNAIRE_STATUS,
  STATUS_OK,
  STATUS_PENDING,
  STATUS_ERROR
} from "../status";

const pullQuestionnaire = (locale, surveyId) => async (dispatch, getState) => {
  dispatch(setAppStatus(QUESTIONNAIRE_STATUS, STATUS_PENDING, ""));

  try {
    const questionnaire = await API.get(
      "surveys",
      `/surveys/${surveyId}/questions/locale/${locale}`
    );
    const { version, payload, responseId } = getState();

    dispatch(
      importQuestionnaire({
        blocks: questionnaire.blocks,
        questions: questionnaire.questions,
        version,
        slug: surveyId,
        question: undefined,
        responseId,
        payload
      })
    );
    dispatch(setAppStatus(QUESTIONNAIRE_STATUS, STATUS_OK));
  } catch (error) {
    dispatch(setAppStatus(QUESTIONNAIRE_STATUS, STATUS_ERROR, error.message));
  }
};

export default pullQuestionnaire;
