import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { navigate } from "@reach/router";

import Authenticator from "../../components/Authenticator";
import { importUserFromSession } from "../../state/actions/user";
import { isLoggedIn, isInAdminGroup } from "../../state/selectors/user";

const styles = theme => ({
  card: {
    margin: theme.spacing.unit * 3
  }
});

const Home = ({ classes, isAnonymous, isAdmin, onSignedIn }) => {
  useEffect(() => {
    if (isAdmin) {
      navigate("/admin");
    }
  }, [isAdmin]);

  const onAuthChange = state => {
    switch (state) {
      case "signedIn": {
        onSignedIn();
        break;
      }
      default:
        break;
    }
  };

  return (
    <Grid container spacing={16} justify="center">
      <Grid item xs={6}>
        <Card className={classes.card}>
          <CardContent>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              Welcome to survey tool. Please login to your account
            </Typography>
            {isAnonymous && <Authenticator onStateChange={onAuthChange} />}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => ({
  isAnonymous: !isLoggedIn(state),
  isAdmin: isInAdminGroup(state)
});

const mapDispatchToProps = dispatch => ({
  onSignedIn: () => dispatch(importUserFromSession())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Home));
