import React, { useState } from "react";
import { navigate } from "@reach/router";
import { withStyles } from "@material-ui/core/styles";
import { API } from "aws-amplify";
import { Formik } from "formik";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { MenuItem } from "@material-ui/core";
import LuxonAdapter from "@date-io/luxon";
import { Settings, DateTime } from "luxon";
import Snackbar from "../../../../components/Snackbar";

//setting the default timezone to GMT/UTC
Settings.defaultZone = "Etc/GMT";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fefefe",
    width: 500
  },
  formElement: {
    margin: theme.spacing.unit
  }
});

const SurveyForm = ({ classes, survey, jwtToken }) => {
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const typeValues = ["public", "private", "hybrid"];
  const now = DateTime.now();
  const defaultDate = DateTime.utc(now.year, now.month, now.day, 0).toISO();
  const updateSurvey = async data => {
    setLoader(true);
    try {
      await API.put("surveys", `/surveys/${survey._id}`, {
        body: {
          ...data
        },
        headers: { Authorization: `Bearer ${jwtToken}` }
      });
      navigate("/admin");
    } catch (error) {
      setLoader(false);
      setErrorMessage(error.message);
    }
  };

  const createSurvey = async data => {
    setLoader(true);

    try {
      await API.post("surveys", "/surveys", {
        body: {
          ...data
        },
        headers: { Authorization: `Bearer ${jwtToken}` }
      });
      navigate("/admin");
    } catch (error) {
      setLoader(false);
      setErrorMessage(error.message);
    }
  };

  return (
    <>
      {loader && <div>loading...</div>}
      <MuiPickersUtilsProvider utils={LuxonAdapter}>
        <Formik
          enableReinitialize
          initialValues={{
            name: "",
            startDate: defaultDate,
            endDate: defaultDate,
            type: (survey && survey.type) ?? typeValues[0],
            ...survey
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Name is required"),
            startDate: Yup.date().required("startDate is Required"),
            endDate: Yup.date()
              .when("startDate", st =>
                Yup.date().min(st, "End date shoud be greater than startDate")
              )
              .required("Required"),
            type: Yup.string().required("type is required")
          })}
          onSubmit={values =>
            survey === undefined ? createSurvey(values) : updateSurvey(values)
          }
          render={({
            values,
            handleChange,
            errors,
            touched,
            handleSubmit,
            setFieldValue
          }) => (
            <form className={classes.container} noValidate autoComplete="off">
              <TextField
                name="name"
                label="Name"
                error={errors.name && touched.name}
                helperText={errors.name}
                className={classes.formElement}
                value={values.name}
                onChange={handleChange}
                margin="normal"
              />
              <TextField
                name="type"
                label="type"
                select={true}
                value={values.type}
                onChange={handleChange}
                helperText={errors.type}
                margin="normal"
                className={classes.formElement}
                InputLabelProps={{
                  shrink: true
                }}
              >
                {typeValues.map((type_, idx) => (
                  <MenuItem key={idx} value={type_}>
                    {type_}
                  </MenuItem>
                ))}
              </TextField>
              <KeyboardDateTimePicker
                ampm={false}
                name="startDate"
                label="start date in GMT/UTC"
                inputVariant="outlined"
                format="DD T"
                className={classes.formElement}
                value={values.startDate}
                error={errors.startDate && touched.startDate}
                helperText={errors.startDate}
                onChange={value => setFieldValue("startDate", value)}
                KeyboardButtonProps={{
                  "aria-label": "start date"
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <KeyboardDateTimePicker
                ampm={false}
                name="endDate"
                label="End Date in GMT/UTC"
                inputVariant="outlined"
                format="DD T"
                className={classes.formElement}
                value={values.endDate}
                error={errors.endDate && touched.endDate}
                helperText={errors.endDate}
                onChange={value => setFieldValue("endDate", value)}
                KeyboardButtonProps={{
                  "aria-label": "end date"
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.formElement}
                onClick={handleSubmit}
              >
                {survey === undefined ? "Save" : "Update"}
              </Button>
            </form>
          )}
        />
        <Snackbar
          message={errorMessage}
          onClose={() => setErrorMessage(null)}
          open={Boolean(errorMessage)}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};

export default withStyles(styles)(SurveyForm);
