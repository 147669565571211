import { Auth } from "aws-amplify";
import jsonLogic from "json-logic-js";

import get from "lodash/get";
import head from "lodash/head";
import isEmpty from "lodash/isEmpty";

import {
  getCognitoUserId,
  getCognitoUserJwt
} from "../../../../state/selectors/user";
import { createResponse, getPayload, getUserResponses } from "./api";
import getVersion from "./getVersion";

const execAuthenticatedFlow = async (
  survey,
  metadata,
  versionName,
  locale,
  branch
) => {
  const surveyId = get(survey, "_id");
  let responseId = null;
  let payload = {};

  const auth = true;

  // Get version by query param only in draft mode
  const name =
    survey.status === "draft" && !isEmpty(versionName) ? versionName : "";
  // The version can be overwritten if the user has already response
  let version = getVersion(name, survey, branch, auth);

  if (version === undefined) {
    return new Promise((resolve, reject) =>
      reject(new Error("The specified version does not exist"))
    );
  }

  // Create graph from survey spec
  const graph = jsonLogic.apply(version.blocks);

  return new Promise(async (resolve, reject) => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();

      const userId = getCognitoUserId(cognitoUser);
      const jwtToken = getCognitoUserJwt(cognitoUser);

      // Fetch user responses
      let userResponses = await getUserResponses(
        get(survey, "_id"),
        userId,
        jwtToken
      );

      // Handle the flow of empty responses
      if (userResponses.length === 0) {
        try {
          const response = await createResponse(
            surveyId,
            userId,
            locale,
            { graph, version: version.name },
            metadata,
            jwtToken
          );
          responseId = get(response, "id", null);
          payload = await getPayload(surveyId, userId, responseId, jwtToken);
        } catch (error) {
          // Handle the case of a response form a different tab
          if (error.response.status === 409) {
            userResponses = await getUserResponses(surveyId, userId, jwtToken);
            const response = head(userResponses);
            responseId = get(response, "_id", null);
            payload = await getPayload(surveyId, userId, responseId, jwtToken);
          }
        }
        // Handle the case of having a response
      } else {
        const response = head(userResponses);
        responseId = get(response, "_id", null);
        payload = await getPayload(surveyId, userId, responseId, jwtToken);
        version = getVersion(payload.version, survey.versions, branch, auth);
      }

      resolve({ responseId, payload, version, user: { userId, jwtToken } });
    } catch (error) {
      reject(error);
    }
  });
};

export default execAuthenticatedFlow;
