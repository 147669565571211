import React, { Component } from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import find from "lodash/find";
import flow from "lodash/flow";
import get from "lodash/get";
import keyBy from "lodash/keyBy";
import mapValues from "lodash/mapValues";
import noop from "lodash/noop";
import partialRight from "lodash/partialRight";
import Grid from "@material-ui/core/Grid";

import ListLayout from "../ListLayout";
import RatingItem from "../RatingItem";
import styles from "./RatingList.module.css";

class RatingList extends Component {
  static get propTypes() {
    return {
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
          source: PropTypes.string,
          path: PropTypes.string
        })
      ).isRequired,
      value: PropTypes.object,
      duration: PropTypes.number.isRequired,
      custom: PropTypes.objectOf(
        PropTypes.shape({
          name: PropTypes.string,
          text: PropTypes.string
        })
      ),
      onInit: PropTypes.func,
      onChange: PropTypes.func
    };
  }

  static get defaultProps() {
    return {
      value: {},
      custom: null,
      onInit: noop,
      onChange: noop
    };
  }

  constructor(props) {
    super(props);

    const formatValue = flow([
      partialRight(keyBy, "name"),
      partialRight(mapValues, (option, key) =>
        get(props.value, key, {
          value: null
        })
      )
    ]);

    this.state = {
      value: formatValue(props.options)
    };

    this.handleChange = this.handleChange.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  componentWillMount() {
    // Pass state and answer to parent (Question) component right before being appended to DOM
    this.props.onInit(
      this.props.name,
      this.props.type,
      {
        //      options: map(this.props.options, (v) => omitBy({ name: v.name, source: v.source, path: v.path }, isUndefined))
        options: this.props.options,
        custom: this.props.custom
      },
      this.props.value,
      this.props.duration
    );
  }

  handleChange(e, rating) {
    const state = this.state;
    const option = find(this.props.options, { name: rating.name });
    state.value[option.name].value = option.value;
    state.value[option.name].score = rating.value;
    this.setState(state);

    this.props.onChange(e, state.value);
  }

  renderItem(option) {
    const currentState = this.state.value[option.name];

    // TODO: Migrate to react-flexbox-grid
    return (
      <MediaQuery key={option.name} maxWidth={480}>
        {matches =>
          matches ? (
            <div
              className="row"
              key={option.name}
              style={{ margin: "0.5em 1em 0.5em 1em" }}
            >
              <div className="col-xs-12">
                <label className={styles.optionText}>{option.text}</label>
              </div>
              <div className="col-xs-12">
                <RatingItem
                  name={option.name}
                  value={currentState.score}
                  onRatingChange={this.handleChange}
                />
              </div>
            </div>
          ) : (
            <Grid container alignItems="center" className={styles.row}>
              <Grid item xs={6}>
                <span className={styles.optionText}>{option.text}</span>
              </Grid>
              <Grid item xs={6}>
                <RatingItem
                  name={option.name}
                  value={currentState.score}
                  onRatingChange={this.handleChange}
                />
              </Grid>
            </Grid>
          )
        }
      </MediaQuery>
    );
  }

  render() {
    return (
      <ListLayout columns={1} width="100%">
        {this.props.options.map(o => this.renderItem(o))}
      </ListLayout>
    );
  }
}

export default RatingList;
