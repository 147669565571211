import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    paddingBottom: 74
  }
});

const GridContainer = ({ classes, children, optionColumns }) => (
  <div
    className={classes.container}
    style={{
      "--count": optionColumns
    }}
  >
    {children}
  </div>
);

export default withStyles(styles)(GridContainer);
