import React from "react";

import cn from "classnames";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  imageContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("xl")]: {
      justifyContent: "flex-start"
    }
  },
  backgroundImage: {
    height: "250px",
    objectFit: "contain"
  }
});

const PageImage = ({ classes, asset, alt = "page-image", className = "" }) => (
  <div className={cn(classes.imageContainer, className)}>
    <img src={asset} className={classes.backgroundImage} alt={alt} />
  </div>
);

export default withStyles(styles)(PageImage);
