import React from "react";
import PropTypes from "prop-types";

import styles from "./Image.module.css";

const ImageRenderer = props => (
  <span className={styles.imageContainer}>
    <img src={props.src} alt={props.alt} className={styles.image} />
  </span>
);

ImageRenderer.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default ImageRenderer;
