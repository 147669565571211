import ReactGA from "react-ga";

import get from "lodash/get";
import set from "lodash/set";
import includes from "lodash/includes";
import forEach from "lodash/forEach";

export const gaPageView = path =>
  process.env.SURVEY_HOST !== "cn" && ReactGA.pageview(path);

export const initializeGA = () => {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
    debug:
      includes(".netlify.com", get(window, ["location", "origin"], "")) ||
      includes("127.0.0.1", get(window, ["location", "origin"], ""))
  });

  gaPageView(get(window, ["location", "pathname"], "/"));
};

export const handleGACookies = () => {
  if (!get(window, ["Cookiebot", "consent", "statistics"], false)) {
    set(window, `ga-disable-${process.env.REACT_APP_GA_TRACKING_ID}`, true);
  } else {
    set(window, `ga-disable-${process.env.REACT_APP_GA_TRACKING_ID}`, false);
  }
};

const gaEvent = eventProps =>
  process.env.SURVEY_HOST !== "cn" && ReactGA.event(eventProps);

export const trackNewSurveyUser = () =>
  gaEvent({
    category: "Community Members",
    action: "New survey user",
    label: "New survey sign up"
  });

export const trackQuestionnaire = hasResponse =>
  gaEvent({
    category: "Survey",
    action: hasResponse ? "Resume questionnaire" : "Start questionnaire",
    label: "Survey questionnaire"
  });

const trackCommunitySubscription = newsletter =>
  gaEvent({
    category: "Community Members",
    action: "Newsletter subscription",
    label: newsletter
  });

const trackSurveySubscription = newsletter =>
  gaEvent({
    category: "Community Members",
    action: "Email subscription",
    label: newsletter
  });

export const trackNewsletterSubscription = newsletters => {
  forEach(newsletters, ({ category, status }) => {
    if (status === "subscribed" && category === "DE_COMMUNITY") {
      trackCommunitySubscription(category);
    } else if (status === "subscribed" && category === "DE_SURVEY") {
      trackSurveySubscription(category);
    }
  });
};
