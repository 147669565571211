import React from "react";

import KeyboardIcon from "@material-ui/icons/KeyboardOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    marginRight: 8,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  icon: {
    color: theme.palette.primary.main
  }
});

const Keyboard = ({ classes }) => (
  <Tooltip
    className={classes.root}
    title={
      <>
        <div>CTRL + Right Arrow/Enter: move forward</div>
        <div>CTRL + Left Arrow: move backward</div>
      </>
    }
  >
    <KeyboardIcon className={classes.icon} />
  </Tooltip>
);

export default withStyles(styles)(Keyboard);
