import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { navigate } from "@reach/router";
import MediaQuery from "react-responsive";

import isEmpty from "lodash/isEmpty";

import IconButton from "@material-ui/core/IconButton";
import AccountIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToAppOutlined";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";

import { AuthReducer } from "@slashdata/authenticator";

import { signOut } from "../../../../../../state/actions/user";
import pushResponse from "../../../../../../state/actions/pushResponse";
import changeAuthenticatorView from "../../../../../../state/actions/changeAuthenticatorView";

const { SIGN_IN_STATUS } = AuthReducer;

const styles = theme => ({
  account: {
    height: "100%",
    width: "100%",
    color: "#b0c0da"
  },
  email: {
    marginLeft: 8,
    color: "#b0c0da",
    width: 100,
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  button: {
    height: 26,
    width: 26,
    padding: "0px !important",
    marginLeft: 8,
    [theme.breakpoints.down("sm")]: {
      height: 30,
      width: 30
    }
  }
});

const User = ({
  email,
  classes,
  redirect,
  dirtyQuestions,
  doPushResponse,
  onSignOut,
  hideSignIn,
  hideSignOut,
  setSignInStatus
}) => {
  const [open, setOpen] = useState(false);
  const anchorEl = useRef(null);

  const handleToggle = () => setOpen(!open);
  const handleClose = event => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  /**
   * Implementation of sign out with pushing responses
   */
  const handleSignOutImpl = async () => {
    try {
      await Auth.signOut();
      onSignOut();
      handleClose();
      if (redirect) {
        navigate(process.env.REACT_APP_SUBMIT_URL);
      }
    } catch (error) {
      console.log("Error");
      console.log(error);
    }
  };

  /**
   * Handle sign out when in questionnaire.
   */
  const handleSignOut = () => {
    if (isEmpty(dirtyQuestions)) {
      handleSignOutImpl();
    } else {
      doPushResponse(handleSignOutImpl);
    }
  };

  if (hideSignIn) return null;

  if (isEmpty(email))
    return (
      <MediaQuery maxWidth={480}>
        {matches =>
          matches ? (
            <IconButton
              onClick={() => setSignInStatus()}
              href="#authenticator-form"
            >
              <ExitToAppIcon fontSize="large" color="primary" />
            </IconButton>
          ) : (
            <Button onClick={() => setSignInStatus()}>Sign In</Button>
          )
        }
      </MediaQuery>
    );

  return (
    <>
      <Hidden smDown>
        <Typography className={classes.email}>{email}</Typography>
      </Hidden>
      <IconButton
        buttonRef={anchorEl}
        onClick={handleToggle}
        className={classes.button}
      >
        <AccountIcon className={classes.account} />
      </IconButton>
      {!hideSignOut && (
        <Popper
          open={open}
          anchorEl={anchorEl.current}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </>
  );
};

User.propTypes = {
  hideSignIn: PropTypes.bool,
  /**
   * Whether to redirect on sign out
   */
  redirect: PropTypes.bool,
  email: PropTypes.string,
  onSignOut: PropTypes.func.isRequired
};

User.defaultProps = {
  redirect: false,
  hideSignIn: false,
  email: ""
};

const mapStateToProps = state => ({
  email: state.user.email,
  dirtyQuestions: state.dirtyQuestions
});

const mapDispatchToProps = dispatch => ({
  // Update redux store
  onSignOut: () => dispatch(signOut()),
  setSignInStatus: () => dispatch(changeAuthenticatorView(SIGN_IN_STATUS)),
  doPushResponse: onSuccess => dispatch(pushResponse({}, onSuccess))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(User));
