import jsonLogic from "json-logic-js";
import assign from "lodash/assign";
import find from "lodash/find";
import get from "lodash/get";
import indexOf from "lodash/indexOf";

import {
  IMPORT_QUESTIONNAIRE,
  RESET,
  SET_CURSOR_NEXT,
  SET_CURSOR_PREVIOUS,
  SET_CURSOR
} from "../types";

/*
 * Create path of questions based on a graph (defining branches) and current answers.
 *
 * First each block is tested to see if it's visible or not. Then for each remaining blocks we perform a similar
 * test for each of its questions.
 *
 * The resulting path will contain questions which are allowed to be displayed.
 */
function createPath(blocks, questions, payload) {
  const path = payload.graph
    .map(block => find(blocks, { name: block })) // Find block object for each block name
    // .map(block => { console.log(block.name); return block; })
    .filter(block => jsonLogic.apply(block.displayLogic, { answer: payload.answer, context: payload.context })) // Filter out those who don't pass the test
    .map(block => block.questions) // For each block pass its array of questions
    .reduce((a, b) => a.concat(b), []) // Flatten array of arrays of questions
    .map(q => find(questions, { name: q })) // Find question object for each question name
    // .map(q => { console.log(q.name); return q; })
    .filter(q => jsonLogic.apply(q.displayLogic || true, { answer: payload.answer, context: payload.context })) // Filter out those who don't pass the (existing) test
    .map(q => q.name); // Return list of question names

  return path;
}

function handleImport(state, action) {
  const { blocks, questions } = action;

  // If payload has a graph already defined it will overwrite the one generated
  const payload = assign({}, { graph: action.graph }, action.payload);

  // Create initial path
  const path = createPath(blocks, questions, payload);

  // Find index of question passed as parameter (if any)
  const { question } = action;
  let index = indexOf(path, question);

  // If invalid or non-existent then try using the value defined in payload
  if (index < 0) {
    const current = get(action, ["payload", "current"]);
    index = indexOf(path, current);
  }

  // If all fails default to start unless the path is empty
  if (index < 0) {
    index = path.length > 0 ? 0 : -1;
  }

  return { path, index };
}

function handleNext(state, action) {
  // Make sure the existing state is valid, othewise do nothing
  if (state.index < 0 || state.index >= state.path.length) {
    return state;
  }

  // Retrieve current question from path
  const question = state.path[state.index];

  // Generate new path and locate current question
  const { blocks, questions, payload } = action;
  const path = createPath(blocks, questions, payload);
  const index = path.indexOf(question);

  // If index is valid then move one position forward
  if (index >= 0 && index < path.length - 1) {
    return {
      path,
      index: index + 1
    };
  }

  return state;
}

function handlePrevious(state, action) {
  // Make sure the existing state is valid, othewise do nothing
  if (state.index < 0 || state.index >= state.path.length) {
    return state;
  }

  // Retrieve current question from path
  const question = state.path[state.index];

  // Generate new path and locate current question
  const { blocks, questions, payload } = action;
  const path = createPath(blocks, questions, payload);
  const index = path.indexOf(question);

  // If index is valid then move one position backward
  if (index > 0 && index < path.length) {
    return {
      path,
      index: index - 1
    };
  }

  return state;
}

function handleCursor(state, action) {
  // Generate new path and locate current question
  const { blocks, questions, payload, value } = action;
  const path = createPath(blocks, questions, payload);
  const index = path.indexOf(value);

  // If index is valid then move to index
  if (index >= 0 && index < path.length) {
    return {
      path,
      index
    };
  }

  return state;
}

export default function(state = { path: [], index: -1 }, action) {
  switch (action.type) {
    case IMPORT_QUESTIONNAIRE:
      return handleImport(state, action);

    case SET_CURSOR_NEXT:
      return handleNext(state, action);

    case SET_CURSOR_PREVIOUS:
      return handlePrevious(state, action);

    case SET_CURSOR:
      return handleCursor(state, action);

    case RESET:
      return { path: [], index: -1 };

    default:
      return state;
  }
}
