import get from "lodash/get";

import { IMPORT_SURVEY } from "../types";

export const initialState = {
  _id: "",
  name: "",
  startDate: "",
  endDate: "",
  status: "",
  theme: {
    appBarLogo: "",
    loadingPage: ""
  },
  versions: [],
  locales: [],
  pages: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case IMPORT_SURVEY:
      return {
        ...state,
        ...get(action, "survey", {})
      };
    default:
      return state;
  }
};

export default reducer;
