import setCursorNext from "./setCursorNext";
import setCursorPrevious from "./setCursorPrevious";
import pushResponse from "./pushResponse";
import setAppStatus from "./setAppStatus";
import { isLastQuestion } from "../selectors/questionnaire";
import { QUESTIONNAIRE_STATUS, STATUS_COMPLETED } from "../status";

export const navigateNext = () => (dispatch, getState) => {
  const state = getState();
  const { blocks, questions, payload, cursor } = state;

  if (isLastQuestion(state)) {
    dispatch(finalizeSurvey());
  } else {
    dispatch(
      setCursorNext(blocks, questions, payload, cursor.path[cursor.index])
    );

    window.scrollTo(0, 0);
  }
};

export const navigatePrevious = () => (dispatch, getState) => {
  const state = getState();

  const { blocks, questions, payload, cursor } = state;
  dispatch(
    setCursorPrevious(blocks, questions, payload, cursor.path[cursor.index])
  );

  window.scrollTo(0, 0);
};

export const finalizeSurvey = () => dispatch => {
  const onSuccess = () =>
    dispatch(setAppStatus(QUESTIONNAIRE_STATUS, STATUS_COMPLETED));

  dispatch(pushResponse({ force: true, verbose: true }, onSuccess));
};
