import React, { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import noop from "lodash/noop";

import sanitize from "../../utils/sanitizeInput";
import combineClassNames from "../../utils/combineClassNames";
import styles from "./Input.module.css";

class Input extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   value: props.value || this.props.defaultValue || "",
    //   valid: props.valid
    // };

    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    // Pass state and answer to parent (Question) component right before being appended to DOM
    this.props.onInit(
      this.props.name,
      this.props.type,
      {
        custom: this.props.custom
      },
      { value: this.props.value },
      this.props.duration
    );
  }

  /**
   * Helper function for managing input validity
   * @param  {String}  value : the new input value
   * @return {Boolean}
   */
  isInputValid(value) {
    const valueLength = value ? value.replace(/\r\n|\r|\n/g, "").length : 0; // discount newline chars
    if (this.props.minLength && valueLength < this.props.minLength)
      return false;
    if (this.props.maxLength && valueLength > this.props.maxLength)
      return false;
    return true;
  }

  handleChange(e) {
    // this.setState({
    //   value: e.target.value,
    //   valid: this.isInputValid(e.target.value)
    // });

    // this.handleSubmit(e);
    const value = get(e, ["target", "value"], "");

    if (this.isInputValid(value)) {
      this.props.onChange(e, { value });
    }
  }

  handleClick(e) {
    this.props.onClick(e);
  }

  handleSubmit(e) {
    const actualValue = get(e, ["target", "value"], "");

    if (this.isInputValid(actualValue)) {
      this.props.onChange(e, { value: sanitize(actualValue) });
    }
  }

  handleKeyPress(e) {
    if (e.charCode === 13) {
      // i.e. "enter"
      this.handleSubmit(e);
    }
  }

  render() {
    const actualValue = get(
      this.props,
      "value",
      get(this.props, "defaultValue", "")
    );

    return (
      <input
        name={this.props.name}
        type={this.props.inputType}
        value={actualValue}
        disabled={this.props.disabled}
        placeholder={this.props.placeholder}
        title={this.props.placeholder}
        size={get(this.props.placeholder, "length", 24)}
        onKeyPress={this.handleKeyPress}
        onChange={this.handleChange}
        onClick={this.handleClick}
        onBlur={this.handleSubmit}
        className={combineClassNames({
          [styles.input]: true,
          [styles.invalid]: !this.isInputValid(actualValue)
        })}
      />
    );
  }
}

Input.propTypes = {
  inputType: PropTypes.oneOf(["text", "email"]),
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  valid: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  minLength: PropTypes.number, // minLength input char length
  maxLength: PropTypes.number, // maxLength input char length
  onInit: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  duration: PropTypes.number,
  custom: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
      text: PropTypes.string
    })
  )
};

Input.defaultProps = {
  inputType: "text",
  type: "WRITEIN", // Provide default values to ease usage in writeIn
  valid: true,
  placeholder: null,
  disabled: false,
  minLength: null,
  maxLength: null,
  onInit: noop,
  onChange: noop,
  value: "",
  defaultValue: "",
  onClick: noop,
  duration: 0, // Provide default values to ease usage in writeIn
  custom: null
};

export default Input;
