import React, { Component } from "react";
import PropTypes from "prop-types";
import assign from "lodash/assign";
import every from "lodash/every";
import filter from "lodash/filter";
import flow from "lodash/flow";
import get from "lodash/get";
// import isUndefined from 'lodash/isUndefined';
import keyBy from "lodash/keyBy";
// import map from 'lodash/map';
import mapValues from "lodash/mapValues";
import noop from "lodash/noop";
// import omitBy from 'lodash/omitBy';
import partial from "lodash/partial";
import partialRight from "lodash/partialRight";
import size from "lodash/size";
import some from "lodash/some";
import MediaQuery from "react-responsive";

import Input from "../Input";
import Checkbox from "../Checkbox";
import GridContainer from "../GridQuestion/GridContainer";
import GridHeader from "../GridQuestion/GridHeader";
import GridRow from "../GridQuestion/GridRow";
import GridGroup from "../GridQuestion/GridGroup";
import GridItem from "../GridQuestion/GridItem";
import Container from "../GridMobile/Container";
import Row from "../GridMobile/Row";
import Group from "../GridMobile/Group";
import Options from "../GridMobile/Options";
import Option from "../GridMobile/Option";

import styles from "./CheckboxGrid.module.css";

import { Tooltip } from "@material-ui/core";

const countGroupsSelected = flow([
  partialRight(filter, group => some(group.options, option => option === true)),
  size
]);

class CheckboxGrid extends Component {
  constructor(props) {
    super(props);

    const groupsMap = keyBy(props.groups, "name");
    const optionsMap = keyBy(props.options, "name");

    /**
     * Example group object:
     *  {
     *    group_a: {
     *      options: {
     *        option_a: true,
     *        option_b: false,
     *        option_c: false
     *      },
     *      writeIn: 'foobar',
     *      showOptionsInMobile: true
     *    },
     *    group_b
     *  }
     */
    const groups = mapValues(groupsMap, group => ({
      options: mapValues(
        optionsMap,
        option =>
          get(props.value, [group.name, "value", option.name, "value"]) ===
          option.value
      ),
      exclusive: group.exclusive,
      writeIn: group.writeIn
        ? get(props.value, [group.name, "writeIn"], "")
        : undefined,
      source: group.source,
      showOptionsInMobile: true // some(optionsMap, (option) => option === true)
    }));

    const disabled =
      countGroupsSelected(groups) === this.props.maxGroupsAllowed;

    this.state = { groups, disabled };
    this.optionsMap = optionsMap;
    this.handleChange = this.handleChange.bind(this);
    this.handleWriteIn = this.handleWriteIn.bind(this);
    this.toggleOptions = this.toggleOptions.bind(this);
  }

  componentWillMount() {
    // Pass state and answer to parent (Question) component right before being appended to DOM
    this.props.onInit(
      this.props.name,
      this.props.type,
      {
        // groups: map(this.props.groups, (v) => omitBy({ name: v.name, source: v.source, path: v.path }, isUndefined)),
        // options: map(this.props.options, (v) => omitBy({ name: v.name, source: v.source, path: v.path }, isUndefined))
        groups: this.props.groups,
        options: this.props.options,
        custom: this.props.custom
      },
      this.props.value,
      this.props.duration
    );
  }

  formatState(groups) {
    return mapValues(groups, groupObj => ({
      exclusive: groupObj.exclusive,
      options: groupObj.options,
      showOptionsInMobile: groupObj.showOptionsInMobile,
      source: groupObj.source,
      writeIn: some(groupObj.options, (value) => value) ? groupObj.writeIn : "",
    }));
  }

  formatValue(groups) {
    return mapValues(groups, groupObj => ({
      value: mapValues(groupObj.options, (checked, optionKey) => ({
        value: checked ? this.optionsMap[optionKey].value : null
      })),
      writeIn: groupObj.writeIn,
      source: groupObj.source
    }));
  }

  handleChange(group, option, e) {
    let groups = mapValues(this.state.groups, (groupObj, groupKey) =>
      assign({}, groupObj, {
        options: mapValues(groupObj.options, (optionValue, optionKey) => {
          // toggle current target
          if (groupKey === group.name && optionKey === option.name) {
            return e.target.checked;
          }

          // handle unique options
          if (option.unique && e.target.checked && optionKey === option.name) {
            return false;
          }

          // handle exclusive options
          if (
            (option.exclusive || this.optionsMap[optionKey].exclusive) &&
            e.target.checked &&
            groupKey === group.name
          ) {
            // current option is exclusive - or - some other option is exclusive
            return false;
          }

          // Hit exclusive so turning off other options
          if (
            group.exclusive &&
            e.target.checked &&
            optionKey === option.name
          ) {
            return false;
          }

          // Hit non-exclusive option so turning off exclusives
          if (
            !group.exclusive &&
            groupObj.exclusive &&
            e.target.checked &&
            optionKey === option.name
          ) {
            return false;
          }

          return optionValue;
        })
      })
    );

    const disabled =
      countGroupsSelected(groups) === this.props.maxGroupsAllowed;

    // Normalise state (reset writeIn with no options set in a group, etc)
    groups = this.formatState(groups);

    this.setState({ groups, disabled });
    this.props.onChange(e, this.formatValue(groups));
  }

  handleWriteIn(group, e) {
    const groups = mapValues(this.state.groups, (groupObj, groupKey) => {
      if (groupKey === group.name) {
        return assign({}, groupObj, { writeIn: e.target.value });
      }

      return groupObj;
    });

    this.setState({ groups });
    this.props.onChange(e, this.formatValue(groups));
  }

  toggleOptions(group) {
    const checked = !this.state.groups[group.name].showOptionsInMobile;
    const groups = mapValues(this.state.groups, (groupObj, groupKey) => {
      // toggle current slider
      if (groupKey === group.name) {
        if (checked) {
          return assign({}, groupObj, { showOptionsInMobile: true });
        }

        // unchecked -> close and nullify open slider
        return assign({}, groupObj, {
          showOptionsInMobile: false,
          options: mapValues(groupObj.options, () => false)
        });
      }

      // close other sliders without value
      if (checked && every(groupObj.options, e => e === false)) {
        return assign({}, groupObj, { showOptionsInMobile: false });
      }

      return groupObj; // return as is
    });

    const disabled =
      countGroupsSelected(groups) === this.props.maxGroupsAllowed;

    this.setState({ groups, disabled });
  }

  renderMobile() {
    return (
      <Container>
        {this.props.groups.map(group => {
          const currentGroup = this.state.groups[group.name];
          const isGroupAnswered = some(this.props.options, (option) => currentGroup.options[option.name]);

          return (
          <Row key={group.name}>
            <Group>
              {group.writeIn ? (
                <Tooltip
                  arrow={true}
                  open={currentGroup.writeIn && !isGroupAnswered ? true : false}
                  title="Please select at least one option"
                  >
                  <Input
                    name={group.name}
                    placeholder={group.text}
                    /*disabled={!isGroupAnswered}*/
                    onChange={partial(this.handleWriteIn, group)}
                    value={this.state.groups[group.name].writeIn}
                  />
                </Tooltip>
              ) : (
                group.text
              )}
              {/*
              <div>
                <Toggle
                  onChange={partial(this.toggleOptions, group)}
                  checked={this.state.groups[group.name].showOptionsInMobile}
                />
              </div>
              */}
            </Group>
            <Options
              hide={this.state.groups[group.name].showOptionsInMobile === false}
            >
              {this.props.options.map(option => (
                <Option key={`${group.name}_${option.name}`}>
                  <Checkbox
                    value={option.value}
                    checked={this.state.groups[group.name].options[option.name]}
                    disabled={
                      this.state.disabled &&
                      every(
                        this.state.groups[group.name].options,
                        v => v === false
                      )
                    }
                    onChange={partial(this.handleChange, group, option)}
                  />
                  <div className={styles.optionText}>{option.text}</div>
                </Option>
              ))}
            </Options>
          </Row>
        )})}
      </Container>
    );
  }

  renderDesktop() {
    return (
      <GridContainer optionColumns={this.props.options.length}>
        <GridHeader>
          <GridGroup />
          {this.props.options.map((option, columnIndex) => (
            <GridItem key={`option-${columnIndex}`}>{option.text}</GridItem>
          ))}
        </GridHeader>
        {this.props.groups.map((group, groupIndex) => {
          const currentGroup = this.state.groups[group.name];
          const isGroupAnswered = some(this.props.options, (option) => currentGroup.options[option.name]);

          return (
          <GridRow key={`group-${groupIndex}`}>
            <GridGroup>
              {group.writeIn ? (
                <Tooltip
                  arrow={true}
                  open={currentGroup.writeIn && !isGroupAnswered ? true : false}
                  title="Please select at least one option"
                  >
                  <Input
                    name={group.name}
                    placeholder={group.text}
                    /*disabled={!isGroupAnswered}*/
                    onChange={partial(this.handleWriteIn, group)}
                    value={this.state.groups[group.name].writeIn}
                  />
                </Tooltip>
              ) : (
                group.text
              )}
            </GridGroup>
            {this.props.options.map((option, optionIndex) => (
              <GridItem key={`option-${optionIndex}`}>
                <label key={option.name} className={styles.optionLabel}>
                  <Checkbox
                    value={option.value}
                    checked={this.state.groups[group.name].options[option.name]}
                    disabled={
                      this.state.disabled &&
                      every(
                        this.state.groups[group.name].options,
                        v => v === false
                      )
                    }
                    onChange={partial(this.handleChange, group, option)}
                  />
                </label>
              </GridItem>
            ))}
          </GridRow>
        )})}
      </GridContainer>
    );
  }

  renderIEMarkup() {
    return (
      <Container>
        {this.props.groups.map(group => {
          const currentGroup = this.state.groups[group.name];
          const isGroupAnswered = some(this.props.options, (option) => currentGroup.options[option.name]);
          
          return (
          <Row key={group.name}>
            <Group className={styles.ieGroup}>
              {group.writeIn ? (
                <Tooltip
                  arrow={true}
                  open={currentGroup.writeIn && !isGroupAnswered ? true : false}
                  title="Please select at least one option"
                  >
                  <Input
                    name={group.name}
                    placeholder={group.text}
                    /*disabled={!isGroupAnswered}*/
                    onChange={partial(this.handleWriteIn, group)}
                    value={this.state.groups[group.name].writeIn}
                  />
                </Tooltip>
              ) : (
                group.text
              )}
              {/*
              <div>
                <Toggle
                  onChange={partial(this.toggleOptions, group)}
                  checked={this.state.groups[group.name].showOptionsInMobile}
                />
              </div>
              */}
            </Group>
            <Options
              hide={this.state.groups[group.name].showOptionsInMobile === false}
            >
              {this.props.options.map(option => (
                <Option
                  key={`${group.name}_${option.name}`}
                  className={styles.ieOption}
                >
                  <Checkbox
                    value={option.value}
                    checked={this.state.groups[group.name].options[option.name]}
                    disabled={
                      this.state.disabled &&
                      every(
                        this.state.groups[group.name].options,
                        v => v === false
                      )
                    }
                    onChange={partial(this.handleChange, group, option)}
                  />
                  <div className={styles.optionText}>{option.text}</div>
                </Option>
              ))}
            </Options>
          </Row>
        )})}
      </Container>
    );
  }

  render() {
    const isIE = false || !!document.documentMode;

    if (isIE) {
      return <div>{this.renderIEMarkup()}</div>;
    }

    return (
      <MediaQuery maxWidth={599.95}>
        {matches => (matches ? this.renderMobile() : this.renderDesktop())}
      </MediaQuery>
    );
  }
}

CheckboxGrid.propTypes = {
  // The name of the question
  name: PropTypes.string.isRequired,
  // The type of the question (CHECKBOX_GRID)
  type: PropTypes.string.isRequired,
  // The groups of the question
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      // The name of the group
      name: PropTypes.string.isRequired,
      // The text to diplay for the group
      text: PropTypes.string.isRequired,
      // Whether its position is fixed
      fixed: PropTypes.boolean,
      // Whether the user can write in the content
      writeIn: PropTypes.boolean,
      // TODO: Describe the group.source property
      source: PropTypes.string
    })
  ).isRequired,
  // The options of the question
  options: PropTypes.arrayOf(
    PropTypes.shape({
      // The name of the option
      name: PropTypes.string.isRequired,
      // The value of the option
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      // The text of the option to be displayed
      text: PropTypes.string.isRequired,
      // Whether the position is fixed
      fixed: PropTypes.boolean,
      unique: PropTypes.boolean,
      exclusive: PropTypes.boolean,
      source: PropTypes.string,
      path: PropTypes.string
    })
  ).isRequired,
  // The count of the allowed groups
  maxGroupsAllowed: PropTypes.number,
  // The answer of the user
  value: PropTypes.shape({
    // The actual answer of the user
    value: PropTypes.string
  }),
  // The duration the user spent on the question
  duration: PropTypes.number.isRequired,
  // TODO: Describe the custom property
  custom: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
      text: PropTypes.string
    })
  ),
  // Callback to be invoked on mount
  onInit: PropTypes.func,
  // Callback to be invoked on change
  onChange: PropTypes.func
};

CheckboxGrid.defaultProps = {
  value: {},
  maxGroupsAllowed: null,
  custom: null,
  onInit: noop,
  onChange: noop
};

export default CheckboxGrid;
