import { SET_APP_STATUS } from "../types";

export default function(key, value, text = "") {
  return {
    key,
    value,
    text,
    type: SET_APP_STATUS
  };
}
