import React, { Component } from "react";
import PropTypes from "prop-types";
import flow from "lodash/flow";
import get from "lodash/get";
// import isUndefined from 'lodash/isUndefined';
import keyBy from "lodash/keyBy";
// import map from 'lodash/map';
import mapValues from "lodash/mapValues";
import noop from "lodash/noop";
// import omitBy from 'lodash/omitBy';
import partialRight from "lodash/partialRight";
import RatingList from "../RatingList";
import styles from "./RatingGrid.module.css";

import Header from "./components/Header";

class RatingGrid extends Component {
  static get propTypes() {
    return {
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      groups: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
          source: PropTypes.string
        })
      ).isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
          source: PropTypes.string,
          path: PropTypes.string
        })
      ).isRequired,
      value: PropTypes.object,
      duration: PropTypes.number.isRequired,
      custom: PropTypes.objectOf(
        PropTypes.shape({
          name: PropTypes.string,
          text: PropTypes.string
        })
      ),
      onInit: PropTypes.func,
      onChange: PropTypes.func
    };
  }

  static get defaultProps() {
    return {
      value: {},
      custom: null,
      onInit: noop,
      onChange: noop
    };
  }

  constructor(props) {
    super(props);

    const formatValue = flow([
      partialRight(keyBy, "name"),
      partialRight(mapValues, (option, key) =>
        get(props.value, key, {
          value: null
        })
      )
    ]);

    this.state = {
      value: formatValue(props.groups)
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    // Pass state and answer to parent (Question) component right before being appended to DOM
    this.props.onInit(
      this.props.name,
      this.props.type,
      {
        //      groups: map(this.props.groups, (v) => omitBy({ name: v.name, source: v.source, path: v.path }, isUndefined)),
        //      options: map(this.props.options, (v) => omitBy({ name: v.name, source: v.source, path: v.path }, isUndefined))
        groups: this.props.groups,
        options: this.props.options,
        custom: this.props.custom
      },
      this.props.value,
      this.props.duration
    );
  }

  handleChange(e, group, rating) {
    const state = this.state;
    state.value[group.name].value = rating;
    this.setState(state);

    this.props.onChange(e, state.value);
  }

  render() {
    return (
      <div className={styles.container}>
        {this.props.groups.map(group => (
          <div className={styles.group} key={group.name}>
            <Header>{group.text}</Header>
            <RatingList
              name={group.name}
              type={this.props.type}
              options={this.props.options}
              value={this.state.value[group.name].value}
              duration={this.props.duration}
              custom={this.props.custom}
              onChange={(e, rating) => this.handleChange(e, group, rating)}
            />
          </div>
        ))}
      </div>
    );
  }
}

export default RatingGrid;
