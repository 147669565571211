import React, { Component } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";

import RatingStar from "./RatingStar";

class RatingItem extends Component {
  static get propTypes() {
    return {
      name: PropTypes.string.isRequired,
      maxValue: PropTypes.number,
      value: PropTypes.number,
      onRatingChange: PropTypes.func
    };
  }

  static get defaultProps() {
    return {
      maxValue: 5,
      value: 0,
      onRatingChange: noop
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      rating: props.value,
      current: null
    };

    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleMouseOver(e, index) {
    this.setState({ current: index + 1 });
  }

  handleMouseOut(/* e, index */) {
    this.setState({ current: null });
  }

  handleClick(e, index) {
    const rating = index + 1;

    this.setState({ rating });
    this.props.onRatingChange(e, { name: this.props.name, value: rating });
  }

  render() {
    const stars = [];
    const STAR_LEN = 23;

    for (let i = 0; i < this.props.maxValue; i += 1) {
      const rating =
        this.state.current !== null ? this.state.current : this.state.rating;
      const selected = i < rating;

      stars.push(
        <RatingStar
          key={i}
          index={i}
          solid={selected}
          width={STAR_LEN}
          height={STAR_LEN}
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOut}
          onClick={this.handleClick}
        />
      );
    }

    return (
      <span style={{ minWidth: this.props.maxValue * STAR_LEN }}>{stars}</span>
    );
  }
}

export default RatingItem;
