import React, { Component } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";

import emptyStar from "./empty-star.svg";
import solidStar from "./solid-star.svg";

class RatingStar extends Component {
  static get propTypes() {
    return {
      index: PropTypes.number.isRequired,
      solid: PropTypes.bool.isRequired,
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      onMouseOver: PropTypes.func,
      onMouseOut: PropTypes.func,
      onClick: PropTypes.func
    };
  }

  static get defaultProps() {
    return {
      onMouseOver: noop,
      onMouseOut: noop,
      onClick: noop
    };
  }

  render() {
    const { index, width, height } = this.props;

    return (
      <span
        className={this.props.solid ? "solid" : "empty"}
        onMouseOver={e => this.props.onMouseOver(e, index)}
        onMouseOut={e => this.props.onMouseOut(e, index)}
        onClick={e => this.props.onClick(e, index)}
      >
        <img
          width={width}
          height={height}
          src={this.props.solid ? solidStar : emptyStar}
          alt="RatingStar"
        />
      </span>
    );
  }
}

export default RatingStar;
