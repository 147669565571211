import { Auth, API } from "aws-amplify";

import get from "lodash/get";
import map from "lodash/map";

import { SET_USER_PROFILE, SIGN_OUT } from "../types";
import { Logger } from "../../utils/logger";

const logger = new Logger("user/actions");

export const normalizeUser = cognitoUser => {
  const userId = get(cognitoUser, "username", "");
  const email = get(
    cognitoUser,
    ["signInUserSession", "idToken", "payload", "email"],
    ""
  );
  const groups = get(
    cognitoUser,
    ["signInUserSession", "idToken", "payload", "cognito:groups"],
    []
  );
  const jwtToken = get(
    cognitoUser,
    ["signInUserSession", "idToken", "jwtToken"],
    []
  );

  return {
    userId,
    email,
    groups,
    jwtToken
  };
};

export const setUserProfile = profile => ({
  type: SET_USER_PROFILE,
  payload: profile
});

export const signOut = () => ({
  type: SIGN_OUT
});

export const importUserFromSession = () => async dispatch => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const normalizedUser = normalizeUser(user);
    dispatch(setUserProfile(normalizedUser));
  } catch (error) {
    logger.debug("An error occurred", error);
  }
};

/**
 *
 * @param {*} newsletters  [{"category":"DE_COMMUNITY","status":"subscribed","value":"community-newsletter"},{"category":"DE_SURVEY","status":"subscribed","value":"survey-newsletter"}]
 */
export const handleNewsletterSubscriptions = newsletters => async (
  dispatch,
  getState
) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const normalizedUser = normalizeUser(user);
    dispatch(setUserProfile(normalizedUser));

    const { userId, jwtToken } = normalizedUser;
    const locale = get(getState(), ["i18n", "locale"], "en");

    const normalizedNewsletters = map(newsletters, ({ category, status }) => ({
      category,
      status,
      locale
    }));

    const headers = { Authorization: `Bearer ${jwtToken}` };
    const body = normalizedNewsletters;
    const params = {
      body,
      headers
    };
    await API.put("users", `/users/${userId}/communications`, params);
  } catch (error) {
    logger.debug("An error occurred", error);
  }
};
