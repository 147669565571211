import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { setLocale } from "react-redux-i18n";
import { connect } from "react-redux";

import isEmpty from "lodash/isEmpty";
import includes from "lodash/includes";
import get from "lodash/get";

import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";

import Assets from "../../images";

import FullPageTransparentLoader from "../../components/FullPageTransparentLoader";
import NavigationButton from "../../components/NavigationButton";
import Footer from "../../components/Footer";
import Question from "../../components/Question";
import Snackbar from "../../components/Snackbar";

import useQueryParams from "./hooks/useQueryParams";
import useUserFlow from "./hooks/useUserFlow";
import useOnBeforeUnload from "./hooks/useOnBeforeUnload";
import useInterval from "./hooks/useInterval";

import AppBar from "./components/AppBar";
import Theme from "./components/Theme";
import KeyboardShortcuts from "./components/KeyboardShortcuts";

import importQuestionnaire from "../../state/actions/importQuestionnaire";
import pullQuestionnaire from "../../state/actions/pullQuestionnaire";
import pushResponse from "../../state/actions/pushResponse";
import { getSelectedLocale } from "../../state/selectors/i18n";
import { isResponseSaved } from "../../state/selectors/status";
import { importUserFromSession } from "../../state/actions/user";
import pullSurvey from "../../state/actions/pullSurvey";
import setAppStatus from "../../state/actions/setAppStatus";
import { navigatePrevious, navigateNext } from "../../state/actions/navigate";
import {
  QUESTIONNAIRE_STATUS,
  SURVEY_STATUS,
  STATUS_OK,
  STATUS_PENDING,
  STATUS_ERROR,
  STATUS_COMPLETED
} from "../../state/status";

import ClosingPage from "./pages/ClosingPage";
import LandingPage from "./pages/LandingPage";
import LoadingPage from "./pages/LoadingPage";
import OutroPage from "./pages/OutroPage";

import styles from "./styles";

const Questionnaire = ({
  location,
  slug,
  classes,
  locale,
  survey,
  isSurveyReady,
  isQuestionnaireLoading,
  isQuestionnaireCompleted,
  enableOnBeforeUnload,
  onNext,
  onPrevious,
  doPullSurvey,
  doImportQuestionnaire,
  doPullQuestionnaire,
  doPushResponse,
  doSetLocale,
  doImportUser
}) => {
  const {
    metadata,
    versionName,
    questionId,
    queryLocale,
    clearQueryParamLocale
  } = useQueryParams(location);
  const {
    showLandingPage,
    showClosingPage,
    showQuestionnaire,
    isAnonymous,
    isPublic,
    flowLoader,
    flowError,
    doClearFlowError,
    doExecFlow,
    doPrepareQuestionnaire
  } = useUserFlow(
    survey,
    metadata,
    versionName,
    locale,
    questionId,
    slug,
    doImportQuestionnaire,
    doImportUser
  );

  useEffect(() => {
    doPullSurvey(slug);
  }, [slug]);

  useEffect(() => {
    if (locale !== queryLocale && queryLocale !== undefined) {
      doSetLocale(queryLocale);
      clearQueryParamLocale();
    }
  }, [queryLocale, locale]);

  useEffect(() => {
    if (showQuestionnaire) {
      doPullQuestionnaire(locale, survey._id);
    }
  }, [locale]);

  useEffect(() => {
    doExecFlow(survey);
  }, [survey]);

  useOnBeforeUnload(
    () => {
      if (!showClosingPage) {
        doPushResponse();
      }
    },
    slug,
    enableOnBeforeUnload && showQuestionnaire
  );

  useInterval(() => {
    if (!showClosingPage) {
      doPushResponse();
    }
  }, 20000);

  const Images = includes(slug, "arvr") ?
    get(Assets, "arvr", {}) :
    includes(slug, "dn") ? get(Assets, "de", {}) :
      includes(slug, "mu_") ? get(Assets, "mulesoft", {}) :
        includes(slug, "sf_") ? get(Assets, "salesforce", {}) :
          includes(slug, "dpl") ? get(Assets, "dpl", {}) :
            get(Assets, "de", {});

  if (isQuestionnaireCompleted) {
    return (
      <Theme slug={slug}>
        <AppBar
          userProps={{
            hideSignIn: isPublic
          }}
          hideAutoSave
          Images={Images}
        />
        <OutroPage />
      </Theme>
    );
  }

  if (showClosingPage) {
    return (
      <Theme slug={slug}>
        <ClosingPage Images={Images} />
      </Theme>
    );
  }

  if (showLandingPage) {
    console.log(`isPublic ${isPublic}`);

    return (
      <Theme slug={slug}>
        <AppBar
          userProps={{
            hideSignIn: isPublic
          }}
          hideAutoSave
          Images={Images}
        />
        <LandingPage
          doPrepareQuestionnaire={doPrepareQuestionnaire}
          branch={slug}
          Images={Images}
        />
      </Theme>
    );
  }

  if (showQuestionnaire) {
    return (
      <Theme slug={slug}>
        <KeyboardShortcuts>
          <Grid container className={classes.container}>
            <AppBar
              hideAutoSave={false}
              userProps={{
                redirect: true,
                hideSignIn: isAnonymous,
                hideSignOut: true
              }}
              Images={Images}
            />
            {isSurveyReady && !flowLoader && isEmpty(flowError) && (
              <>
                {isQuestionnaireLoading && <FullPageTransparentLoader />}
                <Hidden smDown>
                  <NavigationButton
                    className={classes.prevBtn}
                    onClick={onPrevious}
                  />
                </Hidden>
                <Question />
                <Hidden smDown>
                  <NavigationButton
                    className={classes.nextBtn}
                    onClick={onNext}
                    next
                  />
                </Hidden>
              </>
            )}
            <Footer />
          </Grid>
          <Snackbar
            message={flowError}
            onClose={doClearFlowError}
            open={Boolean(flowError)}
          />
        </KeyboardShortcuts>
      </Theme>
    );
  }

  return (
    <Theme slug={slug}>
      <LoadingPage Images={Images} />
      <Snackbar
        message={flowError}
        onClose={doClearFlowError}
        open={Boolean(flowError)}
      />
    </Theme>
  );
};

Questionnaire.propTypes = {
  classes: PropTypes.shape().isRequired,
  // Survey from state
  survey: PropTypes.shape().isRequired,
  isSurveyReady: PropTypes.bool.isRequired,
  isQuestionnaireLoading: PropTypes.bool,
  // Whether the user has completed the questionnaire
  isQuestionnaireCompleted: PropTypes.bool,
  location: PropTypes.shape().isRequired,
  slug: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  enableOnBeforeUnload: PropTypes.bool.isRequired,
  doImportQuestionnaire: PropTypes.func.isRequired,
  doPushResponse: PropTypes.func.isRequired,
  doSetLocale: PropTypes.func.isRequired,
  doImportUser: PropTypes.func.isRequired,
  // Next btn callback
  onNext: PropTypes.func.isRequired,
  // Previous btn callback
  onPrevious: PropTypes.func.isRequired,
  // Pull survey document by name
  doPullSurvey: PropTypes.func.isRequired,
  // Pull the questionnaire by locale and survey.id
  doPullQuestionnaire: PropTypes.func.isRequired
};

const getSurveyLoader = state =>
  state.status[SURVEY_STATUS] &&
  state.status[SURVEY_STATUS].value === STATUS_PENDING;

const getSurveyError = state =>
  state.status[SURVEY_STATUS] &&
  state.status[SURVEY_STATUS].value === STATUS_ERROR &&
  state.status[SURVEY_STATUS].text;

const getQuestionnaireLoader = state =>
  state.status[QUESTIONNAIRE_STATUS] &&
  state.status[QUESTIONNAIRE_STATUS].value === STATUS_PENDING;

const hasQuestionnaireCompleted = state =>
  state.status[QUESTIONNAIRE_STATUS] &&
  state.status[QUESTIONNAIRE_STATUS].value === STATUS_COMPLETED;

const mapStateToProps = state => ({
  survey: state.survey,
  isQuestionnaireCompleted: hasQuestionnaireCompleted(state),
  isSurveyReady: !getSurveyLoader(state) && isEmpty(getSurveyError(state)),
  isQuestionnaireLoading: getQuestionnaireLoader(state),
  locale: getSelectedLocale(state),
  enableOnBeforeUnload: !isResponseSaved(state)
});

const mapDispatchToProps = dispatch => ({
  onNext: () => dispatch(navigateNext()),
  onPrevious: () => dispatch(navigatePrevious()),
  doPullSurvey: slug => dispatch(pullSurvey(slug)),
  doImportQuestionnaire: data => dispatch(importQuestionnaire(data)),
  doPullQuestionnaire: (locale, surveyId) =>
    dispatch(pullQuestionnaire(locale, surveyId)),
  doSetLocale: data => dispatch(setLocale(data)),
  doPushResponse: () => dispatch(pushResponse()),
  doImportUser: () => dispatch(importUserFromSession()),
  doClearSurveyError: () => dispatch(setAppStatus(SURVEY_STATUS, STATUS_OK))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Questionnaire));
