import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import Files from "react-files";
import head from "lodash/head";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    width: "100%"
  },
  input: {
    display: "none"
  }
});

const ImportSpecForm = ({ open, classes, onClose }) => {
  const [file, setFile] = useState({});

  const onFilesChange = files => {
    console.log(files);
    setFile(head(files));
  };

  const onFilesError = (error, file) => {
    console.log(`error code ${error.code}: ${error.message}`);
    console.log(file);
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Import Spec File</DialogTitle>
      <DialogContent>
        <Files
          onChange={onFilesChange}
          onError={onFilesError}
          accepts={[".gz"]}
          maxFileSize={10000000}
          minFileSize={0}
          clickable
        >
          <DialogContentText>
            To import the spec file of the survey, please drop files here
          </DialogContentText>
        </Files>
      </DialogContent>
      {file.name && <div>{file.name}</div>}
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onClose} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ImportSpecForm);
