// import jsonLogic from "json-logic-js";
// import find from "lodash/find";
import { IMPORT_QUESTIONNAIRE } from "../types";

// /*
//  * Create graph of nodes (blocks) from the spec file, applying json-logic. This will generate an array
//  * of blocks that should be kept unmodified throught the survey lifecycle.
//  */
// function createGraph(survey) {
//   const start = find(survey, { name: "START" });
//   // Create graph from survey spec
//   const graph = jsonLogic.apply(start.blocks);

//   return graph;
// }

export default function(obj) {
  return {
    ...obj,
    graph: obj.payload.graph,
    type: IMPORT_QUESTIONNAIRE
  };
}
