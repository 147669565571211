import {
  // ASSIGN_ANSWER,
  // ASSIGN_CONTEXT,
  IMPORT_QUESTIONNAIRE,
  RESET_APP_STATUS,
  SET_APP_STATUS,
  RESET
  // UNMARK_DIRTY_QUESTION_BATCH
} from "../types";
import {
  // PAYLOAD_STATUS,
  SURVEY_STATUS,
  STATUS_OK
  // STATUS_PENDING
} from "../status";

export default function(state = {}, action) {
  switch (action.type) {
    case IMPORT_QUESTIONNAIRE:
      return {
        ...state,
        [SURVEY_STATUS]: { value: STATUS_OK }
      };

    // case ASSIGN_ANSWER:
    // case ASSIGN_CONTEXT:
    //   return {
    //     ...state,
    //     [PAYLOAD_STATUS]: { value: STATUS_PENDING }
    //   };

    // case UNMARK_DIRTY_QUESTION_BATCH:
    //   return {
    //     ...state,
    //     [PAYLOAD_STATUS]: { value: STATUS_OK }
    //   };

    case RESET_APP_STATUS:
      return {};

    case SET_APP_STATUS:
      return {
        ...state,
        [action.key]: { value: action.value, text: action.text }
      };

    case RESET:
      return {};

    default:
      return state;
  }
}
