import React from "react";
import { withStyles } from "@material-ui/core/styles";
import cn from "classnames";

const styles = () => ({
  options: {
    overflowY: "hidden"
  },
  hide: {
    maxHeight: 0
  }
});

const Options = ({ classes, hide, children }) => (
  <div
    className={cn({
      [classes.options]: true,
      [classes.hide]: hide === true
    })}
  >
    {children}
  </div>
);

export default withStyles(styles)(Options);
