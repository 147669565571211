import { combineReducers } from "redux";
import { i18nReducer } from "react-redux-i18n";

import survey from "./reducers/survey";
import payload from "./reducers/payload";
import responseId from "./reducers/responseId";
import cursor from "./reducers/cursor";
import version from "./reducers/version";
import blocks from "./reducers/blocks";
import questions from "./reducers/questions";
import dirtyQuestions from "./reducers/dirtyQuestions";
import status from "./reducers/status";
import userErrors from "./reducers/userErrors";
import user from "./reducers/user";
import meta from "./reducers/meta";

const rootReducer = combineReducers({
  survey,
  version,
  blocks,
  questions,
  payload,
  cursor,
  responseId,
  dirtyQuestions,
  status,
  userErrors,
  user,
  meta,
  i18n: i18nReducer
});

export default rootReducer;
