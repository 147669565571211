/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import cn from "classnames";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  option: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
    paddingRight: 20,
    "&:nth-child(odd)": {
      backgroundColor: theme.palette.questionnaire.stripe
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 14
    },
    "@media (max-width: 320px)": {
      fontSize: 11,
      height: 36
    }
  }
});

const Option = ({ classes, className = "", children }) => (
  <label className={cn(classes.option, className)}>{children}</label>
);

export default withStyles(styles)(Option);
