import WeightedList from 'js-weighted-list';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import flattenDeep from 'lodash/flattenDeep';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import max from 'lodash/max';
import sample from 'lodash/sample';
import shuffle from 'lodash/shuffle';
import size from 'lodash/size';
import some from 'lodash/some';
import uniq from 'lodash/uniq';

import jsonLogic from 'json-logic-js';

function weightedShuffle(data) {
  const list = new WeightedList(map(data, (item, index) => [index, item[1], item[0]]));
  return map(list.shuffle(), (item) => item.data);
}

function character(x, y, z) {
  const CR2a = z[0];
  const CR3 = z[1];
  const CR6 = z[2];
  const CR_DEV3 = z[3];

  const pro = some(get(CR2a, 'answer'), (item) => get(item, 'value.OPTION_1.value'));
  // console.log(`Is PRO: ${pro}`);
  const org = get(CR3, 'answer.value');
  // console.log(`Org = ${org}`);
  const exp = max(map(get(CR6, 'answer'), (item) => get(item, 'value')));
  // console.log(`Exp = ${exp}`);
  const sex = get(CR_DEV3, 'answer.value');
  // console.log(`Sex ${sex}`);

  /* eslint-disable eqeqeq, no-else-return */
  if (sex == 3 && (exp == 1 || exp == 2)) {
    return 'MRK_CH1';
  } else if (sex == 3 && exp == 4 && pro && includes(['5', '6', '7', '8'], org)) {
    return 'MRK_CH2';
  } else if ((sex == 3 && exp == 4 && pro && includes(['1', '2', '3', '4'], org)) ||
      (sex == 3 && exp == 4 && !pro)) {
    return 'MRK_CH3';
  } else if ((sex == 3 && exp == 3) || (sex == 1 && !pro) || (sex != 1 && sex != 3)) {
    return 'MRK_CH4';
  } else if (sex == 1 && pro) {
    return 'MRK_CH5';
  } else {
    // console.log(`Fallback: ${pro}, ${org}, ${exp}, ${sex}`);
    return '';
  }
  /* eslint-disable eqeqeq, no-else-return */
}

export default function setupJsonLogic() {
  jsonLogic.add_operation('character', character);
  jsonLogic.add_operation('compact', compact);
  jsonLogic.add_operation('filter', filter);
  jsonLogic.add_operation('flatten', flattenDeep);
  jsonLogic.add_operation('sample', sample);
  jsonLogic.add_operation('shuffle', shuffle);
  jsonLogic.add_operation('size', size);
  jsonLogic.add_operation('uniq', uniq);
  jsonLogic.add_operation('weightedShuffle', weightedShuffle);
}
