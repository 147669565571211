import find from "lodash/find";
import filter from "lodash/filter";
import sample from "lodash/sample";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

const getVersion = (versionName, survey, branch, authStatus) => {
  // Filter versions based on auth status
  const versions = filter(
    get(find(survey.branches, { name: branch }), "versions", []),
    ({ auth }) => auth === authStatus
  );

  /**
   * - Get a specific version from survey versions
   * - Get a random version from branch versions
   */
  const selectedVersion = !isEmpty(versionName)
    ? find(survey.versions, { name: versionName })
    : sample(versions);

  // Get the actual version
  const version = find(survey.versions, {
    name: get(selectedVersion, "name", "")
  });

  return {
    ...version,
    auth: authStatus
  };
};

export default getVersion;
